import React, { FC } from "react";
import { IExpandableItem, IFooter, IGoalItem, IHeader, IHighlight, IIcon, INotice, IStatus, IGoal, INote } from "../../../../Interfaces";
import "./Section.scss";

import Header from "./Header/Header";
import Title from "./Title/Title";
import Description from "./Description/Description";
import Notice from "./Notice/Notice";
import HighLightList from "./Highlights/HighlightList";
import Icon from "../../../../../components/Generic/Icon"
import Status from "./Status/Status";
import SummaryGoals from "./Goals/SummaryGoals";
import ExpandableItemList from "./ExpandableItemList/ExpandableItemList";
import Footer from "../Footer/Footer";
import ContextualNoteList from "@components/ContextualNoteList/ContextualNoteList";
import { SAVE_CONTEXTUAL_NOTE } from "requests/api";
import { useDispatch, useSelector } from "react-redux";
interface IProps {
    sectionId: string;
    applyFill?: boolean
    header?: IHeader;
    icon?: IIcon;
    title?: string;
    description?: string;
    notice?: INotice;
    highlights?: IHighlight[];
    status?: IStatus;
    goals?: IGoal;
    expandableItems?: IExpandableItem[];
    notes?: INote[];
    showNotes?: boolean;
    footer?: IFooter;
}

const Section: FC<IProps> = (props) => {
    const isSalesforce = window.sessionStorage.getItem("CurrentChannel") === "Salesforce";
    const isDCUMobile = sessionStorage.getItem("CurrentChannel") === "dcu_mobile";
    // @ts-ignore
    const dmcId = useSelector(state => state.startDMC);

    // This should be a temporary fix... 
    /// the text must be sent from service
    let customerData = sessionStorage.getItem("customerData");
    let customerName = "";
    if (customerData) {
        customerData = JSON.parse(customerData);
        // @ts-ignore
        if (customerData.givenName) {
            // @ts-ignore
            customerName = `, ${customerData.givenName}`;
        }
    }

    return (
        <section className={props.applyFill ? "filled-section" : undefined}>
            <span id={props.sectionId} className={isDCUMobile ? "section-anchor" : "section-anchor webViewMode"} aria-hidden={true}></span>
            {/* Section Header */}
            {props.header &&
                <Header
                    title={props.header?.titles?.[0].value}
                    images={props.header?.images}
                />
            }

            {/* Section Icon */}
            {props.icon &&
                <Icon
                    className="section-icon"
                    imageUrl={props.icon.url}
                    altText={""}
                />
            }

            {/* Section Title */}
            {props.title && <Title text={props.title} />}

            {/* Section Description */}
            {props.description && <Description text={props.description} />}

            {/* HighlightList - Intro */}
            {props.highlights &&
                <HighLightList highlights={props.highlights} />
            }

            {/* Status - Top Of Mind */}
            {props.status &&
                <Status
                    title={props.status?.titles?.[0]?.value}
                    subTitle={props.status.subTitle?.[0]?.value}
                    description={props.status.descriptions?.[0]?.value}
                    statusColor={props.status.statusColor}
                />
            }

            {/* Goals */}
            {props.goals &&
                <SummaryGoals goals={props.goals.goalsItems} />
            }

            {/* Financial Health - Expandable Items */}
            {props.expandableItems && props.expandableItems.length > 0 &&
                <ExpandableItemList
                    expandableItems={props.expandableItems}
                    resultsSection={props.title}
                    showNotes={props.showNotes}
                />
            }

            {/* Notice / Contact */}
            {!isSalesforce && props.notice &&
                <Notice
                    border={props.notice.border}
                    filled={props.notice.filled}
                    title={props.notice.titles?.[0]?.value}
                    subTitle={props.notice.subTitles?.[0]?.value}
                    description={props.notice.descriptions?.[0]?.value}
                    button={props.notice.button}
                    resultsSection={props.title}
                />
            }

            {
                isSalesforce && // Only staff members will see notes
                props.notes &&
                props.notes !== undefined &&
                props.notes !== null &&
                <div style={{ marginTop: 20 }}>
                    <ContextualNoteList
                        notes={props.notes}
                        onSave={(noteId, text) => {
                            SAVE_CONTEXTUAL_NOTE(noteId, text, dmcId);
                        }} />
                </div>
            }

            {props.footer && <Footer text={props.footer.text?.[0].value} />}
        </section>
    );
}
export default Section;