import React, { useEffect, useState } from "react";
//import auth from "./auth";

import ResumePage from "./components/containers/ResumePage/ResumePage";
import WelcomePage from "./WelcomePage";
import History from "./components/containers/History/History";
import Loading from "./components/Generic/Loading";
import DMCWrapper from "./components/Generic/DMCWrapper";
import QuestionairePage from "./views/Questionaire";
import Alert from "./components/Generic/Alert";
import Summary from "./components/containers/Summary/Summary";
import ErrorPage from "./components/containers/ErrorPage/ErrorPage";
import AlertIcon from "./assets/svg/alert.svg";
import Context from "./components/Generic/Context";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAuthorized,
  setIsAuthenticated,
  setAlert,
  setShowSummary,
  startNewDmc,
  setStaffResumedDmc,
  setShowWelcome,
  setSummaryAlert,
  setErrorInfo,
  setContext,
  setIsQuestionnaireRunning
} from "./redux/actions";
import { AUTHENTICATE, LOGOUT } from "./requests/authorization"; // Requests for authorization

// Helpers
import { generate } from "./helpers/PKCE"; // PKCE Code Generator Engine
import { decodeJWT } from "./helpers/decodeJWT";
import { RESPONDENTS, RESUME_DMC } from "./requests/api";

import log from "./helpers/logger";

function resumeDmc(resumeDmcQuestionnaire, dmc, reduxDispatcher) {
  if (dmc) {
    //redirect widget to Questionnaire page
    resumeDmcQuestionnaire()
  }
  else {
    console.log("Error with the dmc received")
  }
}

const FirstScreen = ({ staffResumedDmc, resumedDmc, authenticated, respondentDmcs, authorized, begin, resumeDmcQuestionnaire }) => {
  const [showWelcome, setShowWelcome] = useState(false);

  let reduxDispatcher = useDispatch();
  const startDmcId = useSelector(state => state.startDMC);
  const showAlert = useSelector(state => state.alert.show);
  const isRequestLoading = useSelector(state => state.requestLoading);

  if (!authenticated || !authorized) {
    return "";
  }

  //Staff member
  if (staffResumedDmc) {
    if (resumedDmc) {
      if (resumedDmc.status.toLowerCase() === "started") {
        //go to questionnaire page
        resumeDmc(resumeDmcQuestionnaire, resumedDmc, reduxDispatcher);

        return null
      }
      else if (resumedDmc.status.toLowerCase() === "completed") {
        reduxDispatcher(startNewDmc(resumedDmc.dmcId))
        reduxDispatcher(setShowSummary(true))

        //set summary alert and data to display on summary page
        let auxStartDate = resumedDmc.startDate ? new Date(resumedDmc.startDate) : null;
        let convertedStartDate = auxStartDate ? auxStartDate.toLocaleString('default', { month: 'long' }) +
          " " + auxStartDate.toLocaleString('default', { day: 'numeric' }) +
          ", " + auxStartDate.getFullYear() + "." : null;

        if (convertedStartDate) {
          reduxDispatcher(setSummaryAlert(
            {
              text: "You are viewing your assessment from",
              date: convertedStartDate,
              link: "Back to all assessments",
            }
          ))
        }

        return <div id="welcome-page"><Summary /></div>
      }
      else if (resumedDmc.status.toLowerCase() === "deleted") {
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "fullPage",
          errorTitle: "Something went wrong.",
          errorDescription: "This content was deleted and is no longer available. Start a new Money Chat or select an existing one",
          errorButton: null
        }));
        return null;
      }
    }
    else {
      if (!showAlert && showWelcome) {
        reduxDispatcher(
          setAlert(
            {
              show: true,
              message: `Dmc ${startDmcId} doesn't exist.`,
              success: false,
            }
          )
        )
      }

      return <WelcomePage handleState={begin} />
      // return <Alert message={"Dmc ID introduced was not found ..."} />;
    }
  }
  //DCU user
  else if (respondentDmcs && Object.keys(respondentDmcs).length > 0) {
    if (respondentDmcs.onGoingDMCId && respondentDmcs.onGoingDMCId !== undefined && respondentDmcs.onGoingDMCId != "") {
      //get dmc from last dmcId
      let dmc = respondentDmcs.onGoingDMCId;

      if (dmc) reduxDispatcher(startNewDmc(dmc))

      return <ResumePage
        resumeDmcQuestionnaire={resumeDmcQuestionnaire}
      />
    }
    else if (respondentDmcs?.lastCompletedDMCId && respondentDmcs?.lastCompletedDMCId !== "" && !respondentDmcs.onGoingDMCId) {
      reduxDispatcher(startNewDmc(respondentDmcs.lastCompletedDMCId))

      //set summary alert and data to display on summary page
      let auxStartDate = respondentDmcs.lastCompletedDMCDate ? new Date(respondentDmcs.lastCompletedDMCDate) : null;
      let convertedStartDate = auxStartDate ? auxStartDate.toLocaleString('default', { month: 'long' }) +
        " " + auxStartDate.toLocaleString('default', { day: 'numeric' }) +
        ", " + auxStartDate.getFullYear() + "." : null;

      reduxDispatcher(setSummaryAlert(
        {
          text: "You are viewing your assessment from",
          date: convertedStartDate,
          link: "Back to all assessments",
        }
      ))

      reduxDispatcher(setShowSummary(true))
      return null
    }
    else {
      if (respondentDmcs !== "-1")
        return <WelcomePage handleState={begin} />
      else
        return null
    }
  }
  else if (respondentDmcs === null || Object.keys(respondentDmcs).length === 0) {
    if (!isRequestLoading && respondentDmcs !== "-1") {
      return <WelcomePage handleState={begin} />;
    }
    else {
      return null;
    }
  }
  else {
    return <h3>Something went wrong with DMC respondents</h3>;
  }
};

//function to resume the dmc after ResumePage or staffMember with dmcId incompleted
const resumeDmcQuestionnaire = (setLetsBegin, reduxDispatcher) => {
  reduxDispatcher(setSummaryAlert(false));
  setLetsBegin(true)
}

const removeTokensFromSessionStorage = () => {
  sessionStorage.removeItem("AUTHENTICATE_TOKEN");
  sessionStorage.removeItem("ID_TOKEN");
  sessionStorage.removeItem("ACCESS_TOKEN");
  sessionStorage.removeItem("customerData");
};

function initAsDCU(reduxDispatcher, settings) {
  log.debug("initAsDCU with platform and config:");
  log.debug(settings.platform);
  log.debug(process.env.REACT_APP_BYPASS_AUTH);

  if (process.env.NODE_ENV === "development") {
    log.debug("Started by eBankit/DCU");
  }
  sessionStorage.setItem("CurrentChannel", settings.platform);
  if (process.env.NODE_ENV !== "development") {
    log.debug("insert css link");
    const container = document.getElementById("dmcWidget");
    const cssId = 'dmcCSS';
    if (!document.getElementById(cssId)) {
      var link = document.createElement('link');
      link.id = cssId;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = `${process.env.REACT_APP_ASSETS}/static/css/dmcWidget.css?v=${process.env.REACT_APP_Version}`;
      link.media = 'all';
      container.appendChild(link);
    }
  }
  /* ---------------------------------------------------------------------------------------------- */
  /* --- YOU SHOULD SET ENV VARIABLE REACT_APP_BYPASS_AUTH to FALSE to ALLOW DCU AUTHENTICATION --- */
  /* ---------------------------------------------------------------------------------------------- */
  //if (settings.platform === "dcu_sim" && process.env.REACT_APP_BYPASS_AUTH == "true") {
  //  log.debug("DCU Simulator - Bypassing SIMEIO AUTH");

  //  if (sessionStorage.getItem("dcuQueryData")) {
  //    const { t24profileId, givenName, familyName } = JSON.parse(
  //      sessionStorage.getItem("dcuQueryData")
  //    );
  //    sessionStorage.setItem("customerData", sessionStorage.getItem("dcuQueryData"));
  //    log.debug("DCU Simulator T24: " + t24profileId);
  //    RESPONDENTS(t24profileId, reduxDispatcher);

  //    reduxDispatcher(setIsAuthenticated(true));
  //    reduxDispatcher(setIsAuthorized(true));
  //  }
  //  return;
  //}
  if (settings.platform === "dcu_sim" && process.env.REACT_APP_BYPASS_AUTH.trim() == "true") {
    log.debug("DCU Simulator - Bypassing SIMEIO AUTH");

    if (sessionStorage.getItem("dcuQueryData")) {
      const { t24profileId, givenName, familyName } = JSON.parse(
        sessionStorage.getItem("dcuQueryData")
      );
      sessionStorage.setItem("customerData", sessionStorage.getItem("dcuQueryData"));
      log.debug("DCU Simulator T24: " + t24profileId);
      RESPONDENTS(t24profileId, reduxDispatcher);

      reduxDispatcher(setIsAuthenticated(true));
      reduxDispatcher(setIsAuthorized(true));
    }
    return;
  }
  if (settings.platform === "dcu" && process.env.REACT_APP_DCUBYPASS_AUTH == "true") {
    log.debug("DCU Hardcoded - Bypassing SIMEIO AUTH");

    const t24profileId = "DCUHardCoded2";
    const givenName = "DCU";
    const familyName = "DCU Hard Coded";
    const dcuQueryData = {
      t24profileId: t24profileId,
      givenName: givenName,
      familyName: familyName
    }
    sessionStorage.setItem("customerData", JSON.stringify(dcuQueryData));
    log.debug("DCU Hardcoded: " + t24profileId);
    RESPONDENTS(t24profileId, reduxDispatcher);

    reduxDispatcher(setIsAuthenticated(true));
    reduxDispatcher(setIsAuthorized(true));

    return;
  }
  /* ------------------------------------------ */


  // Logout if ACCESS_TOKEN exists on init
  if (sessionStorage.getItem("ACCESS_TOKEN")) {
    log.debug("Logout previous Session");
    removeTokensFromSessionStorage();
    LOGOUT(true, reduxDispatcher);
  }
  else {
    AUTHENTICATE(reduxDispatcher);
  }
}

function initAsSalesforce(setLetsBegin, reduxDispatcher) {
  if (process.env.NODE_ENV === "development") {
    log.debug("Started by Salesforce/CRM");
  }

  sessionStorage.setItem("CurrentChannel", "Salesforce");

  if (sessionStorage.getItem("customerData") && sessionStorage.getItem("ACCESS_TOKEN")) {

    log.debug("Found CRM auth Data");

    const { t24profileId, dmcId } = JSON.parse(
      sessionStorage.getItem("customerData")
    );

    if (dmcId) {
      log.debug("Salesforce DMCID in Context - Showing Summary");

      //get dmc data by id and store it to the redux store
      RESUME_DMC(dmcId, () => { resumeDmcQuestionnaire(setLetsBegin, reduxDispatcher) }, reduxDispatcher)
        .then(() => reduxDispatcher(setStaffResumedDmc(true)))
        .finally(() => { setLetsBegin(false) })
    }
    else if (t24profileId) {
      log.debug("Salesforce t24profileId in Context - Showing Welcome, History or Resume");
      RESPONDENTS(t24profileId, reduxDispatcher);
    } else {
      log.error("NO CRM auth Data found");
      reduxDispatcher(setIsAuthenticated(false));
      reduxDispatcher(setIsAuthorized(false));
      reduxDispatcher(setErrorInfo({
        show: true,
        type: "fullPage",
        errorTitle: "Something went wrong.",
        errorDescription: "It's not you, it's us. Please try again.",
        errorButton: null
      }));
      return;
    }
    reduxDispatcher(setIsAuthenticated(true));
    reduxDispatcher(setIsAuthorized(true));
  } else {
    log.error("You are not authorized to perform this call - No CRM Auth Data");
    reduxDispatcher(setErrorInfo({
      show: true,
      type: "fullPage",
      errorTitle: "Something went wrong.",
      errorDescription: "It's not you, it's us. Please try again.",
      errorButton: null
    }));
    reduxDispatcher(setIsAuthorized(false));

  }

}

function App({ settings }) {
  const reduxDispatcher = useDispatch();
  const [letsBegin, setLetsBegin] = useState(false);
  /* ------------------------------- */
  /* ---- REDUX STATE SELECTORS ---- */
  /* ------------------------------- */
  const respondentsDmcs = useSelector((state) => state.respondentsDmcs);
  const isAuthenticated = useSelector((state) => state.authorization.isAuthenticated); // Boolean check if authenticated
  const isAuthorized = useSelector((state) => state.authorization.isAuthorized); // Boolean check if authorized
  const isRequestLoading = useSelector((state) => state.requestLoading); // Redux loading
  const showAlert = useSelector((state) => state.alert.show); // Redux alert
  const showSummary = useSelector((state) => state.summary.showSummary);
  const showHistory = useSelector((state) => state.history.showHistory);
  const showWelcome = useSelector((state) => state.welcome.showWelcome);
  const summaryAlert = useSelector((state) => state.summary.summaryAlert) //Summary alert with link to history
  const respondentId = useSelector(state => state.respondents.data);
  const currentStep = useSelector(state => state.questionnaireFlow.currentStep);
  const resumedDmc = useSelector(state => state.resumedDmc.dmc);
  const staffResumedDmc = useSelector(state => state.resumedDmc.isStaffDmc);
  const currentGoals = useSelector(state => state.goals.currentGoals)

  //trigger app to show Error Message
  const errorInfo = useSelector(state => state.error);

  /* ------------------------------------------- */
  /* ---- RANDOM STRING GENERATORS FOR PKCE ---- */
  /* ------------------------------------------- */
  const generated_CODEVERIFIER = generate.codeVerifier();
  const generated_CODECHALLENGE = generate.codeChallenge(generated_CODEVERIFIER);
  const generated_OAUTH_STATE = generate.randomString(24);

  const showAPPContext = useSelector(state => state.context);
  const showAPPContextConfig = process.env.REACT_APP_ShowAppContext == "true";
  useEffect(() => {
    log.debug(process.env.REACT_APP_Version);
    if (settings.platform === "dcu" || settings.platform === "dcu_sim" || settings.platform === "dcu_mobile") {
      // Generate and saves data to session storage in case there's none
      if (!sessionStorage.getItem("CODE_VERIFIER") || !sessionStorage.getItem("CODE_CHALLENGE")) {
        sessionStorage.setItem("CODE_VERIFIER", generated_CODEVERIFIER);
        sessionStorage.setItem("CODE_CHALLENGE", generated_CODECHALLENGE);
        sessionStorage.setItem("STATE", generated_OAUTH_STATE);
      }
      sessionStorage.setItem("ApiUrl", process.env.REACT_APP_API_SIMEIO_URL);
      initAsDCU(reduxDispatcher, settings);
      reduxDispatcher(setContext({
        show: true
      }));
    }
    if (settings.platform === "salesforce") {
      //Remove authentication garbage.
      sessionStorage.removeItem("tenantID");
      sessionStorage.removeItem("clientID");
      sessionStorage.removeItem("redirectURI");
      sessionStorage.setItem("ApiUrl", process.env.REACT_APP_API_AZURE_URL);
      //Set Agent Context Data.
      var {
        unique_name: AZ_unique_name,
        given_name: AZ_given_name,
        family_name: AZ_family_name,
        onprem_sid: AZ_onprem_sid,
        roles: AZ_roles
      } = decodeJWT(sessionStorage.getItem("ACCESS_TOKEN"));

      if (process.env.REACT_APP_PropagationAgentValue) {
        //for testing purpose
        AZ_onprem_sid = process.env.REACT_APP_PropagationAgentValue;
        if (new Date().getMinutes() % 2 === 0)
          AZ_roles = ["DMC advice-centre"]
        else
          AZ_roles = ["DMC in-branch"]
      }

      var AD_GROUP = "IN-BRANCH";

      if (AZ_roles != undefined && AZ_roles?.find(x => x.includes("DMC advice-centre")))
        AD_GROUP = "ADVICE-CENTRE";

      const agentData = {
        "unique_name": String(AZ_unique_name),
        "given_name": AZ_given_name,
        "family_name": AZ_family_name,
        "AD_Group": AD_GROUP,
        "onprem_sid": AZ_onprem_sid
      }
      sessionStorage.setItem("agentData", JSON.stringify(agentData));
      initAsSalesforce(setLetsBegin, reduxDispatcher);
      reduxDispatcher(setContext({
        show: true
      }));
    }
  }, []);

  useEffect(() => {
    if (letsBegin && currentStep?.stepId) {
      //TODO: if isQuestionnaireRunning
      reduxDispatcher(setIsQuestionnaireRunning(true))
    }
    else {
      reduxDispatcher(setIsQuestionnaireRunning(false))
    }
  }, [letsBegin, currentStep, currentGoals])

  return (
    <>
      {showAPPContextConfig && showAPPContext && showAPPContext.show && <Context />}
      {isRequestLoading && showSummary && <Loading />}
      {errorInfo && errorInfo.show && errorInfo.type === "fullPage" ?
        <ErrorPage
          icon={AlertIcon}
          button={errorInfo.errorButton}
          title={errorInfo.errorTitle}
          description={errorInfo.errorDescription}
          link={errorInfo.errorLink}
        />
        : showSummary ?
          <>
            <Summary
              style={isRequestLoading ? { display: 'none' } : { display: 'block' }}
              startNewDmc={() => resumeDmcQuestionnaire(setLetsBegin, reduxDispatcher)}
              withAlert={summaryAlert ? summaryAlert : null}
              respondentId={respondentId}
            />
          </>
          : showHistory ?
            <><History beginDmc={() => resumeDmcQuestionnaire(setLetsBegin, reduxDispatcher)} resumeDmc={resumeDmc} />
              {isRequestLoading && <Loading />}</>
            : showWelcome ?
              <WelcomePage handleState={() => { reduxDispatcher(setShowWelcome(false)); setLetsBegin(true) }} />
              : <DMCWrapper>
                {showAlert && <Alert />}
                {isRequestLoading && <Loading loaderMessage={"Saving your responses..."} />}
                {!letsBegin ? (
                  <FirstScreen
                    staffResumedDmc={staffResumedDmc}
                    resumedDmc={resumedDmc}
                    authenticated={isAuthenticated}
                    authorized={isAuthorized}
                    respondentDmcs={respondentsDmcs}
                    begin={() => setLetsBegin(true)}
                    resumeDmcQuestionnaire={() => resumeDmcQuestionnaire(setLetsBegin, reduxDispatcher)}
                  />
                ) : (
                  <>
                    {!showAlert && !showSummary && <QuestionairePage handleReturnWelcome={setLetsBegin} />}
                  </>
                )}
              </DMCWrapper>
      }
    </>
  );
}

export default App;
