import BackgroundImageTestDesktop from "../assets/svg/header_desktop_01.jpg";
import BackgroundImageTestMobile from "../assets/svg/mobile-header.png";
import IconEx from "../assets/svg/compass.svg";

/* GOALS */
export const schemaGoals = [
  {
    controlItemType: "Major Goal",
    priority: 1,
    subject: "Buying my first home",
    time: "in 3 to 5 years",
    heat: "green",
    state: "Moderately Confident",
    url: "www.google.pt",
    readOnly: false,
    link: {
      url: "https://www.google.pt",
      text: "Steps you can take",
      target: "_blanck",
    },
  },
  {
    controlItemType: "Goal",
    priority: 2,
    subject: "Start to invest",
    time: "in 10+ years",
    heat: "orange",
    state: "Somewhat Confident",
    url: "www.google.pt",
    readOnly: true,
    link: {
      url: "https://www.google.pt",
      text: "Steps you can take",
      target: "_blanck",
    },
  },
  {
    priority: 3,
    //subject: 'Saving for retirement',
    time: "in 10+ years",
    heat: "red",
    state: "Not confident at all",
    url: "www.google.pt",
    readOnly: false,
    link: {
      url: "https://www.google.pt",
      text: "Steps you can take",
      target: "_blanck",
    },
  },
];
export const myGoals = [
  {
    type: "RadioButton",
    text: "Manage my money better",
    icon: "urlToIcon",
  },
  {
    type: "RadioButton",
    text: "Save for something",
    icon: "urlToIcon",
    select: [
      {
        label: "What are you saving for?",
        options: ["Own a home", "Option 2", "Option 3"],
      },
      {
        label: "What kind of home?",
        options: ["Buy my first home", "Option 2", "Option 3"],
      },
      {
        label: "I want to accomplish this in",
        options: ["3 to 5 years", "Option 2", "Option 3"],
      },
    ],
  },
  {
    type: "RadioButton",
    text: "Pay down debt",
    icon: "urlToIcon",
  },
  {
    type: "RadioButton",
    text: "My small business",
    icon: "urlToIcon",
  },
];
/* GOALS - END */
export const schemaHighlights = [
  {
    title: [
      {
        language: "en-us",
        value: "Overview of your financial health",
      },
    ],
    icon: {
      url: IconEx,
      altText: [
        {
          language: "en-us",
          value: "Overview of your financial health",
        },
      ],
    },
  },
  {
    title: [
      {
        language: "en-us",
        value:
          "Tools and resource recommendations to help you improve areas that may need attention",
      },
    ],
    icon: {
      url: IconEx,
      altText: [
        {
          language: "en-us",
          value: "Tools and Resources",
        },
      ],
    },
  },
  {
    title: [
      {
        language: "en-us",
        value: "Connected with an advisor to build a costumized action plan",
      },
    ],
    icon: {
      url: IconEx,
      altText: [
        {
          language: "en-us",
          value: "Connected",
        },
      ],
    },
  },
];
export const schemaHearder = {
  title: [
    {
      language: "en-us",
      value: "Breath easy. \n Your journey to financial health starts now",
    },
  ],
  images: [
    {
      deviceType: "desktop",
      imageUrl: BackgroundImageTestDesktop,
      altText: "desktop",
    },
    {
      deviceType: "mobile",
      imageUrl: BackgroundImageTestMobile,
      altText: "mobile",
    },
  ],
};
export const schemaCarousel = {
  title: [
    {
      language: "en-us",
      value: "What others have said",
    },
  ],
  quotes: [
    {
      value: "The conversation with my Advisor after doing a Money Chat was great, more in-depth and it was very caring.",
    },
    {
      value: "I was already doing the right things but it made me think about different options.",
    },
    {
      value: "I really liked the colours in the assessment results. It's really clear to see what I should be working on.",
    },
  ],
};
export const optionsLikert = {
  titles: [{
    value: "How confident do you feel about making this decision?",
  }],
  answerOptions: [
    { controlItemType: 'RangeItem', value: 1, text: "Not confident at all" },
    { controlItemType: 'RangeItem', value: 2, text: "Not much confident" },
    { controlItemType: 'RangeItem', value: 3, text: "Confident" },
    { controlItemType: 'RangeItem', value: 4, text: "Litle confident" },
    { controlItemType: 'RangeItem', value: 5, text: "Very confident" },
  ],
};
export const schemaQuestionaire = {
  steps: [
    {
      id: 1,
      stepType: "question", //types: question / info /
      controlType: "radioButtonList", //types: radioButtonList / textBox / rangeItem
      title: [
        {
          language: "en-us",
          value: "Witch of the below would you say is top of mind for you?",
        },
        {
          language: "fr-fr",
          value: "Selon vous, la sorcière du dessous est la priorité pour vous?",
        },
      ],
      description: [
        {
          language: "en-us",
          value: "Small description to display under the question.",
        },
        {
          language: "fr-fr",
          value: "Petite description à afficher sous la question.",
        },
      ],
      answerOptions: [
        {
          id: 1,
          controlItemType: "RadioButtonListItem",
          value: "1",
          label: [
            {
              language: "en-us",
              value: "I'm about to make a bid decision",
            },
            {
              language: "fr-fr",
              value: "Je suis sur le point de prendre une décision en matière d'offre",
            },
          ],
        },
        {
          id: 2,
          controlItemType: "RadioButtonListItem",
          value: "2",
          label: [
            {
              language: "en-us",
              value: "I'm anticipating, experiencing, or adjustin to a big life change.",
            },
            {
              language: "fr-fr",
              value: "J'anticipe, je vis ou je m'adapte à un grand changement de vie.",
            },
          ],
        },
        {
          id: 3,
          controlItemType: "RadioButtonListItem",
          value: "3",
          label: [
            {
              laguage: "en-us",
              value: "Test radio button",
            },
            {
              laguage: "fr-fr",
              value: "Je suis sur le point de prendre une décision en matière d'offre",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      stepType: "question",
      controlType: "range",
      title: [
        {
          language: "en-us",
          value: "How confident do you feel about making this decision?",
        },
        {
          language: "fr-fr",
          value: "Dans quelle mesure vous sentez-vous confiant de prendre cette décision?",
        },
      ],
      answerOptions: [
        {
          id: 1,
          controlItemType: "rangeItem",
          value: "1",
          label: [
            {
              language: "en-us",
              value: "Not confident at all",
            },
            {
              language: "fr-fr",
              value: "Pas du tout confiant",
            },
          ],
        },
        {
          id: 2,
          controlItemType: "rangeItem",
          value: "2",
        },
        {
          id: 3,
          controlItemType: "rangeItem",
          value: "3",
        },
        {
          id: 4,
          controlItemType: "rangeItem",
          value: "4",
        },
        {
          id: 5,
          controlItemType: "rangeItem",
          value: "5",
          label: [
            {
              language: "en-us",
              value: "Very confident",
            },
            {
              language: "fr-fr",
              value: "Très confiant",
            },
          ],
        },
      ],
    },
  ],
};
export const schemaLifeStage = {
  "id": 97,
  "stepType": "question",
  "controlType": "iconRadioButtonList",
  "titles": [
    {
      "language": "en-us",
      "value": "Select one of the following that it best describes where you are today."
    },
    {
      "language": "fr-fr",
      "value": "Payez-vous vos dettes aussi vite que vous le souhaitez?"
    }
  ],
  "subTitle": [
    {
      "language": "en-us",
      "value": "I'm currently"
    }
  ],
  "answerOptions": [
    {
      "id": 97,
      "controlItemType": "RadioButtonListWithIconItem",
      "value": 1,
      "icon": {
        "imageUrl": "CareerIcon",
        "altText": [
          {
            "language": "en-us",
            "value": "Thumbs Up"
          },
          {
            "language": "fr-fr",
            "value": "Pouces vers le haut"
          }
        ]
      },
      "label": [
        {
          "language": "en-us",
          "value": "Maintaining my career"
        },
        {
          "language": "fr-fr",
          "value": "Oui"
        }
      ],
      "subQuestions": [
        {
          "id": 1,
          "controlItemType": "radioButtonListItem",
          "value": 1,
          "title": [
            {
              "laguage": "en-us",
              "value": "and I’m planning to retire"
            }
          ],
          "answerOptions": [
            {
              "id": 4,
              "controlItemType": "RadioButtonListItem",
              "label": [
                {
                  "laguage": "en-us",
                  "value": "someday, but it's so far out I'm not planning for it yet."
                }
              ]
            },
            {
              "id": 5,
              "controlItemType": "RadioButtonListWithFillItem",
              "label": [
                {
                  "laguage": "en-us",
                  "value": "in the next",
                  "descriptions": [
                    {
                      "language": "en-us",
                      "value": "years"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": 98,
      "controlItemType": "RadioButtonListWithIconItem",
      "value": 2,
      "icon": {
        "imageUrl": 'CareerIcon',
        "altText": [
          {
            "language": "en-us",
            "value": "Thumbs Down"
          },
          {
            "language": "fr-fr",
            "value": "Pouces vers le bas"
          }
        ]
      },
      "label": [
        {
          "language": "en-us",
          "value": "Figuring it out"
        },
        {
          "language": "fr-fr",
          "value": "Non"
        }
      ],
      "subQuestions": [
        {
          "id": 1,
          "controlItemType": "RadioButtonListItem",
          "value": "1",
          "title": [
            {
              "laguage": "en-us",
              "value": "this is my life stage at the moment"
            }
          ],
          "answerOptions": [
            {
              "id": 6,
              "controlItemType": "RadioButtonListItem",
              "label": [
                {
                  "laguage": "en-us",
                  "value": "Some life stage info"
                }
              ]
            },
            {
              "id": 7,
              "controlItemType": "RadioButtonListWithFillItem",
              "label": [
                {
                  "laguage": "en-us",
                  "value": "in the next years."
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": 99,
      "controlItemType": "RadioButtonListWithIconItem",
      "value": 3,
      "icon": {
        "imageUrl": 'CareerIcon',
        "altText": [
          {
            "language": "en-us",
            "value": "Thumbs Down"
          },
          {
            "language": "fr-fr",
            "value": "Pouces vers le bas"
          }
        ]
      },
      "label": [
        {
          "language": "en-us",
          "value": "Enjoying retirement"
        },
        {
          "language": "fr-fr",
          "value": "Non"
        }
      ]
    }
  ]
};

export const schemaInfoWithIcons = {
  stepId: 27,
  isLastStep: false,
  stepType: "Info",
  images: [
    {
      "deviceType": "Desktop",
      //"imageUrl": "https://widget.coastcapitalsavings.com/static/media/header_desktop_02.a1f211f5.jpg",
      "imageUrl": BackgroundImageTestDesktop,
      "altText": [
        {
          "language": "en-us",
          "value": "header_desktop_alttext"
        }
      ]
    },
    {
      "deviceType": "Mobile",
      "imageUrl": BackgroundImageTestDesktop,
      "altText": [
        {
          "language": "en-us",
          "value": "header_mobile_alttext"
        }
      ]
    }
  ],
  "titles": [
    {
      "language": "en-us",
      "value": "Let's talk financial health "
    }
  ],
  "descriptions": [
    {
      "language": "en-us",
      "value": "Being financially healthy means that you can comfortably meet all your immediate needs while working towards your future milestones."
    }
  ],
  "highlightList": {
    "title": "We evaluate financial health in 4 pillars:",
    "items": [
      {
        "descriptions": "Manage",
        "backgroundColor": "#E6EFF7",
        "borderType": "circle", // OR "borderType" : "square",
        "borderColor": "#0067C6",
        "icon": {
          "url": "business",
          "altText": [
            {
              "language": "en-us",
              "value": "save_alttext"
            }
          ]
        }
      },
      {
        "descriptions": "Save",
        "backgroundColor": "#E6EFF7",
        "borderType": "circle", // OR "borderType" : "square",
        "borderColor": "#0067C6",
        "icon": {
          "url": "business",
          "altText": [
            {
              "language": "en-us",
              "value": "save_alttext"
            }
          ]
        }
      },
      {
        "descriptions": "Borrow",
        "backgroundColor": "#E6EFF7",
        "borderType": "circle", // OR "borderType" : "square",
        "borderColor": "#0067C6",
        "icon": {
          "url": "borrow",
          "altText": [
            {
              "language": "en-us",
              "value": "save_alttext"
            }
          ]
        }
      },
      {
        "descriptions": "Plan Ahead",
        "backgroundColor": "#E6EFF7",
        "borderType": "circle", // OR "borderType" : "square",
        "borderColor": "#0067C6",
        "icon": {
          "url": "hands",
          "altText": [
            {
              "language": "en-us",
              "value": "save_alttext"
            }
          ]
        }
      }
    ]
  }
}
// RadioButtonList with subtitle
export const schemaFwbStep9 = {
  "id": 29,
  "stepType": "question",
  "controlType": "RadioButtonList",
  "titles": [
    {
      "language": "en-us",
      "value": "How would you describe your current spending habits overall?"
    }
  ],
  "descriptions": [
    {
      "language": "en-us",
      "value": "This includes day-to-day expenses (such as food, housing, utilities, entertainment, etc.) and does not include money you may be putting away into savings."
    }
  ],
  "answerOptions": [
    {
      "id": 1,
      "controlItemType": "RadioButtonListItem",
      "value": 1,
      "icon": {
        "imageUrl": "CareerIcon",
        "altText": [
          {
            "language": "en-us",
            "value": "Thumbs Up"
          },
          {
            "language": "fr-fr",
            "value": "Pouces vers le haut"
          }
        ]
      },
      "label": [
        {
          "language": "en-us",
          "value": "I spend much less than I make."
        },
        {
          "language": "fr-fr",
          "value": "Oui"
        }
      ],
      "subQuestions":
      {
        "id": 1,
        "controlItemType": "RadioButtonListItem",
        "value": 1,
        "title": [
          {
            "language": "en-us",
            "value": "and I’m planning to retire"
          }
        ],
        "answerOptions": [
          {
            "id": 4,
            "controlItemType": "RadioButtonListItem",
            "label": [
              {
                "language": "en-us",
                "value": "someday, but it's so far out I'm not planning for it yet."
              }
            ]
          },
          {
            "id": 5,
            "controlItemType": "RadioButtonListItem",
            "label": [
              {
                "language": "en-us",
                "value": "in the next",
                "descriptions": [
                  {
                    "language": "en-us",
                    "value": "years"
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    {
      "id": 2,
      "controlItemType": "RadioButtonListItem",
      "value": 2,
      "icon": {
        "imageUrl": 'CareerIcon',
        "altText": [
          {
            "language": "en-us",
            "value": "Thumbs Down"
          },
          {
            "language": "fr-fr",
            "value": "Pouces vers le bas"
          }
        ]
      },
      "label": [
        {
          "language": "en-us",
          "value": "I spend a little less than what I make."
        },
        {
          "language": "fr-fr",
          "value": "Non"
        }
      ],
      "subQuestions":
      {
        "id": 1,
        "controlItemType": "RadioButtonListItem",
        "value": "1",
        "title": [
          {
            "language": "en-us",
            "value": "this is my life stage at the moment"
          }
        ],
        "answerOptions": [
          {
            "id": 6,
            "controlItemType": "RadioButtonListItem",
            "label": [
              {
                "language": "en-us",
                "value": "Some life stage info"
              }
            ]
          },
          {
            "id": 7,
            "controlItemType": "RadioButtonListItem",
            "label": [
              {
                "language": "en-us",
                "value": "in the next years."
              }
            ]
          }
        ]
      }
    },
    {
      "id": 3,
      "controlItemType": "RadioButtonListItem",
      "value": 3,
      "icon": {
        "imageUrl": 'CareerIcon',
        "altText": [
          {
            "language": "en-us",
            "value": "Thumbs Down"
          },
          {
            "language": "fr-fr",
            "value": "Pouces vers le bas"
          }
        ]
      },
      "label": [
        {
          "language": "en-us",
          "value": "I spend about the same as what I make."
        },
        {
          "language": "fr-fr",
          "value": "Non"
        }
      ]
    },
    {
      "id": 4,
      "controlItemType": "RadioButtonListItem",
      "value": 4,
      "icon": {
        "imageUrl": 'CareerIcon',
        "altText": [
          {
            "language": "en-us",
            "value": "Thumbs Down"
          },
          {
            "language": "fr-fr",
            "value": "Pouces vers le bas"
          }
        ]
      },
      "label": [
        {
          "language": "en-us",
          "value": "I spend a little more than what I make."
        },
        {
          "language": "fr-fr",
          "value": "Non"
        }
      ]
    },
    {
      "id": 5,
      "controlItemType": "RadioButtonListItem",
      "value": 5,
      "icon": {
        "imageUrl": 'CareerIcon',
        "altText": [
          {
            "language": "en-us",
            "value": "Thumbs Down"
          },
          {
            "language": "fr-fr",
            "value": "Pouces vers le bas"
          }
        ]
      },
      "label": [
        {
          "language": "en-us",
          "value": "I spend much more than what I make."
        },
        {
          "language": "fr-fr",
          "value": "Non"
        }
      ]
    }
  ]
};

// Likert with subtitle
export const schemaFwbStep10 = {
  id: 30,
  stepType: "question",
  controlType: "Range",
  titles: [
    {
      language: "en-us",
      value: "How would you describe your current spending habits overall?"
    }
  ],
  descriptions: [
    {
      language: "en-us",
      value: "This includes day-to-day expenses (such as food, housing, utilities, entertainment, etc.) and does not include money you may be putting away into savings."
    }
  ],
  answerOptions: [
    {
      id: 1,
      controlItemType: "RangeItem",
      value: "1",
      label: [
        {
          language: "en-us",
          value: "Not confident at all",
        },
        {
          language: "fr-fr",
          value: "Pas du tout confiant",
        },
      ],
    },
    {
      id: 2,
      controlItemType: "RangeItem",
      value: "2",
    },
    {
      id: 3,
      controlItemType: "RangeItem",
      value: "3",
    },
    {
      id: 4,
      controlItemType: "RangeItem",
      value: "4",
    },
    {
      id: 5,
      controlItemType: "RangeItem",
      value: "5",
      label: [
        {
          language: "en-us",
          value: "Very confident",
        },
        {
          language: "fr-fr",
          value: "Très confiant",
        },
      ],
    },
  ]
}

// New ControlType: RangeWithRadioButtonList
export const schemaFwbStep17toStep19 = {
  "stepId": 1,
  "titles": [
    {
      "language": "en-us",
      "value": "How confident are you that your investment strategy will meet your goals?"
    }
  ],
  "descriptions": [
    {
      "language": "en-us",
      "value": "Your investment strategy should support your specific goals and help you plan for how much you need."
    }
  ],
  "stepType": "Question",
  "controlType": "Custom",//"RangeWithRadioButtonList",
  "answerOptions": [
    {
      "id": 1,
      "controlItemType": "RangeItem",
      "value": "1",
      "label": [
        {
          "language": "en-us",
          "value": "Not confident at all"
        }
      ]
    },
    {
      "id": 2,
      "controlItemType": "RangeItem",
      "value": "2",
      "label": [
        {
          "language": "en-us",
          "value": "Slightly confident"
        }
      ]
    },
    {
      "id": 3,
      "controlItemType": "RangeItem",
      "value": "3",
      "label": [
        {
          "language": "en-us",
          "value": "Somewhat confident"
        }
      ]
    },
    {
      "id": 4,
      "controlItemType": "RangeItem",
      "value": "4",
      "label": [
        {
          "language": "en-us",
          "value": "Moderately confident"
        }
      ]
    },
    {
      "id": 5,
      "controlItemType": "RangeItem",
      "value": "5",
      "label": [
        {
          "language": "en-us",
          "value": "Very confident"
        }
      ]
    },
    {
      "id": 6,
      "controlItemType": "RadioButtonListItem",
      "value": "1",
      "label": [
        {
          "language": "en-us",
          "value": "I don't have an investment strategy"
        }
      ]
    },
    {
      "id": 7,
      "controlItemType": "RadioButtonListItem",
      "value": "1",
      "label": [
        {
          "language": "en-us",
          "value": "I don't have an investment strategy"
        }
      ]
    }
  ]
};

let saveGoals = {
  "stepId": 16,
  "answers": [
    {
      "id": "17",
      "value": "3",
      "subAnswers": [
        {
          "stepId": "19",
          "answers": [
            {
              "id": "6",
              "value": "string",
              "subAnswers": [
                {
                  "stepId": "19",
                  "answers": [
                    {
                      "id": "6",
                      "value": ""
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "stepId": "25",
          "answers": [
            {
              "id": "2",
              "value": "string"
            }
          ]
        },
        {
          "stepId": "26",
          "answers": [{
            "id": "3",
            "value": "string",
          }]
        }
      ]
    }
  ]
};

export const resultsPageSchema = {
  "sections": [
    {
      "apllyFill": false,
      "sectionId": "intro",
      "header": {
        "order": 0,
        "titles": [
          {
            "language": "en-us",
            "value": "Take a moment to reflect, #placeholderName#.<br/>Let's take a look at where you are, and where you want to go."
          }
        ],
        "images": [
          {
            "device": "desktop",
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/header_desktop_02.jpg",
            "altText": [
              {
                "language": "en-us",
                "value": "header desktop"
              }
            ]
          },
          {
            "device": "mobile",
            "imageUrl": "path to img",
            "altText": [
              {
                "language": "en-us",
                "value": "header mobile"
              }
            ]
          }
        ]
      },
      "titles": [
        {
          "language": "en-us",
          "value": "Your path forward"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Review your results and explore resources to guide you through your journey."
        }
      ],
      "highlights": [
        {
          "sectionId": "TopOfMind",
          "description": [
            {
              "language": "en-us",
              "value": "Top of Mind"
            }
          ],
          "backgroundColor": "",
          "borderType": "square",
          "borderColor": "",
          "icon": [
            {
              "deviceType": "Desktop",
              "imageUrl": "https://widget.coastcapitalsavings.com/static/media/top-of-mind.svg",
              "altText": [
                {
                  "language": "en-us",
                  "value": "Top of Mind"
                }
              ]
            }
          ]
        },
        {
          "sectionId": "YourGoals",
          "description": [
            {
              "language": "en-us",
              "value": "Your goals"
            }
          ],
          "backgroundColor": "",
          "borderType": "square",
          "borderColor": "",
          "icon": [
            {
              "deviceType": "Desktop",
              "imageUrl": "https://widget.coastcapitalsavings.com/static/media/your-goals.svg",
              "altText": [
                {
                  "language": "en-us",
                  "value": "Your goals"
                }
              ]
            }
          ]
        },
        {
          "sectionId": "FinancialHealth",
          "description": [
            {
              "language": "en-us",
              "value": "Financial health"
            }
          ],
          "backgroundColor": "",
          "borderType": "square",
          "borderColor": "",
          "icon": [
            {
              "deviceType": "Desktop",
              "imageUrl": "https://widget.coastcapitalsavings.com/static/media/financial.svg",
              "altText": [
                {
                  "language": "en-us",
                  "value": "Financial health"
                }
              ]
            }
          ]
        }
      ],
      "status": null,
      "goals": null,
      "expandableItems": null,
      "contact": {
        "border": true,
        "title": [
          {
            "language": "en-us",
            "value": "We're in this together."
          }
        ],
        "descriptions": [
          {
            "language": "en-us",
            "value": "Connect with us to review your  results and build an action plan towards your goals"
          }
        ],
        "button": {
          "icon": null,
          "text": [
            {
              "language": "en-us",
              "value": "Connect with an Advisor"
            }
          ],
          "action": {
            "actionType": "expand",
            "href": null,
            "element": {
              "elementId": "xpto",
              "type": "panel",
              "visibility": "hidden",
              "elements": [
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/phone.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "TopOfMind"
                      }
                    ]
                  },
                  "description": [
                    {
                      "language": "en-US",
                      "value": "Mon-Sat, 8am-8pm, Sun, 9am-5:30pm"
                    }
                  ],
                  "link": {
                    "url": "tel:1-888-517-7000",
                    "title": [{ "language": "en-US", "value": "Call us at 1-888-517-7000" }],
                    "target": "_blank"
                  }
                },
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/calendar.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [{ "language": "en-US", "value": "Request an appointment" }],
                    "target": "_blank"
                  }
                }
              ]
            }
          }
        }
      }
    },
    {
      "sectionId": "TopOfMind",
      "applyFill": true,
      "icon": {
        "url": "https://widget.coastcapitalsavings.com/static/media/top-of-mind.svg",
        "altText": [
          {
            "language": "en-us",
            "value": "TopOfMind"
          }
        ]
      },
      "titles": [
        {
          "language": "en-us",
          "value": "Your top priority."
        }
      ],
      "highlights": null,
      "goals": null,
      "status": {
        "controlType": "status",
        "titles": [
          {
            "language": "en-us",
            "value": "Dealing with debt:"
          }
        ],
        "subTitle": [
          {
            "language": "en-us",
            "value": "Credit card or bills"
          }
        ],
        "descriptions": [
          {
            "language": "en-us",
            "value": "I have a bit more debt than is manageable"
          }
        ],
        "statusColor": "amber"
      },
      "contact": {
        "filled": true,
        "title": null,
        "subTitle": [
          {
            "language": "en-us",
            "value": "Breathe easy. We've got you."
          }
        ],
        "descriptions": [
          {
            "language": "en-us",
            "value": "Book your advice session now and we'll build the right plan together"
          }
        ],
        "button": {
          "icon": null,
          "text": [
            {
              "language": "en-us",
              "value": "Connect with an Advisor"
            }
          ],
          "action": {
            "actionType": "expand",
            "href": null,
            "element": {
              "elementId": "xpto",
              "type": "panel",
              "visibility": "hidden",
              "elements": [
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/phone.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "TopOfMind"
                      }
                    ]
                  },
                  "description": [
                    {
                      "language": "en-US",
                      "value": "Mon-Sat, 8am-8pm, Sun, 9am-5:30pm"
                    }
                  ],
                  "link": {
                    "url": "tel:1-888-517-7000",
                    "title": [{ "language": "en-US", "value": "Call us at 1-888-517-7000" }],
                    "target": "_blank"
                  }
                },
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/calendar.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [{ "language": "en-US", "value": "Request an appointment" }],
                    "target": "_blank"
                  }
                }
              ]
            }
          }
        }
      },
      "notes": [
        {
          "id": "TopOfMind",
          "titles": [
            {
              "value": "Top of Mind",
              "language": "en-US"
            }
          ],
          "value": "Lorem ipsum dolor sit amet..."
        },
        {
          "id": "TopOfMind",
          "titles": [
            {
              "value": "Top of Mind",
              "language": "en-US"
            }
          ],
          "value": "Lorem ipsum dolor sit amet..."
        }
      ]
    },
    {
      "sectionId": "YourGoals",
      "icon": {
        "url": "https://widget.coastcapitalsavings.com/static/media/your-goals.svg",
        "altText": [
          {
            "language": "en-us",
            "value": "TopOfMind"
          }
        ]
      },
      "titles": [
        {
          "value": "Your major goals",
          "language": "en-US"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Here's a summary of your future goals and how confident you feel about achieving them."
        }
      ],
      "goals": {
        "controlType": "Cards",
        "goalsItems": [
          {
            "subject": "First home",
            "time": "in 3 to 5 years",
            "state": "Moderately Confident",
            "statusColor": "green",
            "link": {
              "url": "https://www.google.pt",
              "title": [{
                "language": "en-US", "value": "Steps you can take"
              }],
              "target": "_blank"
            }
          },
          {
            "subject": "Start to invest",
            "time": "in 10+ years",
            "state": "Somewhat confident",
            "statusColor": "amber",
            "link": {
              "url": "https://www.google.pt",
              "title": [{
                "language": "en-US", "value": "Steps you can take"
              }],
              "target": "_blank"
            }
          },
          {
            "subject": "Saving for retirement",
            "time": "in 10+ years",
            "state": "Not confident at all",
            "statusColor": "red",
            "link": {
              "url": "https://www.google.pt",
              "title": [{
                "language": "en-US", "value": "Steps you can take"
              }],
              "target": "_blank"
            }
          }
        ]
      },
      "contact": {
        "title": null,
        "subTitle": null,
        "descriptions": null,
        "button": {
          "icon": null,
          "text": [
            {
              "language": "en-us",
              "value": "Connect with an Advisor"
            }
          ],
          "action": {
            "actionType": "expand",
            "href": null,
            "element": {
              "elementId": "xpto",
              "type": "panel",
              "visibility": "hidden",
              "elements": [
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/phone.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "description": [
                    {
                      "language": "en-US",
                      "value": "Mon-Sat, 8am-8pm, Sun, 9am-5:30pm"
                    }
                  ],
                  "link": {
                    "url": "tel:1-888-517-7000",
                    "title": [{
                      "language": "en-US", "value": "Call us at 1-888-517-7000"
                    }],
                    "target": "_blank"
                  }
                },
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/calendar.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [{
                      "language": "en-US", "value": "Request an appointment"
                    }],
                    "target": "_blank"
                  }
                }
              ]
            }
          }
        }
      },
      "notes": [
        {
          "id": "Goals",
          "titles": [
            {
              "value": "",
              "language": "en-US"
            }
          ],
          "value": "Lorem ipsum dolor sit amet..."
        }
      ]
    },
    {
      "apllyFill": false,
      "sectionId": "FinancialHealth",
      "icon": {
        "url": "https://widget.coastcapitalsavings.com/static/media/financial.svg",
        "altText": [
          {
            "language": "en-us",
            "value": "YourFinancialHealth"
          }
        ]
      },
      "titles": [
        {
          "value": "Your Financial Health",
          "language": "en-US"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Here's an assessment of how you're managing, sving, borrowing and planning."
        }
      ],
      "expandableItems": [
        {
          "icon": {
            "url": "https://widget.coastcapitalsavings.com/static/media/wallet.svg",
            "altText": [
              {
                "laguage": "en-us",
                "value": "Wallet"
              }
            ]
          },
          "titles": [
            {
              "value": "Manage money",
              "language": "en-US"
            }
          ],
          "range": {
            "color": "green",
            "currentValue": 3,
            "rangeOptions": [
              {
                "id": "1",
                "value": "1",
                "label": [{}]
              },
              {
                "id": "2",
                "value": "2",
                "label": [{}]
              },
              {
                "id": "3",
                "value": "3",
                "label": [
                  {
                    "value": "Doing well",
                    "language": "en-US"
                  }
                ]
              }
            ]
          },
          "items": [
            {
              "titles": [
                {
                  "value": "I spend less than my income",
                  "language": "en-US"
                }
              ],
              "range": {
                "color": "green",
                "currentValue": 3,
                "rangeOptions": [
                  {
                    "id": "1",
                    "controlItemType": "RangeItem",
                    "value": "1",
                    "label": [{}]
                  },
                  {
                    "id": "2",
                    "controlItemType": "RangeItem",
                    "value": "2",
                    "label": [{}]
                  },
                  {
                    "id": "3",
                    "controlItemType": "RangeItem",
                    "value": "3",
                    "label": [
                      {
                        "value": "Doing well",
                        "language": "en-US"
                      }
                    ]
                  }
                ]
              },
              "link": {
                "url": "https://www.google.pt",
                "title": [{
                  "language": "en-US", "value": "Show me how to get even better"
                }],
                "target": "_blank"
              },
              "notes": [
                {
                  "id": "ExpandableItem",
                  "titles": [],
                  "value": "Lorem ipsum dolor sit amet..."
                }
              ]
            },
            {
              "titles": [
                {
                  "value": "I often keep track of where my money comes and goes",
                  "language": "en-US"
                }
              ],
              "range": {
                "color": "amber",
                "currentValue": 2,
                "rangeOptions": [
                  {
                    "id": "1",
                    "controlItemType": "RangeItem",
                    "value": "1",
                    "label": [{}]
                  },
                  {
                    "id": "2",
                    "controlItemType": "RangeItem",
                    "value": "2",
                    "label": [
                      {
                        "value": "Area to consider",
                        "language": "en-US"
                      }
                    ]
                  },
                  {
                    "id": "3",
                    "controlItemType": "RangeItem",
                    "value": "3",
                    "label": [{}]
                  }
                ]
              },
              "link": {
                "url": "https://www.google.pt",
                "title": [{
                  "language": "en-US", "value": "Show me how to get better"
                }],
                "target": "_blank"
              }
            },
            {
              "titles": [
                {
                  "value": "I always pay my bills on time",
                  "language": "en-US"
                }
              ],
              "range": {
                "color": "red",
                "currentValue": 1,
                "rangeOptions": [
                  {
                    "id": "1",
                    "controlItemType": "RangeItem",
                    "value": "1",
                    "label": [{
                      "value": "Needs Work",
                      "language": "en-US"
                    }]
                  },
                  {
                    "id": "2",
                    "controlItemType": "RangeItem",
                    "value": "2",
                    "label": [{}]
                  },
                  {
                    "id": "3",
                    "controlItemType": "RangeItem",
                    "value": "3",
                    "label": [{}]
                  }
                ]
              },
              "link": {
                "url": "https://www.google.pt",
                "title": [{
                  "language": "en-US", "value": "Show me how to improve"
                }],
                "target": "_blank"
              }
            }

          ]
        },
        {
          "icon": {
            "url": "https://widget.coastcapitalsavings.com/static/media/save-money.svg",
            "altText": [
              {
                "laguage": "en-us",
                "value": "Wallet"
              }
            ]
          },
          "titles": [
            {
              "value": "Save money",
              "language": "en-US"
            }
          ],
          "range": {
            "color": "amber",
            "currentValue": 2,
            "rangeOptions": [
              {
                "id": "1",
                "value": "1",
                "label": [{}]
              },
              {
                "id": "2",
                "value": "2",
                "label": [
                  {
                    "value": "Area to consider",
                    "language": "en-US"
                  }
                ]
              },
              {
                "id": "3",
                "value": "3",
                "label": [{}]
              }
            ]
          },
          "items": [
            {
              "titles": [
                {
                  "value": "I spend less than my income",
                  "language": "en-US"
                }
              ],
              "range": {
                "color": "green",
                "currentValue": 3,
                "rangeOptions": [
                  {
                    "id": "1",
                    "value": "1",
                    "label": [{}]
                  },
                  {
                    "id": "2",
                    "value": "2",
                    "label": [{}]
                  },
                  {
                    "id": "3",
                    "value": "3",
                    "label": [
                      {
                        "value": "Doing well",
                        "language": "en-US"
                      }
                    ]
                  }
                ]
              },
              "link": {
                "url": "https://www.google.pt",
                "title": [{
                  "language": "en-US", "value": "Show me how to get even better"
                }],
                "target": "_blank"
              }
            },
            {
              "titles": [
                {
                  "value": "I often keep track of where my money comes and goes",
                  "language": "en-US"
                }
              ],
              "range": {
                "color": "amber",
                "currentValue": 2,
                "rangeOptions": [
                  {
                    "id": "1",
                    "value": "1",
                    "label": [{}]
                  },
                  {
                    "id": "2",
                    "value": "2",
                    "label": [
                      {
                        "value": "Area to consider",
                        "language": "en-US"
                      }
                    ]
                  },
                  {
                    "id": "3",
                    "value": "3",
                    "label": [{}]
                  }
                ]
              },
              "link": {
                "url": "https://www.google.pt",
                "title": [{
                  "language": "en-US", "value": "Show me how to get even better"
                }],
                "target": "_blank"
              }
            }
          ]
        }
      ],
      "contact": {
        "title": [
          {
            "value": "Connect with an advisor and we'll walk you through each area together",
            "language": "en-US"
          }
        ],
        "subTitle": null,
        "descriptions": null,
        "button": {
          "icon": null,
          "text": [
            {
              "language": "en-us",
              "value": "Connect with an Advisor"
            }
          ],
          "action": {
            "actionType": "expand",
            "href": null,
            "element": {
              "elementId": "xpto",
              "type": "panel",
              "visibility": "hidden",
              "elements": [
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/phone.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "description": [
                    {
                      "language": "en-US",
                      "value": "Mon-Sat, 8am-8pm, Sun, 9am-5:30pm"
                    }
                  ],
                  "link": {
                    "url": "tel:1-888-517-7000",
                    "title": [{
                      "language": "en-US", "value": "Call us at 1-888-517-7000"
                    }],
                    "target": "_blank"
                  }
                },
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/calendar.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [{
                      "language": "en-US", "value": "Request an appointment"
                    }],
                    "target": "_blank"
                  }
                }
              ]
            }
          }
        }
      }
    },
    {
      "applyFill": false,
      "sectionId": "footer",
      "footer": {
        "text": [
          {
            "language": "en-Us",
            "value": "You last took the Money Chat on May 25, 2020 11:15"
          }
        ]
      }
    }
  ]
}

export const AssessmentButtonsSchema = [
  {
    "nextPage": "questionnaire",
    "icon": {
      "url": process.env.REACT_APP_ASSETS + "/static/media/new_assessment.svg",
      "altText": [
        {
          "language": "en-us",
          "value": "New Assessment"
        }
      ]
    },
    "titles": [
      {
        "language": "en-Us",
        "value": "New Assessment"
      }
    ],
  },
  {
    "nextPage": "export",
    "icon": {
      "url": process.env.REACT_APP_ASSETS + "/static/media/export-assessment.svg",
      "altText": [
        {
          "language": "en-us",
          "value": "Export Assessment"
        }
      ]
    },
    "titles": [
      {
        "language": "en-Us",
        "value": "Export Assessment"
      }
    ],
  },
  {
    "nextPage": "history",
    "icon": {
      "url": process.env.REACT_APP_ASSETS + "/static/media/history.svg",
      "altText": [
        {
          "language": "en-us",
          "value": "Past Assessment"
        }
      ]
    },
    "titles": [
      {
        "language": "en-Us",
        "value": "Past Assessments"
      }
    ]
  },
];

export const diorgSchema = {
  "sections": [
    {
      "apllyFill": false,
      "sectionId": "intro",
      "header": {
        "order": 0,
        "titles": [
          {
            "language": "en-us",
            "value": "Take a moment to reflect, #placeholderName#. Let's take a look at where you are, and where you want to go."
          }
        ],
        "images": [
          {
            "device": "desktop",
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/header_desktop_02.jpg",
            "altText": [
              {
                "language": "en-us",
                "value": "header desktop"
              }
            ]
          },
          {
            "device": "mobile",
            "imageUrl": "path to img",
            "altText": [
              {
                "language": "en-us",
                "value": "header mobile"
              }
            ]
          }
        ]
      },
      "title": [
        {
          "language": "en-us",
          "value": "Your path forward"
        }
      ],
      "description": [
        {
          "language": "en-us",
          "value": "Review your results and explore resources to guide you through your journey."
        }
      ],
      "highlights": [
        {
          "sectionId": "TopOfMind",
          "descriptions": [
            {
              "language": "en-us",
              "value": "Top of Mind"
            }
          ],
          "backgroundColor": "",
          "borderType": "square",
          "borderColor": "",
          "icon": [
            {
              "deviceType": "Desktop",
              "imageUrl": "https://ci-ccs-dmc-widget.itsector.pt/static/media/wallet.svg",
              "altText": [
                {
                  "language": "en-us",
                  "value": "Top of Mind"
                }
              ]
            }
          ]
        },
        {
          "sectionId": "YourGoals",
          "descriptions": [
            {
              "language": "en-us",
              "value": "Your goals"
            }
          ],
          "backgroundColor": "",
          "borderType": "square",
          "borderColor": "",
          "icon": [
            {
              "deviceType": "Desktop",
              "imageUrl": "https://ci-ccs-dmc-widget.itsector.pt/static/media/save-money.svg",
              "altText": [
                {
                  "language": "en-us",
                  "value": "Your goals"
                }
              ]
            }
          ]
        },
        {
          "sectionId": "FinancialHealth",
          "descriptions": [
            {
              "language": "en-us",
              "value": "Financial health"
            }
          ],
          "backgroundColor": "",
          "borderType": "square",
          "borderColor": "",
          "icon": [
            {
              "deviceType": "Desktop",
              "imageUrl": "https://ci-ccs-dmc-widget.itsector.pt/static/media/borrow.svg",
              "altText": [
                {
                  "language": "en-us",
                  "value": "Financial health"
                }
              ]
            }
          ]
        }
      ],
      "status": null,
      "goals": null,
      "expandableItems": null,
      "contact": {
        "border": true,
        "title": [
          {
            "language": "en-us",
            "value": "We're in this together."
          }
        ],
        "descriptions": [
          {
            "language": "en-us",
            "value": "Connect with us to review your  results and build an action plan towards your goals"
          }
        ],
        "button": {
          "icon": {
            "url": "/static/media/wallet.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "Call"
              }
            ]
          },
          "text": [
            {
              "language": "en-us",
              "value": "Connect with an Advisor"
            }
          ],
          "action": {
            "actionType": "expand",
            "href": null,
            "element": {
              "elementId": "xpto",
              "type": "panel",
              "visibility": "hidden",
              "elements": [
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/wallet.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "description": [
                    {
                      "language": "en-US",
                      "value": "Mon-Sat, 8am-8pm, Sun, 9am-5:30pm"
                    }
                  ],
                  "link": {
                    "url": "tel:1-888-517-7000",
                    "title": [{
                      "language": "en-US", "value": "Call us at 1-888-517-7000"
                    }],
                    "target": "_blank"
                  }
                },
                {
                  "icon": {
                    "url": "path to img",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [{
                      "language": "en-US", "value": "Request an appointment"
                    }],
                    "target": "_blank"
                  }
                }
              ]
            }
          }
        }
      }
    },
    {
      "sectionId": "TopOfMind",
      "applyFill": true,
      "icon": {
        "url": "path to image",
        "altText": [
          {
            "language": "en-us",
            "value": "TopOfMind"
          }
        ]
      },
      "titles": [
        {
          "language": "en-us",
          "value": "Your top priority."
        }
      ],
      "highlights": null,
      "goals": null,
      "status": {
        "controlType": "status",
        "title": [
          {
            "language": "en-us",
            "value": "Dealing with debt:"
          }
        ],
        "subTitle": [
          {
            "language": "en-us",
            "value": "Credit card or bills"
          }
        ],
        "descriptions": [
          {
            "language": "en-us",
            "value": "I have a bit more debt than is manageable"
          }
        ],
        "statusColor": "amber"
      },
      "contact": {
        "controlType": "notice",
        "filled": true,
        "title": null,
        "subTitle": [
          {
            "language": "en-us",
            "value": "Breathe easy. We've got you."
          }
        ],
        "descriptions": [
          {
            "language": "en-us",
            "value": "Review your results and explore resources to guide you through your journey."
          }
        ],
        "button": {
          "text": [
            {
              "language": "en-us",
              "value": "Talk to us today..."
            }
          ],
          "action": {
            "actionType": "expand",
            "href": null,
            "element": {
              "elementId": "xpto",
              "type": "panel",
              "visibility": "hidden",
              "elements": [
                {
                  "icon": {
                    "url": "https://widget.coastcapitalsavings.com/static/media/wallet.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "description": [
                    {
                      "language": "en-US",
                      "value": "Mon-Sat, 8am-8pm, Sun, 9am-5:30pm"
                    }
                  ],
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [{
                      "language": "en-US", "value": "Call us at 1-888-517-7000"
                    }],
                    "target": "_blank"
                  }
                },
                {
                  "icon": {
                    "url": "path to img",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [{
                      "language": "en-US", "value": "Request an appointment"
                    }],
                    "target": "_blank"
                  }
                }
              ]
            }
          }
        }
      }
    },
    {
      "sectionId": "YourGoals",
      "icon": {
        "url": "https://widget.coastcapitalsavings.com/static/media/top-of-mind.svg",
        "altText": [
          {
            "language": "en-us",
            "value": "TopOfMind"
          }
        ]
      },
      "titles": [
        {
          "value": "Your major goals",
          "language": "en-US"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Here's a summary of your future goals and how confident you feel about achieving them."
        }
      ],
      "goals": {
        "controlType": "Cards",
        "goalsItems": [
          {
            "subject": "Buying my first home",
            "time": "in 3 to 5 years",
            "state": "Moderately Confident",
            "statusColor": "amber",
            "link": {
              "url": "www.google.pt",
              "title": [
                {
                  "language": "en-us",
                  "value": "Call"
                }
              ],
              "target": "_blank"
            }
          },
          {
            "subject": "Start to invest",
            "time": "in 10+ years",
            "state": "Somewhat confident",
            "statusColor": "amber",
            "link": {
              "url": "www.google.pt",
              "title": [
                {
                  "language": "en-us",
                  "value": "Call"
                }
              ],
              "target": "_blank"
            }
          },
          {
            "subject": "Saving for retirement",
            "time": "in 10+ years",
            "state": "Not confident at all",
            "statusColor": "red",
            "link": {
              "url": "www.google.pt",
              "title": [
                {
                  "language": "en-us",
                  "value": "Call"
                }
              ],
              "target": "_blank"
            }
          }
        ]
      },
      "contact": {
        "controlType": "notice",
        "title": null,
        "subTitle": null,
        "descriptions": null,
        "button": {
          "icon": {
            "url": "path to image",
            "altText": [
              {
                "language": "en-us",
                "value": "Plan"
              }
            ]
          },
          "text": [
            {
              "language": "en-us",
              "value": "Let's build tour plan"
            }
          ],
          "action": {
            "actionType": "expand",
            "href": null,
            "element": {
              "elementId": "xpto",
              "type": "panel",
              "visibility": "hidden",
              "elements": [
                {
                  "icon": {
                    "url": "/static/media/wallet.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "description": [
                    {
                      "language": "en-US",
                      "value": "Mon-Sat, 8am-8pm, Sun, 9am-5:30pm"
                    }
                  ],
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ],
                    "target": "_blank"
                  }
                },
                {
                  "icon": {
                    "url": "path to img",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ],
                    "target": "_blank"
                  }
                }
              ]
            }
          }
        }
      }
    },
    {
      "apllyFill": false,
      "sectionId": "FinancialHealth",
      "icon": {
        "url": "/static/media/financial.svg",
        "altText": [
          {
            "language": "en-us",
            "value": "YourFinancialHealth"
          }
        ]
      },
      "titles": [
        {
          "value": "Your Financial Health",
          "language": "en-US"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Here's an assessment of how you're managing, saving, borrowing, and planning"
        }
      ],
      "expandableItems": [
        {
          "icon": {
            "url": "/static/media/wallet.svg",
            "altText": [
              {
                "laguage": "en-us",
                "value": "Wallet"
              }
            ]
          },
          "titles": [
            {
              "value": "Manage money",
              "language": "en-US"
            }
          ],
          "range": {
            "color": "green",
            "currentValue": 3,
            "rangeOptions": [
              {
                "id": "1",
                "value": "1",
                "label": [
                  {
                    "value": "Doing well",
                    "language": "en-US"
                  }
                ]
              },
              {
                "id": "2",
                "value": "2",
                "label": [
                  {
                    "value": "Doing well",
                    "language": "en-US"
                  }
                ]
              },
              {
                "id": "3",
                "value": "3",
                "label": [
                  {
                    "value": "Doing well",
                    "language": "en-US"
                  }
                ]
              }
            ]
          },
          "items": [
            {
              "titles": [
                {
                  "value": "I spend less than my income",
                  "language": "en-US"
                }
              ],
              "range": {
                "color": "green",
                "currentValue": 2,
                "rangeOptions": [
                  {
                    "id": "1",
                    "controlItemType": "RangeItem",
                    "value": "1",
                    "label": [{}]
                  },
                  {
                    "id": "2",
                    "controlItemType": "RangeItem",
                    "value": "2",
                    "label": [
                      {
                        "value": "Doing well",
                        "language": "en-US"
                      }
                    ]
                  },
                  {
                    "id": "3",
                    "controlItemType": "RangeItem",
                    "value": "3",
                    "label": [{}]
                  }
                ]
              },
              "link": {
                "url": "www.google.pt",
                "title": [
                  {
                    "language": "en-us",
                    "value": "Call"
                  }
                ],
                "target": "_blank"
              }
            },
            {
              "titles": [
                {
                  "value": "I often keep track of where my money comes and goes",
                  "language": "en-US"
                }
              ],
              "range": {
                "color": "red",
                "currentValue": 1,
                "rangeOptions": [
                  {
                    "id": "1",
                    "controlItemType": "RangeItem",
                    "value": "1",
                    "label": [
                      {
                        "value": "Needs work",
                        "language": "en-US"
                      }
                    ]
                  },
                  {
                    "id": "2",
                    "controlItemType": "RangeItem",
                    "value": "2",
                    "label": [{}]
                  },
                  {
                    "id": "3",
                    "controlItemType": "RangeItem",
                    "value": "3",
                    "label": [{}]
                  }
                ]
              },
              "link": {
                "url": "www.google.pt",
                "title": [
                  {
                    "language": "en-us",
                    "value": "Call"
                  }
                ],
                "target": "_blank"
              }
            }
          ]
        },
        {
          "icon": {
            "url": "/static/media/wallet.svg",
            "altText": [
              {
                "laguage": "en-us",
                "value": "Wallet"
              }
            ]
          },
          "titles": [
            {
              "value": "Save money",
              "language": "en-US"
            }
          ],
          "range": {
            "color": "amber",
            "currentValue": 2,
            "rangeOptions": [
              {
                "id": "1",
                "value": "1",
                "label": [
                  {
                    "value": "Area to consider",
                    "language": "en-US"
                  }
                ]
              },
              {
                "id": "2",
                "value": "2",
                "label": [
                  {
                    "value": "Area to consider",
                    "language": "en-US"
                  }
                ]
              },
              {
                "id": "3",
                "value": "3",
                "label": [
                  {
                    "value": "Area to consider",
                    "language": "en-US"
                  }
                ]
              }
            ]
          },
          "items": [
            {
              "titles": [
                {
                  "value": "I spend less than my income",
                  "language": "en-US"
                }
              ],
              "range": {
                "color": "green",
                "currentValue": 3,
                "rangeOptions": [
                  {
                    "id": "1",
                    "value": "1",
                    "label": [
                      {
                        "value": "Doing well",
                        "language": "en-US"
                      }
                    ]
                  },
                  {
                    "id": "2",
                    "value": "2",
                    "label": [
                      {
                        "value": "Doing well",
                        "language": "en-US"
                      }
                    ]
                  },
                  {
                    "id": "3",
                    "value": "3",
                    "label": [
                      {
                        "value": "Doing well",
                        "language": "en-US"
                      }
                    ]
                  }
                ]
              },
              "link": {
                "url": "www.google.pt",
                "title": [
                  {
                    "language": "en-us",
                    "value": "Call"
                  }
                ],
                "target": "_blank"
              }
            },
            {
              "titles": [
                {
                  "value": "I often keep track of where my money comes and goes",
                  "language": "en-US"
                }
              ],
              "range": {
                "color": "amber",
                "currentValue": 2,
                "rangeOptions": [
                  {
                    "id": "1",
                    "value": "1",
                    "label": [{}]
                  },
                  {
                    "id": "2",
                    "value": "2",
                    "label": [
                      {
                        "value": "Area to consider",
                        "language": "en-US"
                      }
                    ]
                  },
                  {
                    "id": "3",
                    "value": "3",
                    "label": [{}]
                  }
                ]
              },
              "link": {
                "url": "https://www.google.pt",
                "title": [
                  {
                    "language": "en-us",
                    "value": "Call"
                  }
                ],
                "target": "_blank"
              }
            }
          ]
        }
      ],
      "contact": {
        "controlType": "notice",
        "title": [
          {
            "value": "Connect with an advisor and we'll walk you...",
            "language": "en-US"
          }
        ],
        "subTitle": null,
        "descriptions": null,
        "button": {
          "icon": {
            "url": "path to image",
            "altText": [
              {
                "language": "en-us",
                "value": "Message"
              }
            ]
          },
          "text": [
            {
              "language": "en-us",
              "value": "Let's talk"
            }
          ],
          "action": {
            "actionType": "expand",
            "href": null,
            "element": {
              "elementId": "xpto",
              "type": "panel",
              "visibility": "hidden",
              "elements": [
                {
                  "icon": {
                    "url": "/static/media/wallet.svg",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "description": [
                    {
                      "language": "en-US",
                      "value": "Mon-Sat, 8am-8pm, Sun, 9am-5:30pm"
                    }
                  ],
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ],
                    "target": "_blank"
                  }
                },
                {
                  "icon": {
                    "url": "path to img",
                    "altText": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ]
                  },
                  "link": {
                    "url": "https://www.google.pt",
                    "title": [
                      {
                        "language": "en-us",
                        "value": "Call"
                      }
                    ],
                    "target": "_blank"
                  }
                }
              ]
            }
          }
        }
      }
    },
    {
      "applyFill": false,
      "sectionId": "footer",
      "footer": {
        "text": [
          {
            "language": "en-Us",
            "value": "You last took the Money Chat on May 25, 2020 11:15"
          }
        ]
      }
    }
  ]
};

export const resumedDmcSchema = {
  dmcId: "5fb7ae6b58685367e5758ad5",
  status: "started", //completed
  // steps taken list on the dmc
  steps: [
    //textInput step
    {
      "stepId": 5,
      "showNote": true,
      "isLastStep": false,
      "stepType": "Question",
      "tag": "topOfMindQ2.2a",
      "crmTag": "TOM-BLC-Desc",
      "controlType": "TextBox",
      "titles": [
        {
          "language": "en-us",
          "value": "You're anticipating, experiencing, or adjusting to a big life change. We'd like to ask you a few questions to understand your situation better."
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "What big life change are you experiencing/anticipating?"
        }
      ],
    },
    //range step
    {
      "stepId": 6,
      "showNote": true,
      "isLastStep": false,
      "stepType": "Question",
      "tag": "topOfMindQ2.2b",
      "crmTag": "TOM-BLC-ConfLvl",
      "controlType": "Range",
      "titles": [
        {
          "language": "en-us",
          "value": "How confident do you feel in handling this big life change?"
        }
      ],
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RangeItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "Not confident at all"
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RangeItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "Slightly confident"
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RangeItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "Somewhat confident"
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RangeItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "Moderately confident"
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RangeItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "Very confident"
            }
          ]
        }
      ]
    },
    //lifstage step
    {
      "stepId": 14,
      "isLastStep": false,
      "showNote": true,
      "stepType": "Question",
      "tag": "lifeStageQ3.3a",
      "crmTag": "LS-Stage",
      "controlType": "IconRadioButtonList",
      "titles": [
        {
          "language": "en-us",
          "value": "Which of the following best describes where you're at today?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "I'm currently"
        }
      ],
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "going to school"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/school.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "going to school"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "exploring my options"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/exploring.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "exploring my options"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "building my career"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/career.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "building my career"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "maintaining my career"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/career2.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "maintaining my career"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "enjoying retirement"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/retirement.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "enjoying retirement"
              }
            ]
          }
        },
        {
          "id": 6,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "6",
          "label": [
            {
              "language": "en-us",
              "value": "Other"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/other.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "Other"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 2,
              "controlType": "TextBox"
            },
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
  ],
  answers: [
    //text input answer
    {
      stepId: 5,
      answers: [
        {
          optionId: null,
          value: "Test answers"
        }
      ]
    },
    //range answer
    {
      stepId: 6,
      answers: [
        {
          optionId: "4",
          value: "Moderately confident"
        }
      ]
    },
    //lifestage answer
    {
      stepId: 14,
      answers: [
        {
          optionId: "2",
          value: "exploring my options",
          subAnswers: [
            {
              answers: [
                {
                  optionId: "1",
                  value: "someday but it's so far out I'm not planning for it yet."
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  answersPairs: [
    {
      stepId: 5,
      answerId: "step1answer"
    },
    {
      stepId: 6,
      answerId: "step1answer"
    },
    {
      stepId: 14,
      answerId: "step1answer"
    }
  ]
}

export const historyPageSchema = {
  "title": [
    {
      "language": "en-Us",
      "value": "Assessments"
    }
  ],
  "recentTitle": [
    {
      "language": "en-Us",
      "value": "Most recent assessment"
    }
  ],
  "pastTitle": [
    {
      "language": "en-Us",
      "value": "Past assessments"
    }
  ],
  "assessments": [
    {
      "dmcId": "5fb7b645f855662fe3462af0",
      "startDate": {
        "value": "November, 2020",
      },
      "endDate": {
        "value": "November 20th 10:27 am",
        "text": [
          {
            "language": "en-US",
            "value": "Completed on:"
          }
        ]
      },
      "steps": [
        {
          "title": [
            {
              "language": "en-US",
              "value": "Top of mind"
            }
          ],
          "answers": [
            {
              "language": "en-US",
              "value": "Dealing with debt"
            }
          ]
        },
        {
          "title": [
            {
              "language": "en-US",
              "value": "Goals"
            }
          ],
          "answers": [
            {
              "language": "en-US",
              "value": "Buying my first home"
            },
            {
              "language": "en-US",
              "value": "Start to invest"
            },
            {
              "language": "en-US",
              "value": "Saving for retirement"
            }
          ]
        }
      ],
      "link": [
        {
          "language": "en-US",
          "value": "See Results"
        }
      ]
    },
    {
      "dmcId": "5fb7b645f855662fe3462af0",
      "startDate": {
        "value": "November, 2020",
      },
      "endDate": {
        "value": "November 19th 11:19 am",
        "text": [
          {
            "language": "en-US",
            "value": "Completed on:"
          }
        ]
      },
      "steps": [
        {
          "title": [
            {
              "language": "en-US",
              "value": "Top of mind"
            }
          ],
          "answers": [
            {
              "language": "en-US",
              "value": "Dealing with debt"
            }
          ]
        },
        {
          "title": [
            {
              "language": "en-US",
              "value": "Goals"
            }
          ],
          "answers": [
            {
              "language": "en-US",
              "value": "Buying my first home"
            },
            {
              "language": "en-US",
              "value": "Start to invest"
            },
            {
              "language": "en-US",
              "value": "Saving for retirement"
            }
          ]
        }
      ],
      "link": [
        {
          "language": "en-US",
          "value": "See Results"
        }
      ]
    }
  ]
}

export const testResumeResponse = {
  "dmcId": "5fbbc3d9863b97bc28303393",
  "status": "start",
  "steps": [
    {
      "stepId": 1,
      "section": {
        "id": "1",
        "name": "About you"
      },
      "isLastStep": false,
      "stepType": "Info",
      "images": [
        {
          "deviceType": "Desktop",
          "imageUrl": "https://widget.coastcapitalsavings.com/static/media/header_desktop_02.jpg",
          "altText": [
            {
              "language": "en-us",
              "value": "header_desktop_alttext"
            }
          ]
        },
        {
          "deviceType": "Mobile",
          "imageUrl": "https://widget.coastcapitalsavings.com/static/media/mobile_header_02.png",
          "altText": [
            {
              "language": "en-us",
              "value": "header_mobile_alttext"
            }
          ]
        }
      ],
      "tag": "topOfMindI0",
      "showNote": false,
      "titles": [
        {
          "language": "en-us",
          "value": "Tell us about yourself"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "We want to carve out a clear path forward that's completely tailored to you and your needs. But first we need to know more about you. What's top of mind, what life stage you're in, and what goals you want to achieve. "
        }
      ]
    },
    {
      "stepId": 2,
      "section": {
        "id": "2",
        "name": "Top of mind"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "topOfMindQ1",
      "crmTag": "TOM-TopOfMind",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "Which of the below would you say is top of mind for you right now?"
        }
      ],
      "controlType": "RadioButtonList",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RadioButtonListItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "I'm about to make a big decision."
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RadioButtonListItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "I'm anticipating, experiencing, or adjusting to a big life change. "
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RadioButtonListItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "I'm dealing with more debt than I would like. "
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RadioButtonListItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "I'm dealing with a financial emergency."
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RadioButtonListItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "I'm just curious about my finances today."
            }
          ]
        }
      ]
    },
    {
      "stepId": 10,
      "section": {
        "id": "2",
        "name": "Top of mind"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "topOfMindQ2.4a",
      "crmTag": "TOM-FE-Desc",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "You've indicated that you're dealing with a financial emergency. We'd like to ask you a few questions to understand your situation better."
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "What financial emergency are you dealing with at the moment?"
        }
      ],
      "controlType": "TextBox"
    },
    {
      "stepId": 11,
      "section": {
        "id": "2",
        "name": "Top of mind"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "topOfMindQ2.4b",
      "crmTag": "TOM-FE-ConfLvl",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "How do you feel about your ability to deal with the emergency you are experiencing?"
        }
      ],
      "controlType": "Range",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RangeItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "Not confident at all"
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RangeItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "Slightly confident"
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RangeItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "Somewhat confident"
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RangeItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "Moderately confident"
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RangeItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "Very confident"
            }
          ]
        }
      ]
    },
    {
      "stepId": 13,
      "section": {
        "id": "3",
        "name": "Life Stages"
      },
      "isLastStep": false,
      "stepType": "Info",
      "images": [
        {
          "deviceType": "Desktop",
          "imageUrl": "https://widget.coastcapitalsavings.com/static/media/header_desktop_02.jpg",
          "altText": [
            {
              "language": "en-us",
              "value": "header_desktop_alttext"
            }
          ]
        },
        {
          "deviceType": "Mobile",
          "imageUrl": "https://widget.coastcapitalsavings.com/static/media/mobile_header_02.png",
          "altText": [
            {
              "language": "en-us",
              "value": "header_mobile_alttext"
            }
          ]
        }
      ],
      "tag": "lifeStageI0",
      "showNote": false,
      "titles": [
        {
          "language": "en-us",
          "value": "Where are you today?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Knowing where you're going starts with knowing where you stand today. This will help us build the right path forward together, and ensure it's personalized to you and your needs. "
        }
      ]
    },
    {
      "stepId": 14,
      "section": {
        "id": "3",
        "name": "Life Stages"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "lifeStageQ3.3a",
      "crmTag": "LS-Stage",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "Which of the following best describes where you're at today?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "I'm currently"
        }
      ],
      "controlType": "IconRadioButtonList",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "going to school"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/school.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "going to school"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "propagate": true,
              "showNote": true,
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "exploring my options"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/exploring.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "exploring my options"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "propagate": true,
              "showNote": true,
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "building my career"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/career.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "building my career"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "propagate": true,
              "showNote": true,
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "maintaining my career"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/career2.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "maintaining my career"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "propagate": true,
              "showNote": true,
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "enjoying retirement"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/retirement.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "enjoying retirement"
              }
            ]
          }
        },
        {
          "id": 6,
          "controlItemType": "RadioButtonListWithIconItem",
          "value": "6",
          "label": [
            {
              "language": "en-us",
              "value": "Other"
            }
          ],
          "icon": {
            "imageUrl": "https://widget.coastcapitalsavings.com/static/media/other.svg",
            "altText": [
              {
                "language": "en-us",
                "value": "Other"
              }
            ]
          },
          "subQuestions": [
            {
              "id": 2,
              "showNote": true,
              "controlType": "TextBox"
            },
            {
              "id": 1,
              "tag": "lifeStageQ3.3b",
              "crmTag": "LS-Years2Retire",
              "propagate": true,
              "showNote": true,
              "titles": [
                {
                  "language": "en-us",
                  "value": "and I'm planning to retire"
                }
              ],
              "controlType": "RadioButtonList",
              "answerOptions": [
                {
                  "id": 1,
                  "controlItemType": "RadioButtonListItem",
                  "value": "1",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "someday, but it's so far out I'm not planning for it yet."
                    }
                  ]
                },
                {
                  "id": 2,
                  "controlItemType": "RadioButtonListWithFillItem",
                  "value": "2",
                  "label": [
                    {
                      "language": "en-us",
                      "value": "in the next "
                    }
                  ],
                  "description": [
                    {
                      "language": "en-us",
                      "value": "years."
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "stepId": 15,
      "section": {
        "id": "4",
        "name": "Goal"
      },
      "isLastStep": false,
      "stepType": "Info",
      "images": [
        {
          "deviceType": "Desktop",
          "imageUrl": "https://widget.coastcapitalsavings.com/static/media/header_desktop_02.jpg",
          "altText": [
            {
              "language": "en-us",
              "value": "header_desktop_alttext"
            }
          ]
        },
        {
          "deviceType": "Mobile",
          "imageUrl": "https://widget.coastcapitalsavings.com/static/media/mobile_header_02.png",
          "altText": [
            {
              "language": "en-us",
              "value": "header_mobile_alttext"
            }
          ]
        }
      ],
      "tag": "goalsI0",
      "showNote": false,
      "titles": [
        {
          "language": "en-us",
          "value": "What do you want for tomorrow?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Now that we know where you're at, we want to know about your vision for your future. Tell us what's important to you, and we'll put you on track to achieve it."
        }
      ]
    },
    {
      "stepId": 16,
      "section": {
        "id": "4",
        "name": "Goal"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "goalsQ4",
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "What are you working towards?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Select 1-3 goals and rank them by priority."
        }
      ],
      "controlType": "GoalSelector",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "GoalSelectorItem",
          "value": "1"
        },
        {
          "id": 2,
          "controlItemType": "GoalSelectorItem",
          "value": "2"
        },
        {
          "id": 3,
          "controlItemType": "GoalSelectorItem",
          "value": "3"
        }
      ]
    },
    {
      "stepId": 27,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Info",
      "images": [
        {
          "deviceType": "Desktop",
          "imageUrl": "https://widget.coastcapitalsavings.com/static/media/header_desktop_02.jpg",
          "altText": [
            {
              "language": "en-us",
              "value": "header_desktop_alttext"
            }
          ]
        },
        {
          "deviceType": "Mobile",
          "imageUrl": "https://widget.coastcapitalsavings.com/static/media/mobile_header_02.png",
          "altText": [
            {
              "language": "en-us",
              "value": "header_mobile_alttext"
            }
          ]
        }
      ],
      "tag": "fwbI0",
      "showNote": false,
      "titles": [
        {
          "language": "en-us",
          "value": "Let's talk financial health "
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Financial health refers to your ability to comfortably meet all your immediate needs while working towards your future milestones. We asses financial health across 4 categories:"
        }
      ],
      "highlights": {
        "title": [
          {
            "language": "en-us",
            "value": ""
          }
        ],
        "highlightItems": [
          {
            "description": [
              {
                "language": "en-us",
                "value": "Manage"
              }
            ],
            "backgroundColor": "#E6EFF7",
            "borderType": "Circle",
            "borderColor": "#0067C6",
            "icon": [
              {
                "deviceType": "Desktop",
                "imageUrl": "https://widget.coastcapitalsavings.com/static/media/wallet.svg",
                "altText": [
                  {
                    "language": "en-us",
                    "value": "Manage"
                  }
                ]
              }
            ]
          },
          {
            "description": [
              {
                "language": "en-us",
                "value": "Save"
              }
            ],
            "backgroundColor": "#E6EFF7",
            "borderType": "Circle",
            "borderColor": "#0067C6",
            "icon": [
              {
                "deviceType": "Desktop",
                "imageUrl": "https://widget.coastcapitalsavings.com/static/media/save-money.svg",
                "altText": [
                  {
                    "language": "en-us",
                    "value": "Save"
                  }
                ]
              }
            ]
          },
          {
            "description": [
              {
                "language": "en-us",
                "value": "Borrow"
              }
            ],
            "backgroundColor": "#E6EFF7",
            "borderType": "Circle",
            "borderColor": "#0067C6",
            "icon": [
              {
                "deviceType": "Desktop",
                "imageUrl": "https://widget.coastcapitalsavings.com/static/media/borrow.svg",
                "altText": [
                  {
                    "language": "en-us",
                    "value": "Borrow"
                  }
                ]
              }
            ]
          },
          {
            "description": [
              {
                "language": "en-us",
                "value": "Plan Ahead"
              }
            ],
            "backgroundColor": "#E6EFF7",
            "borderType": "Circle",
            "borderColor": "#0067C6",
            "icon": [
              {
                "deviceType": "Desktop",
                "imageUrl": "https://widget.coastcapitalsavings.com/static/media/plan.svg",
                "altText": [
                  {
                    "language": "en-us",
                    "value": "Plan Ahead"
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    {
      "stepId": 30,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "fwbQ5.v3",
      "crmTag": "FWB-M-SpendingHabits",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "You told us that you are dealing with a financial emergency."
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "How does this affect your current spending (this includes your day-to-day expenses such as food, housing utilities, entertainment, etc)?"
        }
      ],
      "controlType": "RadioButtonList",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RadioButtonListItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "I spend much less than I make."
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RadioButtonListItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "I spend a little less than what I make."
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RadioButtonListItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "I spend about the same as what I make."
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RadioButtonListItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "I spend a little more than what I make."
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RadioButtonListItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "I spend much more than what I make."
            }
          ]
        }
      ]
    },
    {
      "stepId": 31,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "fwbQ6",
      "crmTag": "FWB-M-ExpTracking",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "Do you keep track of how your money comes and goes?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "For example, do you keep a monthly budget or have a clear picture of your expenses?"
        }
      ],
      "controlType": "Range",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RangeItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "Never"
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RangeItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "Rarely"
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RangeItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "Sometimes"
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RangeItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "Very often"
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RangeItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "Always"
            }
          ]
        }
      ]
    },
    {
      "stepId": 32,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "fwbQ7",
      "crmTag": "FWB-M-BillPymnt",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "Over the last 12 months, have you been paying your bills on time and in full?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Bills mean regular, fixed expenses such as utilities, your cell phone bill, rent, credit cards, etc."
        }
      ],
      "controlType": "Range",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RangeItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "Never"
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RangeItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "Rarely"
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RangeItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "Sometimes"
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RangeItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "Very often"
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RangeItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "Always"
            }
          ]
        }
      ]
    },
    {
      "stepId": 34,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "fwbQ9",
      "crmTag": "FWB-B-CreditRating",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "Are you aware of your credit rating and what that means for your finances?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": ""
        }
      ],
      "controlType": "RadioButtonList",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RadioButtonListItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "Yes, I know my credit rating, and I'm doing well."
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RadioButtonListItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "Yes, I know my credit rating, but I'm not doing so well."
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RadioButtonListItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "Yes, I know my credit rating, but I don't know what it means"
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RadioButtonListItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "No, I don't know my credit rating, but I want to find out."
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RadioButtonListItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "No, I don't know my credit rating, but it's not important to me at this time."
            }
          ]
        },
        {
          "id": 6,
          "controlItemType": "RadioButtonListItem",
          "value": "6",
          "label": [
            {
              "language": "en-us",
              "value": "I don't have a credit history."
            }
          ]
        }
      ]
    },
    {
      "stepId": 35,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "fwbQ10",
      "crmTag": "FWB-B-DbPymnt",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "Are you paying off your debts as fast as you’d like?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Debts can include student loans, bank loans, credit card balances, mortgage payments, etc."
        }
      ],
      "controlType": "RadioButtonList",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RadioButtonListItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "Yes, I feel confident and on track with my payments."
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RadioButtonListItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "No, I am not paying off my debts off as fast as I would like."
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RadioButtonListItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "I do not have any debts."
            }
          ]
        }
      ]
    },
    {
      "stepId": 36,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "fwbQ11",
      "crmTag": "FWB-B-DbImpact",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "How do you feel about your current debts and how they’re affecting your goal(s)?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "This can include student loans, bank loans, mortgages, bills, credit card balances, etc."
        }
      ],
      "controlType": "RadioButtonList",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RadioButtonListItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "My goals are not at all affected."
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RadioButtonListItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "My goals are somewhat affected."
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RadioButtonListItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "My goals are definitely affected."
            }
          ]
        }
      ]
    },
    {
      "stepId": 37,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "fwbQ12",
      "crmTag": "FWB-S-InvProfile",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "Are you actively investing and informed about your investments?"
        }
      ],
      "controlType": "RadioButtonList",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RadioButtonListItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "I'm investing, and I feel informed."
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RadioButtonListItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "I'm investing, but I want to know how I could do better."
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RadioButtonListItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "I'm not investing, but I want to know how to start."
            }
          ]
        }
      ]
    },
    {
      "stepId": 38,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "fwbQ13",
      "crmTag": "FWB-S-InvConfLvl",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "How confident are you that your investment strategy will meet your goals?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "Your investment strategy should support your specific goals and help you plan for how much you need."
        }
      ],
      "controlType": "Custom",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RangeItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "Not confident at all"
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RangeItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "Slightly confident"
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RangeItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "Somewhat confident"
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RangeItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "Moderately confident"
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RangeItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "Very confident"
            }
          ]
        },
        {
          "id": 6,
          "controlItemType": "RadioButtonListItem",
          "value": "6",
          "label": [
            {
              "language": "en-us",
              "value": "I don't have an investment strategy"
            }
          ]
        }
      ]
    },
    {
      "stepId": 39,
      "section": {
        "id": "5",
        "name": "Financial well being"
      },
      "isLastStep": false,
      "stepType": "Question",
      "tag": "fwbQ14",
      "crmTag": "FWB-P-InsConfLvl",
      "propagate": true,
      "showNote": true,
      "titles": [
        {
          "language": "en-us",
          "value": "Are you confident that your insurance policies will provide enough support in case of an emergency?"
        }
      ],
      "descriptions": [
        {
          "language": "en-us",
          "value": "This could include the amount of coverage you have for vehicle insurance, home/rental insurance, life insurance, and disability insurance."
        }
      ],
      "controlType": "Custom",
      "answerOptions": [
        {
          "id": 1,
          "controlItemType": "RangeItem",
          "value": "1",
          "label": [
            {
              "language": "en-us",
              "value": "Not confident at all"
            }
          ]
        },
        {
          "id": 2,
          "controlItemType": "RangeItem",
          "value": "2",
          "label": [
            {
              "language": "en-us",
              "value": "Slightly confident"
            }
          ]
        },
        {
          "id": 3,
          "controlItemType": "RangeItem",
          "value": "3",
          "label": [
            {
              "language": "en-us",
              "value": "Somewhat confident"
            }
          ]
        },
        {
          "id": 4,
          "controlItemType": "RangeItem",
          "value": "4",
          "label": [
            {
              "language": "en-us",
              "value": "Moderately confident"
            }
          ]
        },
        {
          "id": 5,
          "controlItemType": "RangeItem",
          "value": "5",
          "label": [
            {
              "language": "en-us",
              "value": "Very confident"
            }
          ]
        },
        {
          "id": 6,
          "controlItemType": "RadioButtonListItem",
          "value": "6",
          "label": [
            {
              "language": "en-us",
              "value": "I don't know what my insurance coverage is"
            }
          ]
        },
        {
          "id": 7,
          "controlItemType": "RadioButtonListItem",
          "value": "7",
          "label": [
            {
              "language": "en-us",
              "value": "I don't have insurance"
            }
          ]
        }
      ]
    }
  ],
  "answers": [
    {
      "stepId": 1,
      "answers": []
    },
    {
      "stepId": 2,
      "answers": [
        {
          "optionId": "4",
          "value": "I'm dealing with a financial emergency."
        }
      ]
    },
    {
      "stepId": 10,
      "answers": [
        {
          "value": "treteterter"
        }
      ]
    },
    {
      "stepId": 11,
      "answers": [
        {
          "optionId": "5",
          "value": "Very confident"
        }
      ]
    },
    {
      "stepId": 13,
      "answers": []
    },
    {
      "stepId": 14,
      "answers": [
        {
          "optionId": "4",
          "value": "maintaining my career",
          "subAnswers": [
            {
              "stepId": "0",
              "startDate": "0001-01-01T00:00:00Z",
              "endDate": "2020-11-23T14:15:12.731Z",
              "answers": [
                {
                  "optionId": "1",
                  "value": "someday, but it's so far out I'm not planning for it yet.",
                  "subAnswers": []
                }
              ],
              "id": "5fbbc3f0863b97bc28303c6c"
            }
          ]
        }
      ]
    },
    {
      "stepId": 15,
      "answers": []
    },
    {
      "stepId": 16,
      "answers": [
        {
          "optionId": "1",
          "subAnswers": [
            {
              "stepId": "17",
              "startDate": "0001-01-01T00:00:00Z",
              "endDate": "2020-11-23T14:15:26.722Z",
              "answers": [
                {
                  "optionId": "3",
                  "value": "Pay down debts",
                  "subAnswers": []
                }
              ],
              "id": "5fbbc3fe863b97bc28303c85"
            },
            {
              "stepId": "22",
              "startDate": "0001-01-01T00:00:00Z",
              "endDate": "2020-11-23T14:15:26.722Z",
              "answers": [
                {
                  "optionId": "2",
                  "value": "Student Debt",
                  "subAnswers": []
                }
              ],
              "id": "5fbbc3fe863b97bc28303c86"
            },
            {
              "stepId": "25",
              "startDate": "0001-01-01T00:00:00Z",
              "endDate": "2020-11-23T14:15:26.722Z",
              "answers": [
                {
                  "optionId": "1",
                  "value": "In the next year",
                  "subAnswers": []
                }
              ],
              "id": "5fbbc3fe863b97bc28303c87"
            },
            {
              "stepId": "26",
              "startDate": "0001-01-01T00:00:00Z",
              "endDate": "2020-11-23T14:15:26.722Z",
              "answers": [
                {
                  "optionId": "5",
                  "value": "Very confident",
                  "subAnswers": []
                }
              ],
              "id": "5fbbc3fe863b97bc28303c88"
            }
          ]
        }
      ]
    },
    {
      "stepId": 27,
      "answers": []
    },
    {
      "stepId": 30,
      "answers": [
        {
          "optionId": "4",
          "value": "I spend a little more than what I make."
        }
      ]
    },
    {
      "stepId": 31,
      "answers": [
        {
          "optionId": "4",
          "value": "Very often"
        }
      ]
    },
    {
      "stepId": 32,
      "answers": [
        {
          "optionId": "5",
          "value": "Always"
        }
      ]
    },
    {
      "stepId": 34,
      "answers": [
        {
          "optionId": "4",
          "value": "No, I don't know my credit rating, but I want to find out."
        }
      ]
    },
    {
      "stepId": 35,
      "answers": [
        {
          "optionId": "1",
          "value": "Yes, I feel confident and on track with my payments."
        }
      ]
    },
    {
      "stepId": 36,
      "answers": [
        {
          "optionId": "1",
          "value": "My goals are not at all affected."
        }
      ]
    },
    {
      "stepId": 37,
      "answers": [
        {
          "optionId": "1",
          "value": "I'm investing, and I feel informed."
        }
      ]
    },
    {
      "stepId": 38,
      "answers": [
        {
          "optionId": "5",
          "value": "Very confident"
        }
      ]
    },
    {
      "stepId": 39,
      "answers": [
        {
          "optionId": "4",
          "value": "Moderately confident"
        }
      ]
    }
  ],
  "answersPairs": [
    {
      "stepId": 1,
      "answerId": "5fbbc3df863b97bc28303c59"
    },
    {
      "stepId": 2,
      "answerId": "5fbbc3e2863b97bc28303c5b"
    },
    {
      "stepId": 10,
      "answerId": "5fbbc3e7863b97bc28303c5e"
    },
    {
      "stepId": 11,
      "answerId": "5fbbc3ea863b97bc28303c62"
    },
    {
      "stepId": 13,
      "answerId": "5fbbc3ed863b97bc28303c67"
    },
    {
      "stepId": 14,
      "answerId": "5fbbc3f0863b97bc28303c6e"
    },
    {
      "stepId": 15,
      "answerId": "5fbbc3f3863b97bc28303c75"
    },
    {
      "stepId": 16,
      "answerId": "5fbbc3fe863b97bc28303c8a"
    },
    {
      "stepId": 27,
      "answerId": "5fbbc401863b97bc28303cae"
    },
    {
      "stepId": 30,
      "answerId": "5fbbc404863b97bc28303cd9"
    },
    {
      "stepId": 31,
      "answerId": "5fbbc40a863b97bc28303d07"
    },
    {
      "stepId": 32,
      "answerId": "5fbbc40d863b97bc28303d38"
    },
    {
      "stepId": 34,
      "answerId": "5fbbc412863b97bc28303d6c"
    },
    {
      "stepId": 35,
      "answerId": "5fbbc418863b97bc28303dd5"
    },
    {
      "stepId": 36,
      "answerId": "5fbbc41c863b97bc28303e22"
    },
    {
      "stepId": 37,
      "answerId": "5fbbc41f863b97bc28303e73"
    },
    {
      "stepId": 38,
      "answerId": "5fbbc424863b97bc28303edd"
    },
    {
      "stepId": 39,
      "answerId": "5fbbc428863b97bc28303f81"
    }
  ],
  "goals": [
    {
      "id": 1,
      "subject": "Line of credit",
      "time": "1 - 3 years",
      "heat": "orange",
      "state": "Somewhat confident",
    }
  ]
}