import React, { FC } from "react";
import "./Status.scss";

interface IProps {
    title: string;
    subTitle: string;
    description: string;
    statusColor: string;
}

const Status: FC<IProps> = ({ title, subTitle, description, statusColor }) => {
    const descriptionClass = ["description", statusColor].join(" ");
    return (
        <div className="status-item">
            <p className="title">{title}</p>
            <p className="subtitle">{subTitle}</p>
            <div className={descriptionClass}>
                <p className="text">{description}</p>
            </div>
        </div>
    );
}

export default Status;