import React, { useEffect } from "react";
import Likert from "react-likert-scale";
import "../styles/likert.scss";
import { useSelector } from "react-redux";



const LikertScale = ({ id, titles, answerOptions, currentValue, handleState, descriptions, staticScale, onSelect, handleStateModal }) => {
  let options = [];

  const showSummary = useSelector((state) => state.summary.showSummary);

  for (let i = 0; i < answerOptions.length; i++) {
    if (answerOptions[i].controlItemType === 'RangeItem' || staticScale) {
      let option = {
        value: answerOptions[i].value,
        text: answerOptions[i].label && answerOptions[i].label.length > 0 ? answerOptions[i].label[0].value : "",
      };
      options.push(option);
    }
  }

  const picked = val => {
    let inputs = document.getElementById("likert-" + id)?.querySelectorAll(".likertResponse input");
    let selectedValue = 'selectedValue';
    let selectedId = 0;
    let isLikertOption = document.getElementById("likert-" + id)?.querySelectorAll(`.likertResponse input[value='${val}']`).length !== 0;

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value === val.toString()) {
        selectedValue = inputs[i].parentElement.querySelector(".likertText").innerText;
        inputs[i].parentElement.querySelector('.likertText').style.display = 'block';
        document.getElementById("likert-" + id).querySelector(`.likertResponse input[value='${val}']`).checked = true;
        selectedId = inputs[i].value;

        let ariaString = inputs[i].closest(".likertResponse").getAttribute("aria-label")
        if (ariaString !== undefined && ariaString?.indexOf("unchecked") >= 0)
          inputs[i].closest(".likertResponse").ariaLabel = ariaString.split("unchecked")[0] + "checked";
      }
      else {
        let ariaString = inputs[i].closest(".likertResponse").getAttribute("aria-label")
        if (ariaString !== undefined && ariaString?.indexOf("unchecked") < 0)
          inputs[i].closest(".likertResponse").ariaLabel = ariaString.split("checked")[0] + "unchecked";

        if (isLikertOption) {
          inputs[i].parentElement.querySelector('.likertText').style.display = 'none';
        }
        else {
          inputs[0].parentElement.querySelector('.likertText').style.display = 'block';
          inputs[inputs.length - 1].parentElement.querySelector('.likertText').style.display = 'block';
        }
      }
    }

    if (isLikertOption) {
      if (handleState) handleState('range', selectedId, selectedValue);

      if (onSelect) {
        currentValue = "";
        onSelect(selectedId);
      }
    }
  }

  const assignCurrentValue = (likertId, likertCurrentValue) => {
    let inputs = document.getElementById("likert-" + likertId)?.querySelectorAll(".likertResponse input");
    let selectedValue = 'selectedValue';
    let selectedId = 0;
    let isLikertOption = document.getElementById("likert-" + likertId)?.querySelector(`.likertResponse input[value='${likertCurrentValue}']`)?.length !== 0;

    for (let i = 0; i < inputs.length; i++) {
      //let likert = inputs[i].parentElement.parentElement.parentElement.parentElement.id;
      if (inputs[i].value === likertCurrentValue.toString()) {
        selectedValue = inputs[i].parentElement.querySelector(".likertText").innerText;
        inputs[i].parentElement.querySelector('.likertText').style.display = 'block';
        document.getElementById("likert-" + likertId).querySelector(`.likertResponse input[value='${likertCurrentValue}']`).checked = true
        selectedId = inputs[i].value;
      }
      else {
        if (isLikertOption) {
          inputs[i].parentElement.querySelector('.likertText').style.display = 'none';
        }
      }
    }
  }

  /* --- disables Likert Scale  --- */
  const disableScale = (likertId) => {
    let inputs = document.getElementById("likert-" + likertId)?.querySelectorAll(".likertResponse input");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true;
      inputs[i].ariaHidden = "true";
    }
  }

  /* --- set scale lines color until current value input --- */
  const setScaleProgressColor = (likertId, currentValue) => {
    let scaleOption = document.getElementById("likert-" + likertId)?.querySelectorAll(".likertResponse");

    for (let i = 0; i < scaleOption.length; i++) {
      let optionInputValue = scaleOption[i].querySelector("input").value;

      if (optionInputValue !== currentValue.toString()) {
        scaleOption[i].querySelector(".likertIndicator").classList += " fill"
        scaleOption[i].querySelectorAll(".likertLine").forEach(line => {
          line.className += " fill"
        });
      }
      else {
        scaleOption[i].querySelector(".likertIndicator").classList += " fill"
        scaleOption[i].querySelectorAll(".likertLine").forEach(line => {
          if (!line.nextElementSibling.classList.contains("visuallyHidden")) {
            line.className += " fill"
          }
        });

        break;
      }
    }
  }

  function handleFocus(event) {
    if (event.target.type != "radio") {
      let texts = document.getElementById("likert-" + id)?.querySelectorAll(".likertResponse .likertText");

      if (texts && texts.length > 0) {
        for (let i = 0; i < texts.length; i++) {
          if (!texts[i].parentElement.querySelector("input").checked)
            texts[i].style.display = "none"
        }
      }

      event.target.querySelector(".likertText").style.display = "block";
    }
  }

  function handleLooseFocus(e) {
    //if focus moves outside of childrens
    if (!e.currentTarget.contains(e.relatedTarget)) {
      let texts = document.getElementById("likert-" + id)?.querySelectorAll(".likertResponse .likertText");

      if (texts && texts.length > 0) {
        for (let i = 0; i < texts.length; i++) {
          if (!texts[i].parentElement.querySelector("input").checked)
            texts[i].style.display = "none"
        }
      }
    }
  }

  function handleLabelClick(event) {
    if (event.key === "Enter" || event.key === " ") {
      let value = event.target.querySelector("input[type='radio']").value;
      let selectedId = event.target.querySelector("input[type='radio']").value;
      let classList = event.target.querySelector("input[type='radio']").classList;
      let textContent = event.target.querySelector(".likertText").textContent;

      picked(value)

      if (handleStateModal) handleStateModal(
        {
          target: {
            id: selectedId,
            value: value,
            classList: classList,
            nextElementSibling: {
              nextElementSibling: {
                textContent: textContent
              }
            }
          }
        }
      )
    }
  }

  useEffect(() => {

    if (!showSummary) {
      let labels = document.getElementById("likert-" + id)?.querySelectorAll(".likertResponse");
      if (labels && labels.length > 0) {
        for (let i = 0; i < labels.length; i++) {
          let isChecked = false;
          let labelID = labels[i].querySelector("input[type='radio']").value

          if (labelID && labelID === currentValue) isChecked = true;

          labels[i].tabIndex = 0;
          var likertResponseLines = Array.prototype.slice.call(labels[i].querySelectorAll("span"))
          if (likertResponseLines && likertResponseLines.length)
            likertResponseLines.forEach(line => line.ariaHidden = true)

          var radioBtn = labels[i].querySelector("input[type='radio']");
          radioBtn.ariaLabel = labels[i].querySelector(".likertText")?.innerHTML.toString();
          radioBtn.tabIndex = -1;
        }
      }
    }

    if (currentValue) {
      //static scale to use in results page
      if (staticScale) {
        assignCurrentValue(id, currentValue)
        disableScale(id);
        setScaleProgressColor(id, currentValue)
      }
      else {
        picked(currentValue);

        let element = document.getElementById("likert-" + id)?.querySelector(`.likertResponse input[value='${currentValue}']`);
        if (element) {
          element.checked = true;
        }
      }
    }
  }, []);

  return (
    <div id={"likert-" + id} className="likert-container"
      onFocus={(event) => handleFocus(event)}
      onKeyDown={(event) => handleLabelClick(event)}
      onBlur={(event) => { handleLooseFocus(event) }}>
      {titles && titles.length > 0 &&
        <p className={descriptions && descriptions.length > 0 ? "question-title" : "question-title without-description"}>
          {titles[0].value}
        </p>
      }
      {descriptions && descriptions.length > 0 &&
        <p className="subtitle">
          {descriptions[0].value}
        </p>
      }
      <form>
        <Likert responses={options} picked={picked} />
      </form>
    </div>
  )
};

export default LikertScale;