import React, { useState, useEffect } from 'react';

import Slider from 'react-slick';

import '../styles/carousel.scss';
import QuoteIcon from '../assets/svg/quote2.svg';

//helper function
//Focus and slide carousel to the respective slider item
function focusCarouselAccessibility() {
    let slickItemsList = document.querySelectorAll(".slick-slide")
    let slickItemsArray = slickItemsList && slickItemsList.length > 0 ? Array.prototype.slice.call(slickItemsList) : null;

    if (slickItemsArray && slickItemsArray.some(slickItem => slickItem.tabIndex === -1)) {
        slickItemsArray.forEach((slickItem, index) => {
            slickItem.tabIndex = 0;
            slickItem.ariaHidden = undefined;
            let quoteText = slickItem.querySelector(".slick-item .quote-content").textContent.replace(/"/g, '')
            slickItem.ariaLabel = `${quoteText} Review ${index + 1} of ${slickItemsArray.length}`
        })
    }
}

const Carousel = ({ title, quotes }) => {
    const [slider, setSlider] = useState();
    const [focusedSliderItem, setFocusedSliderItem] = useState()

    const sliderSettings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: function (index) { return <button alt={`Review ${index + 1}`} aria-label={`Review ${index + 1}`}>{index + 1}</button> },
        //Arrows to control the carousel if needed
        // nextArrow: (<NextArrow />),
        // prevArrow: (<PrevArrow />),
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false
                }
            }
        ],
    }

    useEffect(() => {
        focusCarouselAccessibility()
    })

    if (quotes) {
        return (
            <div className="spacing carousel">
                <div className="carousel-row row">
                    <div className="title-container my-auto">
                        {title &&
                            <h2 className="carousel-title">
                                {title[0].value}
                            </h2>
                        }
                    </div>
                    <div className="carousel-container">
                        <Slider ref={slider => setSlider(slider)} {...sliderSettings}>
                            {quotes.map((quote, index) =>
                                <div key={index} className="slick-item-holder" aria-hidden={true}>
                                    <div className="slick-item" id={index}>
                                        <img className="top-img" src={QuoteIcon} alt="quote-icon" aria-hidden={true} />
                                        <div className="quote-content">"{quote.value}"</div>
                                        <img className="bottom-img" src={QuoteIcon} alt="quote-icon" aria-hidden={true} />
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>
            </div >
        );
    }
}

export default Carousel;