import React from "react";
import "../../styles/alert.scss";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { setAlert } from "../../redux/actions";

type AlertProps = {
  success?: boolean;
  message?: string;
};

/**
 *
 * @param {boolean} success - Boolean. If true displays green success toaster, otherwise it displays a red toaster.
 * @param {string} message - String. The message to be shown in the toaster.
 *
 * @example
 *
 * <Alert success={true} message="This is the success message!" />
 */

const Alert = ({ success, message }: AlertProps) => {
  const reduxDispatcher = useDispatch();
  const alert = useSelector((state: RootStateOrAny) => state.alert); // Redux alert state

  const style = alert.success ? "success" : "failure";
  return (
    <>
      <div className={`${style} fadein`}>
        <span className="close_btn" onClick={() => reduxDispatcher(setAlert({ show: false }))}>
          &times;
        </span>
        <strong>{alert.success ? "Success" : "Error"}</strong>
        <p>{alert.message}</p>

        {alert.showButton && <button onClick={() => (window.location.href = "/")}>{alert.buttonMessage ? alert.buttonMessage : "Ok, take me back."}</button>}
      </div>
    </>
  );
};

export default Alert;
