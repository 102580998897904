import * as React from 'react';
import { Modal } from 'react-bootstrap';
import "./MessageModal.scss";
import { ReactComponent as IconClose } from "@assets/svg/close_white.svg";

interface IProps {
    show?: boolean,
    title: string,
    closeModal?: (boolean) => void,
    hideCloseButton: boolean
}

const MessageModal: React.FC<IProps> = (props) => {
    return <Modal
        show={props.show}
        className="confirm-modal"
        onHide={() => { if (props.closeModal) { props.closeModal(false) } }}
        size="lg"
        dialogClassName="message-modal-dialog"
        aria-labelledby="contained-modal-title-vcenter"
        container={document.getElementById("dmcWidget")}
        centered>
        <Modal.Header>
            <span className="modal-title">{props.title}</span>
            {!props.hideCloseButton &&
                <span className="modal-close-btn"
                    onClick={() => {
                        props.closeModal &&
                            props.closeModal(true)
                    }}>
                    <IconClose />
                </span>
            }
        </Modal.Header>
        <Modal.Body>
            {props.children && <>{props.children}</>}
        </Modal.Body>
    </Modal>
}

export default MessageModal