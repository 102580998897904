import React, { useState, useEffect } from "react";
import "../styles/goal.scss";
import "../styles/fx/pulsate.css";

import AddIcon from "../assets/svg/add.svg";
import EditIdleIcon from "@assets/svg/edit-idle.svg";
import EditHoverIcon from "@assets/svg/edit-hover.svg";

import { SAVE_GOAL_SUBQUESTION } from "../requests/api"
import { useDispatch, useSelector } from "react-redux";
import GTMHelper from "@helpers/GTMHelper.js";

const setGoalsBorder = (boolean) => {
  const goalsContainers = document.querySelectorAll(".goal.filled");

  if (boolean) goalsContainers.forEach((item) => (item.className = "goal filled dragDashed"));
  if (!boolean) goalsContainers.forEach((item) => (item.className = "goal filled pulsate-bck"));
};

const getParentNode = (node) => {
  if (node.dataset.index) return node;
  const parentNode = node.parentNode;
  return getParentNode(parentNode);
};

const handleSelectGoal = (dmcId, stepId, openModal, reduxDispatcher) => {
  //GET_GOAL_NEXT_STEP(dmcId, 17, reduxDispatcher)
  SAVE_GOAL_SUBQUESTION(dmcId, { isSubStep: true, stepId: stepId }, () => { }, reduxDispatcher)
  openModal(true);
};

const openModalOnTouch = (event, index, setOpenModal) => {
  let idle = event.target.closest(".edit-goal").querySelector(".idle")
  let hover = event.target.closest(".edit-goal").querySelector(".hover")

  if (idle)
    idle.style.display = "none"
  if (hover)
    hover.style.display = "block"

  setOpenModal(index)

  if (idle)
    idle.style.display = ""
  if (hover)
    hover.style.display = ""
}

const Goal = (props) => {
  const { index, id, selectString, priority, subject, time, state, heat, link, readOnly, alignment, swapGoals, openModal, setOpenModal } = props;
  const dmcID = useSelector(state => state.startDMC);
  const stepId = useSelector(state => state.questionnaireFlow.currentStep.stepId)
  const reduxDispatcher = useDispatch();
  const isShowingSummary = useSelector(state => state.summary.showSummary);

  const drag = {
    start: function (e) {
      let goalIndex = e.target.dataset.index;
      if (goalIndex === undefined) goalIndex = getParentNode(e.target).dataset.index;
      window.dragginGoal = goalIndex;
      setGoalsBorder(true);
      return false;
    },
    enter: function (e) {
      getParentNode(e.target).parentNode.className += " dragHover";
    },
    drop: function (e) {
      e.preventDefault();
      let goalIndex = e.target.dataset.index;

      if (goalIndex === undefined) goalIndex = getParentNode(e.target).dataset.index;
      const from = window.dragginGoal;
      const to = index;

      setGoalsBorder(false);
      swapGoals(from, to); // Swaps goals through parent useState - setGoals();
    },
    over: function (event) {
      event.preventDefault();
    },
    end: function () {
      setGoalsBorder(false);
    },
  };

  React.useEffect(() => {
    let domGoal = document.querySelector(`.goal.filled > div[data-index='${index}']`);

    if (domGoal) domGoal.addEventListener('touchmove', (e) => { e.preventDefault() }, { passive: false })
  }, [])

  return (
    <div className={
      subject && alignment === "column" ? "goal column filled pulsate-bck" :
        subject && alignment !== "column" ? "goal filled pulsate-bck" : "goal empty"}
      // tabIndex={selectString || subject ? 0 : undefined}     

      style={{ cursor: "pointer" }}
      tabIndex={subject || selectString ? 0 : undefined}
      onKeyDown={(event) => {
        if (event.key === "Enter")
          handleSelectGoal(dmcID, stepId, openModal, reduxDispatcher)
      }}
      onClick={() => { if (!subject && selectString) handleSelectGoal(dmcID, stepId, openModal, reduxDispatcher) }}
      role={!subject && selectString !== "" ? "button" : undefined}
      aria-label={subject ? `Goal ${subject}` : selectString ? selectString : undefined}
    >
      {subject && (
        <div
          className={alignment === "column" ? "row goal-column" : "row"}
          data-index={index}
          draggable={!readOnly ? "true" : "false"}
          //mouse drag events
          onDragEnter={!isShowingSummary ? (e) => drag.enter(e) : undefined}
          onDragEnd={!isShowingSummary ? () => drag.end() : undefined}
          onDrop={!isShowingSummary ? (e) => drag.drop(e) : undefined}
          onDragOver={!isShowingSummary ? (e) => drag.over(e) : undefined}
          onDragStart={!isShowingSummary ? (e) => drag.start(e) : undefined}
          //mobile drag events
          onTouchStart={!isShowingSummary ? (e) => {
            drag.start(e);
            drag.enter(e)
          } : undefined}
          onTouchEnd={!isShowingSummary ? (e) => {
            e.preventDefault();
            let endPosX = e.changedTouches[0].clientX;
            let endPosY = e.changedTouches[0].clientY;

            let targetEnd = document.elementFromPoint(endPosX, endPosY);
            let targetEndIndex = targetEnd?.dataset?.index;
            let toGoal = null;

            if (targetEnd) {
              if (targetEndIndex === undefined) {
                let goalEndTouch = targetEnd.closest(".goal.filled")

                if (goalEndTouch) {
                  toGoal = goalEndTouch.firstChild.dataset.index
                }
              }

              if (toGoal) {
                const from = window.dragginGoal;
                const to = parseInt(toGoal);

                setGoalsBorder(false);
                swapGoals(from, to);
              }
            }

            drag.over(e)
            drag.end();
          } : undefined}
        >
          <div className="my-auto priority-container">
            <div className="priority" aria-hidden={true}>{index + 1}</div>
          </div>
          <div className="goal-data">
            <div className="subject">{subject}</div>
            <div className="time">{time}</div>
            <div className="d-flex">
              <div
                className={
                  heat ?
                    heat.toLowerCase() === "green"
                      ? "state success"
                      : heat.toLowerCase() === "orange" || heat.toLowerCase() === "amber"
                        ? "state warning"
                        : "state danger" : ""
                }
              >
                {state}
              </div>
            </div>
            {link &&
              <div className="steps-to-take"
                onClick={() => {
                  GTMHelper.pushEvent("e_moneychat_view_goal_advice",
                    {
                      goal: `${subject} ${time}`,
                      // TODO: Get location dynamically
                      results_section: "your goals"
                    }
                  );
                }}
              >
                <a href={link.url} target={link.target ? link.target : ""}>
                  {link.title?.[0].value}
                </a>
              </div>
            }
            {!readOnly &&
              <div className="my-auto edit-goal" tabIndex={0} aria-label={"Edit"} role="button"
                onTouchEnd={(event) => openModalOnTouch(event, id, setOpenModal)}
                onClick={(event) => openModalOnTouch(event, id, setOpenModal)}
                onKeyDown={(e) => {
                  let goalSibling = e.target.closest(".goal").nextElementSibling;
                  if (e.key === "Enter") setOpenModal(id)
                  else if (e.key === "Tab" && goalSibling && !e.shiftKey) { setTimeout(() => goalSibling.focus()) }
                }}
              >
                <button className="idle" aria-hidden={true} disabled><img src={EditIdleIcon} /></button>
                <button className="edit" aria-hidden={true}><img src={EditHoverIcon} /></button>
              </div>
            }
          </div>
        </div>
      )}
      {!subject && (
        <div className="row">
          <div className="my-auto priority-container">
            <div className="priority">{index + 1}</div>
          </div>
          <div className="my-auto select-goal-container">
            <div className="select-container d-inline-block">
              {selectString !== "" && <img src={AddIcon} alt="" />}
              <div className="select-goal">
                {selectString}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Goal;
