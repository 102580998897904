/* EXAMPLE */
//const originalObject = {show: false, message: "default message", success: false};
//const updatedObject = {show: true};
//immutableNewObject(originalObject, updatedObject) // returns {show: true, message: "default message", success: false};

export function immutableNewObject(originalObject: object, updatedObject: object): object {
  let originalObjectCopy = { ...originalObject }; // Keep the original object structure
  for (const [key, value] of Object.entries(updatedObject)) {
    originalObjectCopy = { ...originalObjectCopy, [key]: value }; // Adding or modifying new key/value pair in each iteration, passed by updatedObject
  }
  return originalObjectCopy;
}
