// type of action param value: string, object, number?

import {
  SET_AUTHORIZATION,
  SET_RESPONDENTS,
  SET_RESPONDENTSDMCS,
  SET_CONTENTS,
  SET_REQUEST_LOADING,
  SET_IS_AUTHENTICATED,
  SET_IS_AUTHORIZED,
  SET_QUESTIONAIRE_FLOW,
  SET_NEW_STEP,
  SET_CURRENT_STEP,
  SET_ANSWER_STATE,
  SET_NEW_ANSWER,
  SET_NEW_ANSWER_PAIR,
  SET_ANSWER_PAIRS,
  START_NEW_DMC,
  SET_HAS_ALERT,
  SET_PROGRESS_MADE,
  ADD_NEW_GOAL,
  SET_GOALS_ARRAY,
  ADD_GOAL_SUBQUESTION,
  SET_GOAL_SUBQUESTIONS,
  SET_GOAL_ANSWERS,
  ADD_GOAL_SUBQUESTION_ANSWER,
  SET_IS_LAST_STEP,
  SET_SHOW_SUMMARY,
  SET_RESUMEDDMC,
  SET_STAFFRESUMEDDMC,
  SET_SHOW_HISTORY,
  SET_HISTORY_ASSESSMENTS,
  SET_SHOW_WELCOME,
  SET_SUMMARY_ALERT,
  SET_CONTEXTUAL_NOTE,
  SET_SUBSTEP_INFO,
  SET_ERROR_INFO,
  SET_CONTEXT,
  SET_LOADER_MESSAGE,
  SET_IS_QUESTIONNAIRE_RUNNING,
  SET_SESSION_GUID
} from "./../actions/actionTypes";

export const addAnswer = (value) => ({
  type: "ADD_ANSWER",
  value,
});

export const setAuthorization = (value) => ({
  type: SET_AUTHORIZATION,
  value,
});

export const setRespondents = (value) => ({
  type: SET_RESPONDENTS,
  value,
});

export const setRespondentsDmcs = (value) => ({
  type: SET_RESPONDENTSDMCS,
  value,
});

export const setContents = (value) => ({
  type: SET_CONTENTS,
  value,
});

export const setRequestLoading = (value) => ({
  type: SET_REQUEST_LOADING,
  value,
});

export const setIsAuthenticated = (value) => ({
  type: SET_IS_AUTHENTICATED,
  value,
});

export const setIsAuthorized = (value) => ({
  type: SET_IS_AUTHORIZED,
  value,
});

export const setQuestionnaireFlow = (value) => ({
  type: SET_QUESTIONAIRE_FLOW,
  value,
})

export const setNewStep = (value) => ({
  type: SET_NEW_STEP,
  value,
})

export const setCurrentStep = (value) => ({
  type: SET_CURRENT_STEP,
  value,
})

export const setAnswerState = (value) => ({
  type: SET_ANSWER_STATE,
  value,
})

export const setAnswerPairs = (value) => ({
  type: SET_ANSWER_PAIRS,
  value,
})

export const setNewAnswer = (value) => ({
  type: SET_NEW_ANSWER,
  value,
})

export const setNewAnswerPair = (value) => ({
  type: SET_NEW_ANSWER_PAIR,
  value,
})

export const setIsLastStep = (value) => ({
  type: SET_IS_LAST_STEP,
  value
})

export const startNewDmc = (value) => ({
  type: START_NEW_DMC,
  value,
})
// ALERT ACTION
export const setAlert = (value) => ({
  type: SET_HAS_ALERT,
  value,
});

export const setProgressMade = (value) => ({
  type: SET_PROGRESS_MADE,
  value,
})

//Goals related
export const addNewGoal = (value) => ({
  type: ADD_NEW_GOAL,
  value,
})

export const setSubstrepInfo = (value) => ({
  type: SET_SUBSTEP_INFO,
  value
})

export const setGoalsArray = (value) => ({
  type: SET_GOALS_ARRAY,
  value,
})

export const addGoalSubQuestion = (value) => ({
  type: ADD_GOAL_SUBQUESTION,
  value,
})

export const setGoalSubQuestions = (value) => ({
  type: SET_GOAL_SUBQUESTIONS,
  value,
})

export const setGoalAnswers = (value) => ({
  type: SET_GOAL_ANSWERS,
  value,
})

export const addGoalSubQuestionAnswer = (value) => ({
  type: ADD_GOAL_SUBQUESTION_ANSWER,
  value,
})

export const setShowSummary = (value) => ({
  type: SET_SHOW_SUMMARY,
  value
})

export const setSummaryAlert = (value) => ({
  type: SET_SUMMARY_ALERT,
  value
})

export const setResumedDmc = (value) => ({
  type: SET_RESUMEDDMC,
  value
})

export const setStaffResumedDmc = (value) => ({
  type: SET_STAFFRESUMEDDMC,
  value
})

export const setShowHistory = (value) => ({
  type: SET_SHOW_HISTORY,
  value
})

export const setHistoryAssessments = (value) => ({
  type: SET_HISTORY_ASSESSMENTS,
  value
})

export const setShowWelcome = (value) => ({
  type: SET_SHOW_WELCOME,
  value
})

export const setContextualNote = (value) => ({
  type: SET_CONTEXTUAL_NOTE,
  value
})

///// error messages \\\\\
export const setErrorInfo = (value) => ({
  type: SET_ERROR_INFO,
  value
})

export const setContext = (value) => ({
  type: SET_CONTEXT,
  value
})

export const setLoaderMessage = (value) => ({
  type: SET_LOADER_MESSAGE,
  value
})

export const setIsQuestionnaireRunning = (value) => ({
  type: SET_IS_QUESTIONNAIRE_RUNNING,
  value
})

// setting log session guid
export const setSessionGuid = (value) => ({
  type: SET_SESSION_GUID,
  value
})