import React, { useState } from 'react'

const TextInput = ({ id, titles, descriptions, writtenText, answerOptions, handleState }) => {
    const [answerText, setAnswerText] = useState();

    //helper functions
    function onTextChange(text) {
        setAnswerText(text);
        handleState('text', id, text);
    }

    function checkKeyPressed(event) {
        if (event.key === "Enter" || event.key === "Keys.ENTER") {
            event.preventDefault();
            document.activeElement.blur()
        }
    }

    return (
        <>
            {!descriptions && <div className="question-title">{titles[0].value}</div>}
            {descriptions &&
                <div>
                    <div className="question-title">{titles[0].value}</div>
                    <div className="question-subtitle">
                        {descriptions[0].value}
                    </div>
                </div>
            }
            <input
                type="text"
                placeholder="Type here"
                maxLength={255}
                onFocus={(event) => event.target.placeholder = ""}
                onBlur={(event) => event.target.placeholder = "Type Here"}
                value={writtenText && answerText == null ? writtenText : answerText}
                onChange={event => onTextChange(event.target.value)}
                onKeyDown={event => checkKeyPressed(event)}
                required
                aria-required="true"
            />
        </>
    )
}

export default TextInput;