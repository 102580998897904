import React, { FC } from "react";
import "./ToggleSwitch.scss";

interface IProps {
    onToggle?: (checked: boolean) => void;
    title?: string;
    initialChecked: boolean;
}


const ToggleSwitch: FC<IProps> = (props) => {
    const [Checked, setChecked] = React.useState(props.initialChecked);
    return (
        <div className="toggle-switch-container">
            {props.title && <p className="title">{props.title}</p>}
            <label className="switch">
                <input type="checkbox" checked={Checked} onChange={() => { setChecked(!Checked); props.onToggle && props.onToggle(!Checked); }} />
                <span className="slider round"></span>
            </label>
        </div>
    );
}

export default ToggleSwitch;