import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// React
import React, { Component } from "react";

import { render } from "react-dom";
import App from "./App";

import './styles/custom-bootstrap.scss'
import "./index.scss";

// REDUX STORE
import { Provider } from "react-redux";
import store from "./redux/store";
import log from "./helpers/logger";
const defaultSettings = { platform: null };

// // Inject dtrum script import
// var dtrum = document.getElementById("dtrum");
// if (dtrum === null || dtrum === undefined) {
//   const dtrumScript = document.createElement("script");
//   dtrumScript.src = "dt-rum.js";
//   dtrumScript.type = "text/javascript";
//   dtrumScript.async = true;
//   dtrumScript.id = "dtrum";
//   document.head.insertBefore(dtrumScript, document.head.firstElementChild);
// }

const isValidPlatform = (platform) => {
  if (platform === "dcu") return true;
  else if (platform === "salesforce") return true;
  else if (platform === "dcu_sim") return true;
  else if (platform === "dcu_mobile") return true;
  else return false;
};

const startDMC = (settings = defaultSettings) => {
  log.debug("DMC Widget initialized");
  log.debug(settings);

  if (!isValidPlatform(settings.platform)) {
    log.error("No platform found. You should select a platform first");
  } else {
    render(
      <Provider store={store}>
        <App settings={settings} />
      </Provider>,
      document.getElementById("dmcWidget")
    );
  }
};

//close IOS keyboard after click outside
document.addEventListener("touchend", (event) => {
  if (document.activeElement) {
    if (event.target.tagName.toLowerCase() !== "input" && event.target.tagName.toLowerCase() !== "select" && event.target.tagName.toLowerCase() !== "textarea") {
      document.activeElement.blur();
    }
  }
}, false)

window.startDMC = startDMC;