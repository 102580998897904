import React, { useEffect, useState } from "react";

import "../../styles/radioButton.scss";
import { useSelector } from "react-redux"

/*
@params for RadioButton

length {number} - quantity of radio buttons to generate
icon {string} - contains icon URL to display in the radio box button
label {string} - the label to display next to the radio button
*/
const types = {
  default: "RadioButtonListItem",
  withTextInput: "RadioButtonListWithFillItem",
  withIcon: "RadioButtonListWithIconItem",
  withRange: "RangeItem",
};
const style = {
  box: {
    margin: "5px",
    height: "100px",
    width: "100px",
    border: "2px solid #c3c3c3",
    borderRadius: "4px",
    padding: "1px",
    textAlign: "center",
    cursor: "pointer",
    label: {
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 200,
  },
};

const Input = ({ id, value, isChecked, ariaLabel }) => {
  return <input type="radio"
    id={id}
    value={value}
    style={style.box.label}
    checked={isChecked || null}
    tabIndex={-1}
    //aria-hidden="true"
    aria-label={ariaLabel}
    onChange={() => { }}
    onKeyDown={(event) => {
      if (event.key === "Enter" || event.key === " ") {
        if (event.target.nextElementSibling.querySelectorAll("input[type='text']").length > 0) {
          event.target.nextElementSibling.querySelector("input[type='text']").tabIndex = 0
        }
      }
    }}
    onFocus={(event) => {
      if (isChecked) {
        if (event.target.nextElementSibling.querySelectorAll("input[type='text']").length > 0) {
          event.target.nextElementSibling.querySelector("input[type='text']").tabIndex = 0
        }
      }

      if (document.querySelectorAll(".answer label input").length > 0) {
        let inputs = document.querySelectorAll(".answer label input");
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].tabIndex > -1)
            inputs[i].tabIndex = -1
        }
      }

      if (event.target.checked && event.target.nextElementSibling.querySelectorAll("input[type='text']").length > 0) {
        event.target.nextElementSibling.querySelector("input[type='text']").tabIndex = 0
      }
    }}
  />
};
const Label = ({ id, label, description, withTextInput, insertedText, onlyNumbers, maxCharactersOnInput, handelChange, onTextClick, unselect }) => {

  function checkKeyPressed(event) {
    if (event.key === "Enter" || event.key === "Keys.ENTER") {
      event.preventDefault();
      document.activeElement.blur()
    }
  }

  return <label htmlFor={id} style={style.box.label} aria-hidden={withTextInput ? "false" : "true"} tabIndex={-1}>
    <div aria-hidden="true">{label[0].value}</div>
    {withTextInput ? (
      <input
        id={id}
        tabIndex={-1}
        type="text"
        maxLength={maxCharactersOnInput ? maxCharactersOnInput : undefined}
        value={insertedText}
        className={description && onlyNumbers ? "only-numbers inside-text"
          : description && !onlyNumbers ? "inside-text"
            : !description && onlyNumbers ? "only-numbers" : ""}
        onClick={() => {
          if (onTextClick) onTextClick()
        }}
        onKeyDown={event => checkKeyPressed(event)}
        onChange={(event) => handelChange(event.target.value)}
        required
        aria-required="true"
        //Henrique development related to bug 637
        // required={!unselect}
        // aria-required={!unselect}
        // aria-hidden={unselect}
        //manage placeholder
        placeholder={!onlyNumbers ? "Type here" : undefined}
        onFocus={(event) => { if (!onlyNumbers) event.target.placeholder = "" }}
        onBlur={(event) => { if (!onlyNumbers) event.target.placeholder = "Type Here" }}
      />
    ) : (
        <></>
      )}
    {description ? description : ""}
  </label>
};

// Default Radio Button with only text
const Default = ({ rovTabIndex, label, value, id, selected, length }) => {
  let isChecked = selected && selected.selectedValue === value && selected.selectedId.toString() === id.toString()

  return (
    <div className="answer" tabIndex={rovTabIndex !== null && rovTabIndex !== undefined ? rovTabIndex : -1}
      onFocus={() => { setTimeout(() => document.getElementById(`${id.toString()}`)?.focus()) }}
    //aria-label={length ? `${value} ${id} of ${length} ${isChecked ? "checked" : "unchecked"}` : value}
    >
      <Input
        id={id}
        value={value}
        ariaLabel={length ? `${value}` : undefined}
        isChecked={isChecked}
      />
      <Label id={id} label={label} />
    </div>
  );
};

const WithTextInput = ({ rovTabIndex, label, description, value, id, selected, checked, setChecked, unselect, onlyNumber, setCompleted, handleState, length }) => {
  const [inputedValue, setInputedValue] = useState(value);
  const [refresh, setRefresh] = useState();
  let isChecked = unselect ? false : selected && selected.selectedId === id.toString() && !checked ? true : checked ? true : false

  useEffect(() => {
    if (selected && selected.selectedId === id.toString()) {
      setInputedValue(selected.selectedValue)
    }
    else {
      setInputedValue("")
    }
  }, [])

  useEffect(() => {
    if (unselect) setInputedValue("")
  }, [unselect])

  const selectRadioOnTextClick = () => {
    let options = document.querySelectorAll(".radioButtonList input[type='radio']");

    for (let i = 0; i < options.length; i++) {
      if (id.toString() !== options[i].id && options[i].checked === true) {
        options[i].checked = false;
        options[i].parentElement.parentElement.classList.remove("filled");
      }
      if (options[i].id.toString() === id.toString()) {
        //value = options[i].value;
        options[i].parentElement.parentElement.classList.add("filled");
      }
    }

    setChecked(true);
    if (inputedValue !== "") {
      if (setCompleted) setCompleted(true)
      handleState("radio with text input", id.toString(), inputedValue);
    }
    else {
      if (setCompleted) setCompleted(false)
      handleState("radio with text input", id.toString(), inputedValue);
    }
  }

  const setNewValue = (value) => {
    if (refresh) setRefresh(false)
    else setRefresh(true);

    if (value?.length <= 250) {
      if (onlyNumber) {
        setInputedValue(value.replace(/[^0-9]/g, ''));
      }
      else {
        setInputedValue(value);
      }
    }

    if (value !== "") {
      setChecked(true);
    }
    else {
      if (setCompleted) setCompleted(false)
    }
  };

  return (
    <div className="answer" tabIndex={rovTabIndex !== null && rovTabIndex !== undefined ? rovTabIndex : -1} onFocus={(event) => {
      if (event.target?.type !== "text") {
        setTimeout(() => {
          document.getElementById(`${id.toString()}`).focus();

          if (inputedValue !== "" && document.getElementById(`${id.toString()}`).checked)
            document.getElementById(`${id.toString()}`).nextElementSibling.querySelector("input[type='text']").tabIndex = 0
        })
      }
      else selectRadioOnTextClick()
    }}>
      <Input
        id={id}
        value={inputedValue}
        isChecked={isChecked}
        ariaLabel={label[0].value + " " + inputedValue}
      />
      <Label
        id={id}
        label={label}
        description={description}
        withTextInput="true"
        insertedText={inputedValue}
        onTextClick={selectRadioOnTextClick}
        handelChange={setNewValue}
        onlyNumbers={onlyNumber}
        maxCharactersOnInput={250}
        unselect={unselect}
      />
    </div>
  );
};

// Radio Button with text and icon
const WithIcon = ({ icon, id, label, altText, subAnswers }) => (
  <>
    <div className="radio-with-icon">
      <div className="icon">
        <img src={icon} alt={altText} />
      </div>
      <div className="selector my-auto">
        <Input id={id} value={id} />
        <Label id={id} label={label} />
      </div>
    </div>
  </>
);

//function to manage radio btns clicks and state
function manageRadioChecked(event, setItemChanged, setlifeStageItem, checked, handleState, setChecked, onSelect, setRadioSelected) {
  let options = document.querySelectorAll(".radioButtonList input[type='radio']");
  let value = "";
  let selectedID = "";

  if (event.target.type === "text") {
    setChecked(true)
    value = event.target.value
    selectedID = event.target.id;
  }
  if (event.target.nextElementSibling && event.target.nextElementSibling.querySelectorAll("input[type='text']").length === 0 && checked) {
    setChecked(false);
  }

  for (let i = 0; i < options.length; i++) {
    if (event.target != options[i] && options[i].checked === true) {
      setItemChanged(true)
      options[i].checked = false;
      options[i].parentElement.parentElement.classList.remove("filled");
    }

    if (event.target === options[i] && options[i].checked === true) {
      value = options[i].value;
      selectedID = options[i].id;
      options[i].parentElement.parentElement.classList.add("filled");

      if (options[i].parentElement.parentElement.className.indexOf("sub-answer") <= -1) {
        setlifeStageItem(selectedID);
      }
    }
  }

  if (handleState) {
    handleState("radio", selectedID, value);

    if (setRadioSelected)
      setRadioSelected(selectedID)

    if (onSelect)
      onSelect(selectedID);
  }
}

//function to mange radio btns on arrow key cliks
function manageFocusArrowClick(event, answerOptions) {
  let selectedRadio = document.querySelector("input:focus");

  if (answerOptions && answerOptions.length > 0) {
    if (event.key === "ArrowDown" || event.key === "ArrowUp" || event.key === "ArrowRight" || event.key === "ArrowLeft") {
      event.preventDefault()
      //control lifestage sub options radio buttons
      if (event.key === "ArrowDown" || event.key === "ArrowRight") {
        if (selectedRadio) {
          if (selectedRadio.type === "text") {
            if (selectedRadio.closest(".answer").nextElementSibling) setTimeout(() => selectedRadio.closest(".answer").nextElementSibling?.focus())
          }
          else {
            if (selectedRadio.nextElementSibling?.querySelectorAll("input[type='text']").length > 0 && selectedRadio.checked) {
              setTimeout(() => selectedRadio.nextElementSibling?.querySelector("input[type='text']")?.focus())
            }
            else if (selectedRadio.parentElement.nextElementSibling) {
              setTimeout(() => selectedRadio.parentElement.nextElementSibling?.focus())
            }
          }
        }
      }
      if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
        if (selectedRadio) {
          if (selectedRadio.type === "text") {
            if (selectedRadio.parentElement.previousSibling) setTimeout(() => selectedRadio.parentElement.previousSibling?.focus())
          }
          else {
            if (selectedRadio.parentElement.previousElementSibling) setTimeout(() => selectedRadio.parentElement.previousElementSibling?.focus())
          }
        }
      }
    }
  }
}

//function to focus the selected item
function focusSelectedItem() {
  let selectedRadio = document.querySelector(".questions-container > .radioButtonList input[type='radio']:checked")
  let focusedRadio = document.activeElement;

  if (selectedRadio === focusedRadio) {
    let radioButtonOptions = Array.prototype.slice.call(document.querySelectorAll(".questions-container > .radioButtonList input[type='radio']"))

    radioButtonOptions.forEach(radioOption => {
      if (radioOption === focusedRadio) {
        radioOption.tabIndex = 0
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
          radioOption.closest(".answer").tabIndex = 0
      }
      else {
        radioOption.tabIndex = -1
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
          radioOption.closest(".answer").tabIndex = -1
      }
    })
  }
}

const RadioButton = ({
  id,
  controlType,
  titles,
  description,
  answerOptions,
  handleState,
  selectedItem,
  descriptions,
  unselect,
  onlyNumber,
  onSelect,
  setCompleted
}) => {
  //{ length, icon, label }
  const [lifeStageItemSelected, setlifeStageItem] = useState(null);
  const [checked, setChecked] = useState(false);
  const [itemChanged, setItemChanged] = useState(false);
  const [radioSelected, setRadioSelected] = useState(null);
  const currentStepId = useSelector(state => state.questionnaireFlow.currentStep.stepId);

  useEffect(() => {
    let options = document.querySelectorAll(".radioButtonList input[type='radio']");
    let selected = document.querySelectorAll(".radioButtonList input[type='radio']:checked");

    if (selected.length > 0 && options.length > 0) {
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].checked === true && selectedItem && selectedItem.selectedId.toString() !== selected[i].id.toString()) {
          selected[i].checked = false;
          selected[i].parentElement.parentElement.classList.remove("filled");
        }
      }

      for (let radio = 0; radio < options.length; radio++) {
        if (selectedItem && options[radio].id === selected[0].id && options[radio].id === selectedItem.selectedId) {
          options[radio].focus();
        }
      }
    }
    if (itemChanged) {
      setItemChanged(false)
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      if (options?.length > 0) {
        for (let i = 0; i < options.length; i++) {
          options[i].closest(".answer").removeAttribute('tabIndex')
        }
      }
    }
  }, [answerOptions])

  useEffect(() => {
    focusSelectedItem()
  }, [radioSelected])

  return (
    <>
      {answerOptions && titles && titles.length > 0 &&
        <div id={`question-${currentStepId}`} className="question-title">{titles[0].value}</div>
      }
      {
        descriptions && descriptions.length > 0 &&
        <div className="question-subtitle">{descriptions[0].value}</div>
      }
      <div className="radioButtonList"
        role="radiogroup"
        aria-labelledby={`question-${currentStepId}`}
        style={controlType === 'IconRadioButtonList' ? style.row : {}}
        onFocus={() => focusSelectedItem(radioSelected)}
        onKeyDown={(event) => manageFocusArrowClick(event, answerOptions)}
        onChange={(event) => manageRadioChecked(event, setItemChanged, setlifeStageItem, checked, handleState, setChecked, onSelect, setRadioSelected)}
      >
        {/* if radio buttons doesnt have answer options*/}
        {answerOptions === null || answerOptions === undefined && controlType === types.default && (
          <Default
            label={titles}
            value={titles[0].value}
            id={id}
            key={id}
            selected={!itemChanged && selectedItem?.selectedId === id.toString() ? selectedItem : null}
          />
        )}
        {answerOptions === null || answerOptions === undefined && controlType === types.withTextInput && (
          <WithTextInput
            label={titles}
            description={description ? description[0].value : ""}
            value={titles[0].value}
            id={id}
            key={id}
            checked={checked}
            setChecked={setChecked}
            selected={!itemChanged && selectedItem?.selectedId === id.toString() ? selectedItem : null}
            unselect={unselect}
            onlyNumber={onlyNumber ? true : false}
            handleState={handleState}
            setCompleted={setCompleted}
          />
        )}

        {/* if radio buttons have answer options*/}
        {answerOptions?.map((answer, index) => {
          if (answer.controlItemType === types.withIcon) {
            return (
              <>
                <WithIcon
                  icon={answer.icon.imageUrl}
                  altText={answer.icon.altText[0].value}
                  label={answer.label}
                  value={answer.label[0].value}
                  id={answer.id}
                  key={answer.id}
                />
              </>
            );
          } else if (answer.controlItemType === "RadioButtonListWithFillItem") {
            return (
              <WithTextInput
                rovTabIndex={index === 0 ? 0 : -1}
                label={answer.label}
                description={answer.description}
                value={answer.label && answer.label.length > 0 && answer.label[0].value}
                id={answer.id}
                key={answer.id}
                checked={checked}
                setChecked={setChecked}
                selected={!itemChanged && selectedItem.selectedId === answer.id.toString() ? selectedItem : null}
                unselect={unselect}
                handleState={handleState}
                setCompleted={setCompleted}
                length={answerOptions.length}
              />
            );
          }
          else if (answer.controlItemType === "RadioButtonListItem") {
            return (
              <Default
                rovTabIndex={index === 0 ? 0 : -1}
                length={answerOptions.length}
                label={answer.label}
                value={answer.label && answer.label.length > 0 && answer.label[0].value}
                id={answer.id}
                key={answer.id}
                selected={!itemChanged && selectedItem.selectedId === answer.id.toString() ? selectedItem : null}
              />
            );
          }
        })}
      </div>
    </>
  );
};
export default RadioButton;
