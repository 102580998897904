import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLoaderMessage } from "../../redux/actions";
import log from "../../../src/helpers/logger";

const Loading = ({ loaderMessage }) => {
  const [showMessage, setShowMessage] = React.useState(false)

  const isQuestionnaireRunning = useSelector(state => state.loaderMessage.isQuestionnaireRunning)
  const reduxDispatcher = useDispatch();

  React.useEffect(() => {
    if (isQuestionnaireRunning) {
      log.debug("Response MaxSeconds: " + process.env.REACT_APP_RESPONSE_TIMER_MAXSECONDS);
      let maxTimeElapsed = process.env.REACT_APP_RESPONSE_TIMER_MAXSECONDS * 1000;

      //set default value if the config variable its undefined/null
      if (!maxTimeElapsed) maxTimeElapsed = 15000;

      setTimeout(() => {
        setShowMessage(true)
        reduxDispatcher(setLoaderMessage(true))
      }, maxTimeElapsed);
    }
  }, [])

  return (
    <div className="loadingContainer">
      {!showMessage && <div className="loader"></div>}
      {showMessage && <div className="loaderMessage">{loaderMessage}</div>}
    </div>
  );
};

export default Loading;
