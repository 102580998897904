/// Welcome Page Contents \\\
//header
export const WelcomePage_Header_Title: string = "welcome.header.title";
export const WelcomePage_Header_Desktop_ImgSrc: string = "welcome.header.desktop.imgSrc";
export const WelcomePage_Header_Desktop_ImgAltText: string = "welcome.header.desktop.imgAlt";
export const WelcomePage_Header_Mobile_ImgSrc: string = "welcome.header.mobile.imgSrc";
export const WelcomePage_Header_Mobile_ImgAltText: string = "welcome.header.mobile.imgAlt"

//page content
export const WelcomePage_Title: string = "welcome.body.title";
export const WelcomePage_SubTitle: string = "welcome.body.subtitle";

//highlight
export const Highlight1_Icon: string = "welcome.highlight1.icon";
export const Highlight1_Text: string = "welcome.highlight1.text";
export const Highlight2_Icon: string = "welcome.highlight2.icon";
export const Highlight2_Text: string = "welcome.highlight2.text";
export const Highlight3_Icon: string = "welcome.highlight3.icon";
export const Highlight3_Text: string = "welcome.highlight3.text";

//button
export const WelcomePage_Button_LetsBegin: string = "welcome.button.letsbegin";

//carousel
export const WelcomePage_Carousel_Title: string = "welcome.carousel.title";
export const WelcomePage_Carousel_Quote1: string = "welcome.carousel.quote1";
export const WelcomePage_Carousel_Quote2: string = "welcome.carousel.quote2";
export const WelcomePage_Carousel_Quote3: string = "welcome.carousel.quote3";

/// Resume Page contents \\\
//header
export const ResumePage_Header_Title: string = "resume.header.title";

//button
export const ResumePage_Button_ContinueAssessment: string = "resume.button.continueAssessment";

//link
export const ResumePage_Link_StartAssessment: string = "resume.link.startNewAssessment";

//carousel
export const ResumePage_Carousel_Title: string = "resume.carousel.title";

/// History Page \\\
//Header Title
export const HistoryPage_Header_Title: string = "history.header.title";

//Assessments Title
export const HistoryPage_RecentAssessment_Title: string = "history.mostRecentAssessment.title";
export const HistoryPage_Link: string = "history.link.seeResults";
export const HistoryPage_PastAssessments_Title: string = "history.pastAssessment.title"

//Modal
export const HistoryPage_DeleteModal_Title: string = "history.delete.modal.title";
export const HistoryPage_DeleteModal_SubTitle: string = "history.delete.modal.subtitle";
export const HistoryPage_DeleteModal_QuestionDescription: string = "history.delete.modal.questionDescription"
export const HistoryPage_DeleteModal_ConfirmDescription: string = "history.delete.modal.confirmDescription";
export const HistoryPage_DeleteModal_CancelDescription: string = "history.delete.modal.cancelDescription";
export const HistoryPage_DeleteModal_IconIdle: string = "history.delete.idleIcon";
export const HistoryPage_DeleteModal_IconHover: string = "history.delete.hoverIcon";