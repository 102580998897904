import { SET_RESPONDENTSDMCS } from "../actions/actionTypes";

function respondentsDmcs(state = '-1', action) {
  switch (action.type) {
    case SET_RESPONDENTSDMCS:
      return { ...action.value };
    default:
      return state;
  }
}

export default respondentsDmcs;
