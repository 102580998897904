import {
  setIsAuthenticated,
  setRequestLoading,
  setIsAuthorized,
  setErrorInfo
} from "../redux/actions";
import { RESPONDENTS, RESPONDENT_DMCS } from "../requests/api";
import { setRespondents } from "../redux/actions";
import axios from "axios";
import qs from "qs";
import { decodeJWT } from "../helpers/decodeJWT";
import log from "../helpers/logger";

// Destructured environment variables
const {
  REACT_APP_DOMAIN,
  REACT_APP_REALM,
  REACT_APP_CLIENT_ID,
  REACT_APP_SCOPE,
  REACT_APP_REDIRECT_URI,
  REACT_APP_OAUTH_URI,
  REACT_APP_RESPONSE_TYPE,
  REACT_APP_NONCE,
  REACT_APP_CODE_CHALLENGE_METHOD,
  REACT_APP_RESPONDENT_GUID_AVAILABLE
} = process.env;

///////////////////// AUTHORIZE / CALLBACK \\\\\\\\\\\\\\\\\\\\\\\\\\
const AUTHORIZE = (code_challenge, state, reduxDispatcher) => {
  log.debug("SIMEIO - INIT Authorize");
  try {

    const endpoint = REACT_APP_DOMAIN + REACT_APP_OAUTH_URI + "authorize";
    const response_type = `?response_type=${REACT_APP_RESPONSE_TYPE}`;
    const scope = `&scope=${REACT_APP_SCOPE}`;
    const client_id = `&client_id=${REACT_APP_CLIENT_ID}`;
    const redirect_uri = `&redirect_uri=${REACT_APP_REDIRECT_URI}`;
    const s_state = `&state=${state}`;
    const c_challenge = `&code_challenge=${code_challenge}`;
    const nonce = `&nonce=${REACT_APP_NONCE}`;
    const code_challenge_method = `&code_challenge_method=${REACT_APP_CODE_CHALLENGE_METHOD}`;

    const URL =
      endpoint +
      response_type +
      scope +
      client_id +
      redirect_uri +
      s_state +
      c_challenge +
      nonce +
      code_challenge_method;

    let iframe = document.createElement("iframe");
    try {
      var decodedURL = decodeURI(URL)
      iframe.src = decodedURL;
    } catch (e) {
      console.log("Malformed Url ", e)
    }
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "0";
    iframe.style.border = "none";
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    window.authFrame = iframe;
    log.debug("SIMEIO - Waiting for Authorize response - " + URL);
    window.authFrame.onload = () => {
      log.debug("SIMEIO - Authorize Loaded");
      var url = window.authFrame.contentWindow.location.search;
      const urlParams = new URLSearchParams(url);
      const code_queryString = urlParams.get("code");
      const error_queryString = urlParams.get("error");

      if (!code_queryString || error_queryString) {
        log.error("SIMEIO - Authorize Error", error_queryString);
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "fullPage",
          errorTitle: "Something went wrong.",
          errorDescription: "It's not you, it's us. Please try again.",
          errorButton: null
        }));
        return;
      }
      log.debug("SIMEIO - END Authorize - Calling TOKEN...");
      TOKEN(code_queryString, sessionStorage.getItem("CODE_VERIFIER"), reduxDispatcher);
    };

  } catch (e) {
    log.error("SIMEIO - Authorize Method Exception", e);
    reduxDispatcher(setErrorInfo({
      show: true,
      type: "fullPage",
      errorTitle: "Something went wrong.",
      errorDescription: "It's not you, it's us. Please try again.",
      errorButton: null
    }));
  }
};

////////////////////// POST TO AUTHENTICATE AND SET COOKIE \\\\\\\\\\\\\\\\\\\\\\\\\\\
export const AUTHENTICATE = (reduxDispatcher) => {
  log.debug("SIMEIO - INIT AUTHENTICATE");
  try {
    reduxDispatcher(setRequestLoading(true)); // Displays loader - loading

    const myHeaders = new Headers();
    myHeaders.append("Accept-API-Version", "resource=2.1");
    myHeaders.append("Accept", "*/*");
    myHeaders.append("Content-Length", "0");
    myHeaders.append("Accept-Encoding", "gzip, deflate, br");
    myHeaders.append("Connection", "keep-alive");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      credentials: "include",
    };

    const endpoint = `${REACT_APP_DOMAIN}${REACT_APP_REALM}authenticate?authIndexType=service&authIndexValue=CC-DMCLogin`;
    log.debug("SIMEIO - AUTHENTICATE Waiting...");
    axios
      .post(
        endpoint,
        {},
        {
          headers: {
            "Accept-API-Version": "resource=2.1"
          },
          withCredentials: true
        }
      )
      .then((response) => {
        // Success
        log.debug("SIMEIO - AUTHENTICATE - Success");
        sessionStorage.setItem("AUTHENTICATE_TOKEN", response.data.tokenId);
        AUTHORIZE(sessionStorage.getItem("CODE_CHALLENGE"), sessionStorage.getItem("STATE"), reduxDispatcher);
      })
      // Failure
      .catch((error) => {
        log.error("SIMEIO - AUTHENTICATE Method Error", error);
        reduxDispatcher(setRequestLoading(false));
        reduxDispatcher(setIsAuthenticated(false));
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "fullPage",
          errorTitle: "Something went wrong.",
          errorDescription: "It's not you, it's us. Please try again.",
          errorButton: null
        }));
      })
      .finally(() => reduxDispatcher(setRequestLoading(false)));
  } catch (e) {
    log.error("SIMEIO - AUTHENTICATE Method Exception", e);
    reduxDispatcher(setErrorInfo({
      show: true,
      type: "fullPage",
      errorTitle: "Something went wrong.",
      errorDescription: "It's not you, it's us. Please try again.",
      errorButton: null
    }));
  }
};

////////////////////// POST REQUEST TO OBTAIN TOKEN \\\\\\\\\\\\\\\\\\\\\\\\\\\
export const TOKEN = (URL_CODE, CODE_VERIFIER, reduxDispatcher) => {
  log.debug("SIMEIO - INIT TOKEN");
  try {


    reduxDispatcher(setRequestLoading(true)); // Displays loader - loading
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Connection", "keep-alive");
    const endpoint = `${REACT_APP_DOMAIN}${REACT_APP_OAUTH_URI}access_token`;
    const data = {
      client_id: REACT_APP_CLIENT_ID,
      grant_type: "authorization_code",
      code: URL_CODE,
      redirect_uri: REACT_APP_REDIRECT_URI,
      code_verifier: CODE_VERIFIER, // optional, required for PKCE
    };
    const options = {
      headers: { "content-type": "application/x-www-form-urlencoded" },
      withCredentials: true,
    };
    log.debug("SIMEIO - TOKEN Waiting.....");
    axios
      .post(endpoint, qs.stringify(data), options)
      .then((response) => {
        log.debug("SIMEIO - Token Success");

        sessionStorage.setItem("ID_TOKEN", response.data.id_token);
        sessionStorage.setItem("ACCESS_TOKEN", response.data.access_token);
        const decodedToken = decodeJWT(response.data.id_token);
        var t24profileId = decodedToken.t24profileid;

        // The Respondent GUID        
        var dmcRespondentGuid = decodedToken.dmc_respondent_guid;

        var givenName = decodedToken.given_name;
        var familyName = decodedToken.family_name;
        const tokenDataToObject = { t24profileId, givenName, familyName, dmcRespondentGuid };
        sessionStorage.setItem("customerData", JSON.stringify(tokenDataToObject));
        reduxDispatcher(setIsAuthenticated(true));
        reduxDispatcher(setIsAuthorized(true));

        // Only set the RespondetGUID if the env var is true
        if (REACT_APP_RESPONDENT_GUID_AVAILABLE == "true") {
          // Set respondents data property 
          /// «the only property used to retrieve the respondentId»
          reduxDispatcher(setRespondents({ data: dmcRespondentGuid }));

          // Set the Respondent GUID to redux
          RESPONDENT_DMCS(dmcRespondentGuid, reduxDispatcher);

        } else {
          RESPONDENTS(t24profileId, reduxDispatcher);
        }

      })
      .catch((error) => {
        log.error("SIMEIO - Token - Failed to get token with: ", error);
        reduxDispatcher(setRequestLoading(false));
        reduxDispatcher(setIsAuthorized(false));
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "fullPage",
          errorTitle: "Something went wrong.",
          errorDescription: "It's not you, it's us. Please try again.",
          errorButton: null
        }));
      })
      .finally(() => reduxDispatcher(setRequestLoading(false)));
  } catch (e) {
    log.error("SIMEIO - TOKEN Method Exception", e);
    reduxDispatcher(setErrorInfo({
      show: true,
      type: "fullPage",
      errorTitle: "Something went wrong.",
      errorDescription: "It's not you, it's us. Please try again.",
      errorButton: null
    }));
  }
};

////////////////////// POST REQUEST TO LOGOUT \\\\\\\\\\\\\\\\\\\\\\\\\\\
/**
 * @param {boolean} toAuthenticate - Boolean to indicate if this a forced logout to REauthenticate user
 * @param {function} reduxDispatcher - As second parameter/callback we send the reduxDispatcher to save/set data do redux store
 *  */
export const LOGOUT = (toAuthenticate = false, reduxDispatcher) => {
  try {
    reduxDispatcher(setRequestLoading(true)); // Displays loader - loading
    log.debug("SIMEIO - INIT LOGOUT");
    const myHeaders = new Headers();
    myHeaders.append("Accept-API-Version", "resource=3.1");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      credentials: "include"
    };

    const endpoint = `${REACT_APP_DOMAIN}${REACT_APP_REALM}sessions/?_action=logout`;
    log.debug("SIMEIO - LOGOUT waiting...");
    fetch(endpoint, requestOptions)
      .then((response) => {
        log.debug("SIMEIO - LOGOUT ended successfully");
        AUTHENTICATE(reduxDispatcher);
      })
      .catch((error) => {
        log.error("SIMEIO - Token - Failed to get token with: ", error);
        reduxDispatcher(setRequestLoading(false));
        reduxDispatcher(setIsAuthorized(false));
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "fullPage",
          errorTitle: "Something went wrong.",
          errorDescription: "It's not you, it's us. Please try again.",
          errorButton: null
        }));
      });
  } catch (e) {
    log.error("SIMEIO - LOGOUT Method Exception", e);
    reduxDispatcher(setErrorInfo({
      show: true,
      type: "fullPage",
      errorTitle: "Something went wrong.",
      errorDescription: "It's not you, it's us. Please try again.",
      errorButton: null
    }));
  }
};

