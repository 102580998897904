function questionnaireFlow(state = { steps: [], currentStep: [] }, action) {
  switch (action.type) {
    case "QUESTIONNAIRE_FLOW":
      return action.value;
    case "SET_NEW_STEP":
      return { steps: [...state.steps, action.value], currentStep: action.value };
    case "SET_QUESTIONAIRE_FLOW":
      return { steps: action.value, currentStep: state.currentStep };
    case "SET_CURRENT_STEP":
      return { steps: [...state.steps], currentStep: action.value };
    case "SET_CONTEXTUAL_NOTE":
      let steps = [...state.steps];
      steps[state.steps.length - 1].note = action.value;
      return {
        steps: steps,
        currentStep: steps[state.steps.length - 1]
      };
    default:
      return state;
  }
}

export default questionnaireFlow;
