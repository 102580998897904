import React, { FC } from "react";
import "./Footer.scss";

interface IProps {
    text?: string;
}

const Footer: FC<IProps> = (props) => {
    return <>{props.text && <div className="footer-container">{props.text}</div>}</>;
}
export default Footer;