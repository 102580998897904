import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SAVE_QUESTION_TO_DB, GET_QUESTIONNAIRE_PREVIOUS_STEP, FINISH_QUESTIONNAIRE, SAVE_QUESTION_TO_DB__v2 } from "../requests/api";
import { setQuestionnaireFlow, setCurrentStep, setAnswerState, setIsLastStep, setGoalsArray } from "../redux/actions";

interface Props {
  type?: string;
  icon?: string;
  text: string;
  fill?: boolean;
  disabled?: boolean;
  nextPage?: any;
  handleStepState?: any;
  costumFunction?: any;
  isLastStep?: boolean;
  contextualNote?: string;
}

const CustomButton: React.FC<Props> = ({
  type,
  icon,
  text,
  fill,
  disabled,
  nextPage,
  handleStepState,
  costumFunction,
  isLastStep,
  contextualNote
}) => {
  //styles
  const settings = {
    isFilled: fill === undefined ? false : fill,
    isDisabled: disabled || false,
    text: text || "",
    icon: icon || null,
  };

  const filledButton = {
    background: "#0067c6",
    color: "white",
    padding: "8px",
    border: "1px solid #0067c6",
    borderRadius: "5px",
    fontSize: "16px",
  };
  const unFilledButton = {
    background: "white",
    color: "#0067c6",
    padding: "8px",
    border: "1px solid #0067c6",
    borderRadius: "5px",
    fontSize: "16px",
  };
  const disabledButton = {
    background: "#E5E5E5",
    color: "#878787",
    padding: "8px",
    border: "1px solid #E5E5E5",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const reduxDispatcher = useDispatch();
  const dmcId = useSelector<any, any>((state) => state.startDMC);
  const currentStep = useSelector<any, any>((state) => state.questionnaireFlow.currentStep);
  const answers = useSelector<any, any>((state) => state.questionnaireWording.answers);
  const answerPairs = useSelector<any, any>((state) => state.questionnaireWording.answersPairs);
  const stepsTaken = useSelector<any, any>((state) => state.questionnaireFlow.steps);
  const isQuestionnaireRunning = useSelector<any, any>((state) => state.loaderMessage.isQuestionnaireRunning)

  //inserted goals
  const insertedGoals = useSelector<any, any>((state) => state.goals.currentGoals);

  //funtions
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isLastStep) {
      Finish(e);
    }
    else {
      type === "submit" ? GoToNextPage(e) : nextPage == null ? GoToPreviousPage() : costumFunction();
    }
  };

  const GoToPreviousPage = () => {
    console.log("Going to previous page...");

    if (currentStep.controlType === "GoalSelector") {
      if (insertedGoals && insertedGoals.length > 0) {
        reduxDispatcher(setGoalsArray([]))
      }
    }

    // Reset isLastStep state when back clicked
    reduxDispatcher(setIsLastStep(false));

    let answerId = null;
    let stepId = null;

    answerPairs?.map((answerPair: any) => {
      if (answerPair.stepId === currentStep.stepId) {
        answerId = answerPair.answerId;
        stepId = answerPair.stepId;
        return;
      }
    })

    if (stepsTaken && stepsTaken.length > 0) {
      //remove last step taken
      stepsTaken.splice(stepsTaken.length - 1, 1);
      reduxDispatcher(setQuestionnaireFlow(stepsTaken))

      //remove answer given to the current step
      if (answers.length > 0 && answers[answers.length - 1].stepId == currentStep.stepId) {
        answers.splice(answers.length - 1, 1);
        reduxDispatcher(setAnswerState(answers));
      }

      //set new current step
      reduxDispatcher(setCurrentStep(stepsTaken[stepsTaken.length - 1]))
    }

    //if step has answers saved    
    if (answerId !== null && stepId !== null) {
      GET_QUESTIONNAIRE_PREVIOUS_STEP(dmcId, stepId, answerId, () => { }, reduxDispatcher);
    }

    // Reset state if back button
    handleStepState();
  };

  const GoToNextPage = (event: any) => {
    event.preventDefault();

    let stepType = currentStep.stepType.toLowerCase();
    if (stepType === "info" || stepType === "feedback") {
      SAVE_QUESTION_TO_DB(dmcId, currentStep, isQuestionnaireRunning, () => { }, reduxDispatcher);
    } else {
      let answer: any = [];
      answers?.map((ans: any) => {
        if (ans.stepId === currentStep.stepId) {
          answer = ans;
        }
      });

      // Set answer note
      answer.note = contextualNote;

      SAVE_QUESTION_TO_DB(dmcId, answer, isQuestionnaireRunning, handleStepState, reduxDispatcher);
    }
  };

  const Finish = (event: any) => {
    event.preventDefault();

    let stepType = currentStep.stepType.toLowerCase();
    if (stepType === "info" || stepType === "feedback") {
      SAVE_QUESTION_TO_DB__v2(dmcId, currentStep, isQuestionnaireRunning, reduxDispatcher)
        .then(res => {
          FINISH_QUESTIONNAIRE(dmcId, handleStepState, reduxDispatcher);
        })
        .catch(err => console.error("Could not call finish"));
    } else {
      let answer: any = [];
      answers?.map((ans: any) => {
        if (ans.stepId === currentStep.stepId) {
          answer = ans;
        }
      });

      // Set answer note
      answer.note = contextualNote;

      SAVE_QUESTION_TO_DB__v2(dmcId, answer, isQuestionnaireRunning, reduxDispatcher)
        .then(res => {
          FINISH_QUESTIONNAIRE(dmcId, handleStepState, reduxDispatcher);
        })
        .catch(err => console.error("Could not call finish"));
    }
  };

  return (
    <button
      tabIndex={0}
      type={type ? "submit" : "reset"}
      id={type ? "submit-step" : ""}
      onClick={(e) => handleClick(e)}
      style={
        settings.isDisabled ? disabledButton : settings.isFilled ? filledButton : unFilledButton
      }
      className={fill ? "filled" : ""}
      disabled={settings.isDisabled}
    >
      {settings.icon &&
        <span role="img" aria-label="emoji">
          {settings.icon} &nbsp;
        </span>
      }
      {settings.text}
    </button>
  );
};

export default CustomButton;
