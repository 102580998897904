import * as React from 'react';
import { Modal } from 'react-bootstrap';
import "@styles/confirmModal.scss";

interface IProps {
    show?: boolean,
    title: string,
    closeModal?: (boolean) => void,
}

/**
 * 
 * @param {*} children
 * @param {*} show
 * @param {*} title
 * 
 * @example
 * 
 * <Modal show={state}>
 *     <Modal.Header>title</Modal.Header>
 *     <Modal.Body>children</Modal.Body>
 * </Modal>
 */

const ConfirmModal: React.FC<IProps> = (props) => {
    return <Modal
        show={props.show}
        className="confirm-modal"
        dialogClassName="confirm-modal-dialog"
        onHide={() => { if (props.closeModal) { props.closeModal(false) } }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        container={document.getElementById("dmcWidget")}
        centered>
        {/* <Modal.Header>
            {props.title}
            <a onClick={() => { if (props.closeModal) props.closeModal() }}>X</a>
        </Modal.Header> */}
        <Modal.Body>
            {props.children && <>{props.children}</>}
        </Modal.Body>
    </Modal>
}

export default ConfirmModal