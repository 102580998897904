import * as React from 'react';
import "./ExportAssessment.scss";
import { ReactComponent as IconSuccess } from "@assets/svg/success.svg";
import { ReactComponent as ErrorIcon } from "@assets/svg/error_icon.svg";
import { ReactComponent as IconAt } from "@assets/svg/at.svg";
import { useSelector } from 'react-redux';
import { EXPORT_ASSESSMENT } from 'requests/api';
import Loading from '@components/Generic/LocalLoading/LocalLoading';
import GTMHelper from '@helpers/GTMHelper.js';

interface IProps { }

const exportResults = (dmcId: string, email: string, setSuccess: any, setError: any, setShowLoading: any) => {
    setShowLoading(true);
    GTMHelper.pushEvent("e_moneychat_export", {
        export_format: "email"
    });
    EXPORT_ASSESSMENT(dmcId, email).then(() => {
        setSuccess(true);
    }).catch(() => {
        setSuccess(false);
        setError(true);
    }).finally(() => {
        setShowLoading(false);
    });
}

const ExportAssessment: React.FC<IProps> = (props) => {
    const [ShowInput, setShowInput] = React.useState(false);
    const [Email, setEmail] = React.useState("");
    const [Success, setSuccess] = React.useState(false);
    const [Error, setError] = React.useState(false);
    const [ShowLoading, setShowLoading] = React.useState(false);

    // @ts-ignore
    const dmcId = useSelector(state => state.startDMC);
    return (
        <div className="export-assessment">
            {ShowLoading ? <Loading /> :
                <>
                    {!ShowInput &&
                        <div className="export-assessment-icon"
                            onClick={() => setShowInput(true)}>
                            <IconAt />
                            <span className="send-txt">Send to email</span>
                        </div>
                    }
                    {ShowInput && !Success && !Error &&
                        <div className="send-container">
                            <div className="export-assessment-send">
                                <span className="send-txt">Send to</span>
                                <input className="email" value={Email} onChange={(event) => setEmail(event.target.value)}></input>
                                <button className="send-btn" onClick={(e) => { e.preventDefault(); exportResults(dmcId, Email, setSuccess, setError, setShowLoading); }}>Send</button>
                            </div>
                        </div>
                    }

                    {(Success || Error) &&
                        < div className="result-message">
                            {Error ? <ErrorIcon /> : <IconSuccess />}
                            {!Error && <strong style={{ marginBottom: 10 }}>Assessment sent successfully</strong>}
                            {Error && <strong style={{ marginBottom: 10 }}>Sorry, there was an issue exporting your results. Please try again or contact an advisor for support at 1-888-517-7000.</strong>}
                            {!Error && <p>Exported results sent to {Email}</p>}
                        </div>
                    }
                </>
            }
        </div >);
}

export default ExportAssessment;