import rootReducer from "./reducers";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

// If the environment is not in development, Redux DevTools will not be shown
const reduxWithDevTools = () => {
  if (process.env.NODE_ENV !== "development") {
    return createStore(rootReducer);
  } else {
    return createStore(rootReducer, composeWithDevTools());
  }
};

const store = reduxWithDevTools();

export default store;
