import { SET_HAS_ALERT } from "../actions/actionTypes";
import { immutableNewObject } from "../../helpers/immutable";

const defaultState = {
  show: false,
  success: true,
  message: "",
  buttonMessage: "",
  showButton: false
};

function alert(state = defaultState, action) {
  switch (action.type) {
    case SET_HAS_ALERT:
      return immutableNewObject(state, action.value);
    default:
      return state;
  }
}

export default alert;
