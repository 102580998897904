import React from "react";
import Input from "./Input";
import Label from "./Label";

import "../../styles/card.scss";

const style = {
  border: "2px solid grey",
  display: "flex",
  width: "120px",
  height: "120px",
  alignItems: "center",
  alignContent: "center",
  flexDirection: "column",
  margin: "4px",
};

/**
 * Card is just a div wrapper that displays a border around content.
 * @param {*} children - Pass any JSX content
 *
 * @example
 *
 * <Card>
 *    <h1>
 *      Any content.
 *    </h1>
 * </Card>
 */
const Card = ({ filled, children }) => {
  return <div className={filled ? "card filled" : "card"} tabIndex={0}>{children}</div>;
};

export default Card;
