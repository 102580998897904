import React, { useState, useEffect } from "react";
import GoalsDragDropContainer from "./GoalsDragDropContainer";
//import { useDispatch, useSelector } from "react-redux";

/**
 *
 * @param {object} currentStep - Object that contains data associated with the current step
 * @param {function} function - function that handles the state
 * @param {*} - null or empty object {}
 * @param {function} setNextButtonToEnabled - Function to enable next button
 */
const Goals = (props) => {
  const { currentStep, saveAnswerToStore, selectedGoals, resetState, setNextButtonToEnabled } = props;

  // useEffect(() => {
  //   setNextButtonToEnabled(true);
  //   saveAnswerToStore(String(currentStep.stepId), "3", "");
  // }, []);

  return (
    <>
      <div className="question-title">{currentStep.titles[0].value}</div>
      <p className="small-title">{currentStep.descriptions[0].value}</p>
      <GoalsDragDropContainer storeGoals={saveAnswerToStore} selectedGoals={selectedGoals} resetState={resetState} />
    </>
  );
};

export default Goals;
