import React, { useEffect } from "react";

import Header from "./components/Header";
import Highlight from "./components/Highlight";
import Carousel from "./components/Carousel";
import CostumButton from "./components/CustomButton";
import { CONTENTS } from "./requests/api";

import GTMHelper from "@helpers/GTMHelper.js";
import compass from "./assets/svg/compass.svg";
import financial from "./assets/svg/financial.svg";
import yourGoals from "./assets/svg/your-goals.svg";
import topOfMind from "./assets/svg/top-of-mind.svg";
import calculator from "./assets/svg/calculator.svg";
import thumbsUP from "./assets/svg/thumbs-up.svg";
import thumbsDown from "./assets/svg/thumbs-down.svg";

import desktopHeader from "./assets/svg/header_desktop_01.jpg";
import mobileHeader from "./assets/svg/mobile-header.png";
import desktopHeader02 from "./assets/svg/header_desktop_02.jpg";
import mobileHeader02 from "./assets/svg/mobile_header_02.png";
import desktopHeader03 from "./assets/svg/LifeStage/header_desktop_03.jpg";
import mobileHeader03 from "./assets/svg/LifeStage/mobile_header_03.png";

import desktopHeader04 from "./assets/svg/header_desktop_03.jpg";
import mobileHeader04 from "./assets/svg/mobile_header_03.png";
import desktopHeader05 from "./assets/svg/header_desktop_04.jpg";
import mobileHeader05 from "./assets/svg/mobile_header_04.png";
import desktopHeader06 from "./assets/svg/header_desktop_05.jpg";
import mobileHeader06 from "./assets/svg/mobile_header_05.png";
import desktopHeaderResults from "./assets/svg/header_desktop_results.jpg";
import mobileHeaderResults from "./assets/svg/mobile_header_results.png";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep, setQuestionnaireFlow, setAnswerState, setAnswerPairs, setIsLastStep, setGoalsArray } from "./redux/actions";

import * as contentsLib from "./types/ContentTypes";

let header_title = "";
let header_desktop_img = "";
let header_desktop_alttext = "";
let header_mobile_img = "";
let header_mobile_alttext = "";

let title = "";
let subtitle = "";

let highlight1_icon = "";
let highlight1_title = "";
let highlight2_icon = "";
let highlight2_title = "";
let highlight3_icon = "";
let highlight3_title = "";

let letsbegin = "";

let carousel_title = "";
let carousel_quote1 = "";
let carousel_quote2 = "";
let carousel_quote3 = "";

let carousel_quotes = [];

const WelcomePage = ({ handleState }) => {
  const reduxDispatcher = useDispatch();
  const welcomeContents = useSelector((state) => state.contents.data);
  const isRequestLoading = useSelector((state) => state.requestLoading);
  const contents = useSelector((state) => state.contents);

  if (welcomeContents != null) {
    for (let i = 0; i < welcomeContents.length; i++) {
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Header_Title) {
        header_title = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Header_Desktop_ImgSrc) {
        header_desktop_img = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Header_Desktop_ImgAltText) {
        header_desktop_alttext = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Header_Mobile_ImgSrc) {
        header_mobile_img = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Header_Mobile_ImgAltText) {
        header_mobile_alttext = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Title) {
        title = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_SubTitle) {
        subtitle = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.Highlight1_Icon) {
        highlight1_icon = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.Highlight1_Text) {
        highlight1_title = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.Highlight2_Icon) {
        highlight2_icon = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.Highlight2_Text) {
        highlight2_title = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.Highlight3_Icon) {
        highlight3_icon = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.Highlight3_Text) {
        highlight3_title = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Button_LetsBegin) {
        letsbegin = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Carousel_Title) {
        carousel_title = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Carousel_Quote1) {
        carousel_quote1 = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Carousel_Quote2) {
        carousel_quote2 = welcomeContents[i].contentVersion.resources[0].value;
      }
      if (welcomeContents[i].contentId === contentsLib.WelcomePage_Carousel_Quote3) {
        carousel_quote3 = welcomeContents[i].contentVersion.resources[0].value;
      }

      //check for quotes in content response
      if (welcomeContents[i].contentId.indexOf("quote") > -1) {
        if (carousel_quotes.length <= process.env.REACT_APP_MAX_QUOTES) {
          carousel_quotes.push({ value: welcomeContents[i].contentVersion.resources[0].value })
        }
      }
    }
  }

  let schemaHeader = {
    title: [
      {
        language: "en-us",
        value: header_title,
      },
    ],
    images: [
      {
        deviceType: "desktop",
        imageUrl: header_desktop_img,
        altText: header_desktop_alttext,
      },
      {
        deviceType: "mobile",
        imageUrl: header_mobile_img,
        altText: header_mobile_alttext,
      },
    ],
  };

  let schemaHighlights = [
    {
      title: [
        {
          language: "en-us",
          value: highlight1_title,
        },
      ],
      icon: {
        url: highlight1_icon,
        altText: [
          {
            language: "en-us",
            value: highlight1_title,
          },
        ],
      },
    },
    {
      title: [
        {
          language: "en-us",
          value: highlight2_title,
        },
      ],
      icon: {
        url: highlight2_icon,
        altText: [
          {
            language: "en-us",
            value: highlight2_title,
          },
        ],
      },
    },
    {
      title: [
        {
          language: "en-us",
          value: highlight3_title,
        },
      ],
      icon: {
        url: highlight3_icon,
        altText: [
          {
            language: "en-us",
            value: highlight3_title,
          },
        ],
      },
    },
  ];

  let schemaCarousel = {
    title: [
      {
        language: "en-us",
        value: carousel_title,
      },
    ],
    quotes: carousel_quotes.length > 0 ? carousel_quotes : []
  };

  useEffect(() => {
    if (contents?.length === 0) {
      CONTENTS("WelcomePage", reduxDispatcher);
    }
    else if (contents?.data?.some(item => !item.contentId.includes("welcome")))
      CONTENTS("WelcomePage", reduxDispatcher);

    reduxDispatcher(setCurrentStep([]));
    reduxDispatcher(setQuestionnaireFlow([]))
    reduxDispatcher(setAnswerState([]))
    reduxDispatcher(setAnswerPairs([]))
    reduxDispatcher(setIsLastStep(false))
    reduxDispatcher(setGoalsArray([]))

    GTMHelper.pushEvent("e_moneychat_vpv", {
      pageType: 'start'
    });
  }, []);

  return (
    <div id="welcome-page">
      <div className="components-wrapper">
        <Header {...schemaHeader} />
        <div className="spacing welcome">
          <h2 className="title">{title}</h2>
          <div className="subtitle">{subtitle}</div>
          <div className="highlights-list">
            <div className="d-flex flex-column flex-md-row justify-content-center">
              {schemaHighlights.map((highlight, index) => {
                return <Highlight key={index} {...highlight} />;
              })}
            </div>
          </div>
          <div className="text-center">
            <CostumButton
              text={letsbegin}
              fill={true}
              nextPage="questionnaire"
              costumFunction={handleState}
            />
          </div>
        </div>
      </div>
      <div className="components-wrapper">
        <Carousel {...schemaCarousel} />
      </div>
    </div>
  );
};

export default WelcomePage;
