import React from "react";

import "../styles/header.scss";
import { replacePlaceholderName } from "@helpers/nameHelper.tsx";

const Header = ({ title, images }) => {
  return (
    <div className="app-header">
      {images && images.length > 0 && images.map((img, index) => {
        return (
          <img
            alt=""
            key={index}
            className={img.deviceType.toLowerCase() === "desktop" ? "desktop" : "mobile"}
            src={img.imageUrl}
          />
        );
      })}
      <div className="header-title">
        {title && title.length > 0 && title[0].value.split("\n").map((titleParagraph, index) => {
          return <h1 key={index}>{replacePlaceholderName(titleParagraph)}</h1>;
        })}
      </div>
    </div>
  );
};

export default Header;
