import React, { useState, useEffect } from 'react';

import Input from "../components/Generic/Input";
import Label from "../components/Generic/Label";
import Card from "../components/Generic/Card";
import RadioButton from "../components/Custom/RadioButton";

import '../styles/lifeStage.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setSubstrepInfo } from '../redux/actions';

const types = {
    default: "RadioButtonListItem",
    withTextInput: "RadioButtonListWithFillItem",
    withIcon: "RadioButtonListWithIconItem",
    withRange: "RangeItem"
}

//helper functions
const RadioTypeOutput = (answerID, { id, controlItemType, label, description, icon }, selectedItem, answersSelected, handleOnChange, unselect) => {
    let answerId = answerID !== "" ? answerID + "." + id : id;

    switch (controlItemType) {
        case types.default:
            return (
                <RadioButton
                    titles={label}
                    description={description}
                    controlType={controlItemType}
                    id={answerID !== "" ? answerID + "." + id : id}
                    key={id}
                    handleState={handleOnChange}
                    selectedItem={selectedItem && !answersSelected && selectedItem?.selectedId?.toString() === answerId?.toString() || selectedItem?.id?.toString() === answerId?.toString() ? selectedItem : null}
                />
                // <Label text={label[0].value} htmlFor={id}>
                //     <Input type="radio" id={id} />
                // </Label>
            )
            break;

        case types.withTextInput:
            return (
                <RadioButton
                    titles={label}
                    description={description}
                    controlType={controlItemType}
                    id={answerID ? answerID + "." + id : id}
                    key={id}
                    selectedItem={selectedItem && !answersSelected && selectedItem?.selectedId?.toString() === answerId?.toString() || selectedItem?.id?.toString() === answerId?.toString() ? selectedItem : null}
                    handleState={handleOnChange}
                    unselect={unselect}
                    onlyNumber={!answersSelected ? true : false} />
                // <Label text={label[0].value} htmlFor={id}>
                //     <Input type="radio" id={id} />
                // </Label>
            )
            break;

        case types.withIcon:
            return (
                <Card
                    filled={answersSelected && answersSelected === id.toString() ? true : false}>
                    <img src={icon.imageUrl} alt="" />
                    <Label text={label[0].value} htmlFor={id}>
                        <Input
                            type="radio"
                            tabindex="-1"
                            id={answerID ? answerID + "." + id : id}
                            value={label[0].value}
                            checked={answersSelected === id.toString() ? true :
                                !answersSelected && selectedItem?.selectedId?.toString() === id?.toString() ? true
                                    : null}
                        />
                    </Label>
                </Card>
            )
            break;
        default:
            break;
    }
}

//smaller components
const LifeStageSubQuestions = ({ answerID, subQuestions, selectedItem, answersSelected, setAnswerSelected, unselect }) => {
    let subSelectedItem = null;

    if (selectedItem) {
        let auxSelected = selectedItem?.selectedSubAnswers ? selectedItem?.selectedSubAnswers : "";
        let answerId = selectedItem.selectedId ? selectedItem.selectedId : selectedItem.id ? selectedItem.id : ""
        let subAnswerId = auxSelected.selectedId ? auxSelected.selectedId : auxSelected.id ? auxSelected.id :
            auxSelected.answers?.[0].optionId ? auxSelected.answers?.[0].optionId : auxSelected.answers?.[0].id ? auxSelected.answers?.[0].id : ""

        let idSelected = answerId;
        idSelected += subAnswerId ? "." + subAnswerId : ""

        subSelectedItem = {
            selectedId: idSelected,
            selectedValue: auxSelected.value ? auxSelected.value : auxSelected.answers?.[0].value ? auxSelected.answers?.[0].value : ""
        }
    }

    return (
        <div className="sub-options">
            {RadioTypeOutput(answerID, subQuestions, subSelectedItem, null, () => { }, unselect)}
        </div>
    )
}

//others input 
const OthersInput = ({ id, text, checkKeyPressed, resetText, setResetText, handleState }) => {
    const [othersText, setOthersText] = useState();

    useEffect(() => {
        if (text && text != undefined && text != "") {
            setOthersText(text)
        }
    }, []);

    useEffect(() => {
        if (resetText) {
            setOthersText("")
            setResetText(false)
        }
    }, [resetText])

    function textChange(text) {
        setOthersText(text);
        handleState('text', id, text);
    }

    return <input
        id={id}
        placeholder="Type here"
        className="other-input"
        onFocus={(event) => event.target.placeholder = ""}
        onBlur={(event) => event.target.placeholder = "Type Here"}
        onKeyDown={event => checkKeyPressed(event)}
        onChange={event => textChange(event.target.value)}
        value={othersText}
        maxLength={250}
        required
        aria-required="true"
    />
}

const RadioButtonList = ({ type, options, handleState, resetState, setCompleted, selectedItem }) => {
    const [answerSelected, setAnswerSelected] = useState();
    const currentStepType = useSelector(state => state.questionnaireFlow.currentStep.controlType);
    const [unselect, setUnselected] = useState();
    const [withOtherInput, setWithOtherInput] = useState();
    const [withoutSubQuestions, setWithoutSubQuestions] = useState();

    //reseting other input
    const [resetOtherInput, setResetOtherInput] = useState();

    const reduxDispatcher = useDispatch();

    //aplly diferent style  if this variable its true
    const [className, setClassName] = useState("centered");

    useEffect(() => {
        if (selectedItem) {
            if (answerSelected) {
                if (selectedItem.selectedId === answerSelected) setAnswerSelected(selectedItem.selectedId)
            }
            else {
                setAnswerSelected(selectedItem.selectedId)
            }
            //if (selectedItem.id) setAnswerSelected(selectedItem.id)
        }
    }, [selectedItem])

    //clear subAnswers before option change
    function ClearSubAnswers() {
        let subQuestionsVisible = document.querySelector(".sub-questions.show")

        if (subQuestionsVisible) {
            let radioOptionsSelected = subQuestionsVisible.querySelectorAll("input[type='radio']:checked")
            let radioOptionsSelectedArray = Array.prototype.slice.call(radioOptionsSelected);
            let otherInput = subQuestionsVisible.querySelector("#other");

            let textOptions = subQuestionsVisible.querySelectorAll("input[type='text']")
            let textOptionsArray = Array.prototype.slice.call(textOptions)

            if (textOptionsArray && textOptionsArray.length > 0) {
                textOptionsArray.map(textOption => textOption.value = "")
            }

            if (radioOptionsSelectedArray && radioOptionsSelectedArray.length > 0) {
                radioOptionsSelectedArray.forEach(input => {
                    input.checked = false

                    if (input.nextElementSibling.querySelectorAll("input[type='text']")) {
                        setUnselected(true)
                    }
                })
            }

            if (otherInput) {
                setResetOtherInput(true)
            }
        }
    }

    //allow only one item checked
    const HandleAnswersOnChange = (event) => {
        let options = document.querySelectorAll('.options input');

        let answerId = event.target.id;
        let answerType = "radio"
        let answerValue = event.target.value;

        ClearSubAnswers();

        setAnswerSelected(answerId)

        for (let i = 0; i < options.length; i++) {
            if (event.target != options[i] && options[i].checked === true) {
                options[i].checked = false;
            }
            options[i].parentElement.parentElement.parentElement.classList.remove("filled");
        }

        if (event.target.closest(".options").nextElementSibling
            && event.target.closest(".options").nextElementSibling.classList.contains("sub-questions")) {
            let otherInput = event.target.closest(".options").nextElementSibling.querySelector(".other-input")

            if (otherInput) {
                if (otherInput.value && otherInput.value !== "")
                    answerValue = otherInput.value
            }
        }

        let optionSelected = event.target.closest(".options").dataset.optionid;
        let optionslist = event.target.closest(".options-row")
        let parentNodeSibling = optionslist.querySelector(`.sub-questions[data-optionid='${optionSelected}']`);

        if (parentNodeSibling?.querySelector(".other-input")) {
            setWithOtherInput(true)
        }
        else {
            setWithOtherInput(false)
        }

        if (parentNodeSibling != null) {
            setWithoutSubQuestions(false)
            handleState(answerType, answerId, answerValue)

            if (parentNodeSibling.classList.contains("sub-questions") === false) {
                if (resetState) {
                    resetState(false)
                    setCompleted(true)
                }
            }
            else {
                if (resetState) {
                    resetState(true)
                    setCompleted(false)
                }
            }
        }
        else {
            setWithoutSubQuestions(true)
            handleState(answerType, answerId, answerValue)

            if (resetState)
                resetState(false)
        }

        //event.target.parentElement.parentElement.parentElement.classList.add("filled");       
        if (currentStepType.toLowerCase() === "goalselector") {
            let iconUrl = event.target.parentElement.parentElement.parentElement.parentElement.querySelector("img").src;
            reduxDispatcher(setSubstrepInfo(
                {
                    icon: iconUrl,
                    title: answerValue
                }
            ))
        }
    }

    const HandleSubQuestions = (event) => {
        let options = document.querySelectorAll('.sub-questions.show input');

        let answerId = event.target.id;
        let answerType = "radio";
        let answerValue = event.target.value;

        if (!event.target.classList.contains("inside-text") && !event.target.classList.contains("other-input") && event.target.nextSibling.querySelectorAll('input[type="text"]').length > 0) {
            setUnselected(false)
            if (event.target.nextSibling.querySelector('input[type="text"]').value !== "") {
                if (document.querySelector(".sub-questions.show .other-input")) {
                    if (document.querySelector(".sub-questions.show .other-input").value !== "") {
                        resetState(false)
                        setCompleted(true)
                        handleState(answerType, answerId, answerValue)
                    }
                }
                else {
                    resetState(false)
                    setCompleted(true)
                    handleState(answerType, answerId, answerValue)
                }
            }
            else {
                resetState(true)
                setCompleted(false)
            }
        }
        else if (event.target.classList.contains("other-input")) {
            //if the option selected has a text field to input the subject            
            if (event.target.value !== "") {
                ////////////// Find out if answers already exists \\\\\\\\\\\\\\\
                if (document.querySelector(".sub-questions.show input:checked")) {
                    let optionSelected = document.querySelector(".sub-questions.show input:checked");
                    if (optionSelected.nextSibling.querySelector('input')) {
                        let subOptionSelected = optionSelected.nextSibling.querySelector('input')
                        if (subOptionSelected.value != "") {
                            resetState(false)
                            setCompleted(true)
                            //handleState(answerType, answerId, answerValue)
                        }
                    }
                    else {
                        resetState(false)
                        setCompleted(true)
                        //handleState(answerType, answerId, answerValue)
                    }
                }

                handleState(answerType, answerId, answerValue)
            }
            else {
                resetState(true)
                setCompleted(false)
                //handleState(answerType, answerId, answerValue)
            }
        }
        else if (event.target.classList.contains("inside-text")) {
            if (event.target.value !== "" && event.target.value.replace(/[^0-9]/g, '') !== "") {
                if (document.querySelector(".sub-questions.show .other-input")) {
                    if (document.querySelector(".sub-questions.show .other-input").value && document.querySelector(".sub-questions.show .other-input").value !== "") {
                        resetState(false)
                        setCompleted(true)
                        handleTextInputClick(event)

                        if (event.target.classList.contains("only-numbers")) answerValue = event.target.value.replace(/[^0-9]/g, '')
                        handleState(answerType, answerId, answerValue)
                    }
                    else {
                        handleTextInputClick(event)
                    }
                }
                else {
                    resetState(false)
                    setCompleted(true)
                    handleTextInputClick(event)

                    if (event.target.classList.contains("only-numbers")) answerValue = event.target.value.replace(/[^0-9]/g, '')
                    handleState(answerType, answerId, answerValue)
                }
            }
            else {
                resetState(true)
                setCompleted(false)
                handleTextInputClick(event)
            }
        }
        else {
            setUnselected(true)
            if (document.querySelector(".sub-questions.show .other-input")) {
                if (document.querySelector(".sub-questions.show .other-input").value && document.querySelector(".sub-questions.show .other-input").value !== "") {
                    resetState(false)
                    setCompleted(true)
                    handleState(answerType, answerId, answerValue)
                }
            }
            else {
                resetState(false)
                setCompleted(true)
                handleState(answerType, answerId, answerValue)
            }
        }
    }

    function checkKeyPressed(event) {
        if (event.key === "Enter" || event.key === "Keys.ENTER") {
            event.preventDefault();
            document.activeElement.blur()
        }
    }

    function focusElement(event) {
        var subquestions = Array.prototype.slice.call(document.querySelectorAll(".sub-questions.show input"));
        var radioButtons = Array.prototype.slice.call(document.querySelectorAll(".sub-questions.show input[type='radio']"));

        //Allow arrow keys to change focus between sub options
        let selectedRadio = document.querySelector(".sub-questions.show .sub-options input:focus");
        if (event.key === "ArrowDown" || event.key === "ArrowUp" || event.key === "ArrowRight" || event.key === "ArrowLeft") {
            event.preventDefault()
            //control lifestage sub options radio buttons
            if (selectedRadio) {
                if (event.key === "ArrowDown" || event.key === "ArrowRight") {

                    //focused element has inside text input
                    if (selectedRadio.type === "text") {
                        if (selectedRadio.closest(".sub-options")?.nextElementSibling)
                            setTimeout(() => selectedRadio.closest(".sub-options")?.nextElementSibling?.querySelector(".answer")?.focus())
                    }
                    else {
                        if (selectedRadio.nextElementSibling?.querySelectorAll("input[type='text']").length > 0 && selectedRadio.checked) {
                            setTimeout(() => selectedRadio.nextElementSibling?.querySelector("input[type='text']")?.focus())
                        }
                        else if (selectedRadio.closest(".sub-options").nextElementSibling)
                            setTimeout(() => selectedRadio.closest(".sub-options")?.nextElementSibling.querySelector(".answer")?.focus())
                    }
                }
                if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
                    if (selectedRadio.type === "text") {
                        if (selectedRadio.parentElement?.previousElementSibling) setTimeout(() => selectedRadio.parentElement.previousElementSibling.focus())
                    }
                    else {
                        setTimeout(() => selectedRadio.closest(".sub-options").previousElementSibling?.querySelector(".answer")?.focus())
                    }
                }
            }
        }

        if (event.key === "Tab") {
            if (document.activeElement && radioButtons && subquestions && subquestions.length > 0) {
                //If focus its in the last sub-option decide on where it is going 
                if (document.activeElement === radioButtons[radioButtons.length - 1] || document.activeElement === subquestions[subquestions.length - 1]) {
                    if (subquestions.some(x => x.checked)) {
                        var seletedOption = subquestions.find(x => x.checked)
                        var seletedOptionSub = Array.prototype.slice.call(seletedOption.nextElementSibling.children)

                        if (seletedOptionSub?.some(x => x.type === "text")) {
                            let selectedOptionValue = seletedOptionSub.find(x => x.type === "text").value

                            if (selectedOptionValue && selectedOptionValue !== "") {
                                var backBtn = document.querySelector("#dmcWidget .buttons-row button[type='reset']")

                                window.setTimeout(function () {
                                    backBtn.focus();
                                }, 0);
                            }
                        }
                        else {
                            var backBtn = document.querySelector("#dmcWidget .buttons-row button[type='reset']")

                            window.setTimeout(function () {
                                backBtn.focus();
                            }, 0);
                        }
                    }
                }
            }
        }
    }

    function handleTextInputClick(event) {
        let targetInput = event.target;

        if (targetInput.classList.contains("inside-text")) {
            let selectedRadio = targetInput.closest(".answer").querySelector("input[type='radio']")

            if (selectedRadio && !selectedRadio.checked) {
                let radioSubOptions = Array.prototype.slice.call(document.querySelectorAll(".sub-questions.show input[type='radio']"));

                if (radioSubOptions && radioSubOptions.length > 0)
                    radioSubOptions.map(subOption => {
                        if (subOption === selectedRadio) {
                            subOption.checked = true;
                        }
                        else {
                            subOption.checked = false
                        }
                    })

                if (targetInput.value === "") {
                    resetState(true)
                    setCompleted(false)
                }
            }
        }
    }

    return (
        <div className="lifeStage-container">
            <div className="lifeStage-header">
                <div className={options?.answerOptions?.some(o => o.subQuestions?.length > 0) ? "title lifeStage" : "title"}>{options.titles[0].value}</div>
                {options.descriptions && <div className="small-title">{options.descriptions[0]?.value}</div>}
            </div>
            <div className={type === "row" && className ? `options-row ${className}` :
                type === "row" && !withoutSubQuestions && answerSelected && withOtherInput ? "options-row with-subquestion-open with-other" :
                    type === "row" && !withoutSubQuestions && !answerSelected && withOtherInput ? "options-row with-subquestion-open with-other" :
                        type === "row" && !withoutSubQuestions && answerSelected && !withOtherInput ? "options-row with-subquestion-open" :
                            type === "row" ? "options-row" : ""}>
                {options.answerOptions.map((answer, index) => {
                    if (answer.subQuestions && className !== null) setClassName(null)

                    let lastIndex = options.answerOptions.length - 1;

                    return (
                        <>
                            <div
                                className={answer === options.answerOptions[lastIndex] ? "options last" : "options"}
                                data-optionid={index}
                                onChange={(event) => { HandleAnswersOnChange(event) }}
                                onClick={(event) => {
                                    ClearSubAnswers();
                                    setAnswerSelected(Number(index) + 1);
                                    document.getElementById(Number(index) + 1).closest(".card").classList.add("filled");
                                    document.getElementById(Number(index) + 1).checked = true;
                                    let options = document.querySelectorAll(".card input");
                                    options.forEach((el) => {

                                        el.closest(".options").addEventListener("mouseout", () => {
                                            el.closest(".card").blur();
                                        });

                                        el.closest(".options").addEventListener("touchmove", () => {
                                            el.closest(".card").blur();
                                            el.closest(".card").classList.add("dragged");
                                            el.closest(".card").draggable = "false";
                                        });

                                        let optionSelected = el.closest(".options").dataset.optionid;
                                        let optionslist = el.closest(".options-row")
                                        let parentNodeSibling = optionslist.querySelector(`.sub-questions[data-optionid='${optionSelected}']`);

                                        if (parentNodeSibling?.querySelector(".other-input")) {
                                            setWithOtherInput(true);
                                        }
                                        else {
                                            setWithOtherInput(false);
                                        }

                                        if (Number(el.id) !== (Number(index) + 1)) {
                                            el.checked = false;
                                            el.closest(".card").classList.remove("filled");
                                        } else {


                                            if (parentNodeSibling != null) {
                                                setWithoutSubQuestions(false)
                                                handleState("radio", (Number(index) + 1).toString(), el.value)

                                                if (parentNodeSibling.classList.contains("sub-questions") === false) {
                                                    if (resetState) {
                                                        resetState(false)
                                                        setCompleted(true)
                                                    }
                                                }
                                                else {
                                                    if (resetState) {
                                                        resetState(true);
                                                        setCompleted(false);
                                                    }
                                                }
                                            }
                                            else {
                                                setWithoutSubQuestions(true);
                                                handleState("radio", (Number(index) + 1).toString(), el.value)
                                                if (resetState) {
                                                    resetState(false);
                                                    setCompleted(true);
                                                }
                                            }

                                            if (currentStepType.toLowerCase() === "goalselector") {
                                                let iconUrl = el.parentElement.parentElement.parentElement.parentElement.querySelector("img").src;
                                                reduxDispatcher(setSubstrepInfo(
                                                    {
                                                        icon: iconUrl,
                                                        title: el.value
                                                    }
                                                ))
                                            }

                                        }
                                    }
                                    );
                                }}>
                                {RadioTypeOutput("", answer, selectedItem, answerSelected, () => { }, unselect)}
                            </div>
                        </>
                    )
                })}
                {options.answerOptions.map((answer, index) => {
                    if (answer.subQuestions && className !== null) setClassName(null);
                    return (
                        <>
                            {answer.subQuestions &&
                                <div className={answer.id?.toString() === answerSelected?.toString() ? "sub-questions show" : "sub-questions"} data-optionid={index}
                                    onChange={(event) => { HandleSubQuestions(event) }}
                                    onKeyDown={(event) => focusElement(event)}
                                    onClick={(event) => {
                                        handleTextInputClick(event);

                                        if (event.target.value && event.target.value !== "") {
                                            handleState("radio with text input", event.target.id, event.target.value)
                                        }
                                    }}>
                                    <div className="sub-answers">
                                        {answer.subQuestions.length === 1 && answer.subQuestions.some(s => s.titles != null) && <div className="small-title">{answer.subQuestions.find(a => a.titles != null).titles[0].value}</div>}
                                        {answer.subQuestions.length === 1 && answer.subQuestions[0].answerOptions &&
                                            answer.subQuestions[0].answerOptions.map((subQuestion) => {
                                                return <LifeStageSubQuestions subQuestions={subQuestion} answerID={answer.id} selectedItem={selectedItem} answersSelected={answerSelected} setAnswerSelected={setAnswerSelected} unselect={unselect} />
                                            })
                                        }
                                        {answer.subQuestions.length === 1 && answer.subQuestions[0].answerOptions === null && answer.subQuestions[1].answerOptions &&
                                            answer.subQuestions[0].answerOptions.map((subQuestion) => {
                                                return <LifeStageSubQuestions subQuestions={subQuestion} answerID={answer.id} selectedItem={selectedItem} answersSelected={answerSelected} setAnswerSelected={setAnswerSelected} unselect={unselect} />
                                            })
                                        }
                                        {answer.subQuestions.length > 1 &&
                                            answer.subQuestions.map((subQuestion) => {
                                                if (subQuestion.answerOptions) {
                                                    return (
                                                        <>
                                                            {answer.subQuestions.some(s => s.titles != null) && <div className="small-title">{answer.subQuestions.find(a => a.titles != null).titles[0].value}</div>}
                                                            {subQuestion.answerOptions.map((ans) => {
                                                                return <LifeStageSubQuestions subQuestions={ans} answerID={answer.id} selectedItem={selectedItem} answersSelected={answerSelected} setAnswerSelected={setAnswerSelected} unselect={unselect} />
                                                            })}
                                                        </>
                                                    )
                                                }
                                                else {
                                                    if (!withOtherInput) setWithOtherInput(true)

                                                    return <OthersInput
                                                        id={"other"}
                                                        resetText={resetOtherInput}
                                                        setResetText={setResetOtherInput}
                                                        text={selectedItem?.selectedId?.toString() === answer?.id?.toString() ? selectedItem?.selectedValue : null}
                                                        checkKeyPressed={checkKeyPressed}
                                                        handleState={handleState}
                                                    />
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </>
                    )
                })}
            </div>
        </div>
    )
}

export default RadioButtonList;