/*
 GTM - Google Tag Manager Helper
*/
import log from "../helpers/logger";

export default class GTMHelper {

    static pushEvent(eventName, money_chat) {
        window['dataLayer'] = window['dataLayer'] || [];

        if (money_chat) {
            window.dataLayer.push({
                'event': eventName,
                money_chat
            });
        }
        else {
            window.dataLayer.push({
                'event': eventName
            });
        }

        log.debug("dataLayer", window['dataLayer']);
    }
}
