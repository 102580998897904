import { SET_RESUMEDDMC, SET_STAFFRESUMEDDMC } from "../actions/actionTypes";

function resumedDmc(state = { isStaffDmc: null, dmc: null }, action) {
    switch (action.type) {
        case SET_RESUMEDDMC:
            return { isStaffDmc: state.isStaffDmc, dmc: action.value }
        case SET_STAFFRESUMEDDMC:
            return { isStaffDmc: action.value, dmc: state.dmc }
        default:
            return state
    }
}

export default resumedDmc