import React, { FC } from "react";
import "./Header.scss";
import { IGenericItem, IImage } from "@components/Interfaces";
import parse from "html-react-parser";
import { replacePlaceholderName } from "@helpers/nameHelper.tsx";

interface IProps {
    title?: string;
    images?: IImage[];
}

const Header: FC<IProps> = ({ title, images }) => {
    return (
        <>
            <div className="app-header">
                {images?.map((img, index) => {
                    return <img
                        src={img.imageUrl}
                        className={img.deviceType?.toLowerCase() === "desktop" ? "desktop" : "mobile"}
                        alt={img.altText[0].value}
                        key={index}
                    />
                })}
                <div className="header-title">
                    {title && <h1>{parse(replacePlaceholderName(title))}</h1>}
                </div>
            </div>
        </>
    );
}
export default Header;