import { combineReducers } from "redux";

// REDUX REDUCERS
import customerQuestionnaireResponse from "./customerQuestionnaireResponse";
import customerResults from "./customerResults";
import genericContents from "./genericContents";
import questionnaireFlow from "./questionnaireFlow";
import questionnaireWording from "./questionnaireWording";
import authorization from "./authorization";
import respondents from "./respondents";
import contents from "./contents";
import requestLoading from "./requestLoading";
import loaderMessage from "./loaderMessage";
import respondentsDmcs from "./respondentsDmcs";
import resumedDmc from "./resumedDmc";
import startDMC from './startDMC';
import alert from "./alert";
import questionnaireProgress from './questionnaireProgress';
import goals from './goals';
import summary from './summary';
import history from './history';
import welcome from './welcome';
import error from './error';
import context from './context';
import dmcSession from './dmcSession'

// REDUCERS COMBINED
const rootReducer = combineReducers({
  alert,
  requestLoading,
  loaderMessage,
  contents,
  respondents,
  respondentsDmcs,
  resumedDmc,
  authorization,
  customerQuestionnaireResponse,
  customerResults,
  genericContents,
  questionnaireFlow,
  questionnaireWording,
  startDMC,
  questionnaireProgress,
  goals,
  summary,
  history,
  welcome,
  error,
  context,
  dmcSession
});

export default rootReducer;
