function goals(state = { currentGoals: [], substepInfo: [], subStepQuestions: [], subStepAnswers: [] }, action) {
    switch (action.type) {
        case "ADD_NEW_GOAL":
            return { currentGoals: [...state.currentGoals, action.value], substepInfo: state.substepInfo, subStepQuestions: state.subStepQuestions, subStepAnswers: state.subStepAnswers };
        case "SET_GOALS_ARRAY":
            return { currentGoals: [...action.value], substepInfo: state.substepInfo, subStepQuestions: state.subStepQuestions, subStepAnswers: state.subStepAnswers }
        case "ADD_GOAL_SUBQUESTION":
            return { currentGoals: state.currentGoals, substepInfo: state.substepInfo, subStepQuestions: [...state.subStepQuestions, action.value], subStepAnswers: state.subStepAnswers }
        case "SET_GOAL_SUBQUESTIONS":
            return { currentGoals: state.currentGoals, substepInfo: state.substepInfo, subStepQuestions: [...action.value], subStepAnswers: state.subStepAnswers }
        case "SET_GOAL_ANSWERS":
            return { currentGoals: state.currentGoals, substepInfo: state.substepInfo, subStepQuestions: state.subStepQuestions, subStepAnswers: action.value }
        case "ADD_GOAL_SUBQUESTION_ANSWER":
            return { currentGoals: state.currentGoals, substepInfo: state.substepInfo, subStepQuestions: state.subStepQuestions, subStepAnswers: [...state.subStepAnswers, action.value] }
        case "SET_SUBSTEP_INFO":
            return { currentGoals: state.currentGoals, substepInfo: action.value, subStepQuestions: state.subStepQuestions, subStepAnswers: state.subStepAnswers }
        default:
            return state;
    }
}

export default goals;