import React, { FC } from "react";
import { IHighlight, IImage } from "@components/Interfaces";
import "./HighlightListItem.scss";
import Icon from "@components/Generic/Icon";
import Title from "../../Title/Title";

interface IProps {
    sectionId: string;
    description?: string;
    borderType?: string;
    borderColor?: string;
    backgroundColor?: string;
    icon?: IImage;
}

const HighlightListItem: FC<IProps> = ({ sectionId, borderType, borderColor, backgroundColor, icon, description }) => {
    const className = ["highlight-item", borderType].join(" ");
    return (
        <a href={"#" + sectionId}
            className={className}
            aria-label={description ? description : undefined}
            style={
                {
                    borderColor: borderColor,
                    backgroundColor: backgroundColor
                }
            }
        >
            {icon && <Icon imageUrl={icon[0].imageUrl} altText={icon.altText?.[0].value} />}
            {description && <span className="description" aria-hidden={true}>{description}</span>}
        </a >
    );
}
export default HighlightListItem;