import React, { useState, useEffect } from "react";
import "../../styles/selector.scss";

interface SelectorOption {
  value: string;
  text: string;
}
interface Props {
  optionsList: Array<SelectorOption>;
  selectedItem: string;
  accessibilityText: string;
}

const mockSelectOptions: Array<SelectorOption> = [];

/* Options can be set through props or state*/

/**
 * Fill the select options with an array of strings.
 * @param {Array} optionsList - Array of strings
 * @example
 * <Selector optionsList={["Option 1", "Option 2", "Option 3"]} />
 *
 * or
 *
 * const arr = ["Option 1", "Option 2", "Option 3"]
 * <Selector optionsList={arr} />
 */
const Selector = ({ optionsList, selectedItem, accessibilityText }: Props) => {
  const [options, setOptions] = useState(mockSelectOptions);

  useEffect((): void => {
    if (optionsList?.length > 0) setOptions(optionsList);
  }, []);

  return (
    <div className="select">
      <select placeholder={"Select"} aria-label={accessibilityText}>
        <option>Select</option>
        {
          options?.map((item, index) => {
            if (selectedItem && item.text === selectedItem) {
              return <option key={index} id={item.value} value={item.text} aria-label={item.text?.indexOf("-") > -1 ? `${item.text?.split("-")[0]} to ${item.text?.split("-")[1]}` : undefined} selected>
                {item.text}
              </option>
            }
            else {
              return <option key={index} id={item.value} value={item.text} aria-label={item.text?.indexOf("-") > -1 ? `${item.text?.split("-")[0]} to ${item.text?.split("-")[1]}` : undefined}>
                {item.text}
              </option>
            }
          })
        }
      </select>
      <div className="select_arrow">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Selector;
