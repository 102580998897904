import remote from 'loglevel-plugin-remote'
const getLogger = require('loglevel-colored-level-prefix');
const logger = getLogger();
//const JSONLog = log => ({
//  msg: log.message,
//  level: log.level.label,
//  stacktrace: log.stacktrace
//});

//remote.apply(log, { format: JSONLog, url: '/logger' });

logger.setLevel(process.env.REACT_APP_LOG_LEVEL);

export default logger