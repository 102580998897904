function genericContents(state = [], action) {
  switch (action.type) {
    case "GENERIC_CONTENTS":
      return action.value;
    default:
      return state;
  }
}

export default genericContents;
