import React, { FC, useState } from "react";
import { IIcon, INoticeButtonAction, ILink } from "../../../../../../Interfaces";
import Icon from "@components/Generic/Icon";
import "./NoticeButton.scss";
import expandArrow from "@assets/svg/expandArrow.svg"
import NoticePanel from "../NoticePanel/NoticePanel";
import GTMHelper from "@helpers/GTMHelper.js";

interface IProps {
    icon?: IIcon;
    text: string;
    btnDesciption?: string;
    action?: INoticeButtonAction;
    link?: ILink;
    resultsSection?: string;
}

const NoticeButton: FC<IProps> = ({ icon, text, btnDesciption, link, action, resultsSection }) => {
    const [open, setOpen] = useState(false);

    /* --- FUNCTIONS --- */
    /* --- open expandable panel --- */
    const openExpandable: Function = () => {
        open ? setOpen(false) : setOpen(true)
        return false;
    }
    /* --- open link on another page --- */
    const openLink = (url) => {

        GTMHelper.pushEvent("e_moneychat_contact_cta", {
            cta_text: text,
            results_section: resultsSection
        });

        // if (url) {
        //     if (url.indexOf("tel:") > -1)
        //         //Because of DCU cannot open in same window.
        //         window.open(url);
        //     else
        //         window.open(url);
        // }
        return false;
    }

    return <>
        {link ? <a href={link && link.url} target={link.target} tabIndex={0} className="notice-button"
            onClick={
                action?.actionType === "expand" ? () => openExpandable() :
                    action ? () => openLink(action?.href) :
                        () => openLink(link?.url)}
            aria-label={`${link.url.indexOf("tel") >= 0 ? text.replace(/-/g, " dash ") : text} ${link.target && link.target === "_blank" ? "in a new tab" : ""}`}
        >
            {icon && <Icon imageUrl={icon.url} altText={icon.altText?.[0].value} ariaHidden={true} />}
            {text}
            {action?.element && <img aria-hidden="true" className={open ? "expanded arrow" : "arrow"} src={expandArrow} alt={"expandArrow"} />}
        </a>
            :
            <button type="button" className="notice-button" onClick={
                action?.actionType === "expand" ? () => openExpandable() : () => openLink(action?.href)}
                aria-expanded={open ? "true" : "false"}>
                {icon && <Icon imageUrl={icon.url} altText={icon.altText?.[0].value} ariaHidden={true} />}
                {text}
                {action?.element && <img aria-hidden="true"
                    className={open ? "expanded arrow" : "arrow"} src={expandArrow} alt={"expandArrow"} />}
            </button>
        }
        {btnDesciption && <div className="btn-description">{btnDesciption}</div>}
        {
            action?.element &&
            <NoticePanel open={open}>
                {action?.element.elements.map((ele, index) => {
                    return <div className="element" key={index}>
                        <NoticeButton
                            key={index}
                            icon={ele.icon}
                            text={ele.link.title?.[0].value}
                            btnDesciption={ele.description?.[0].value}
                            link={ele.link}
                            resultsSection={resultsSection}
                        />
                    </div>
                })}
            </NoticePanel>
        }
    </>;
}
export default NoticeButton;