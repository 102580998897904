import * as React from 'react';

interface IProps {
    open: boolean
}

const NoticePanel: React.FC<IProps> = (props) => {
    return <div className={props.open ? "expanded panel" : "panel"}>
        <div>
            {props.children}
        </div>
    </div>
}

export default NoticePanel