import React, { useEffect, useState } from 'react';
import CostumProgressbar from "../../Progressbar";
import CostumButtom from "../../CustomButton";
import Carousel from '../../Carousel';

import { useSelector, useDispatch } from 'react-redux';

//styles
import "./ResumePage.scss";
import { RESUME_DMC, CONTENTS } from '../../../requests/api';
import { setResumedDmc, setStaffResumedDmc, setSummaryAlert } from '../../../redux/actions';
import GTMHelper from "@helpers/GTMHelper.js";

//content types
import * as contentTypes from "../../../types/ContentTypes"

let headerTitle = null;
let continueAssessmentBtnText = null;
let startAssessmentLink = null;
let carouselTitle = null;
let resumeCarouselQuotes = [];

//set static contents
function setResumeStaticContents(contents) {
    if (contents && contents.length > 0) {
        contents.map(element => {
            if (element.contentId === contentTypes.ResumePage_Header_Title) {
                headerTitle = element.contentVersion.resources[0].value
            }
            else if (element.contentId === contentTypes.ResumePage_Button_ContinueAssessment) {
                continueAssessmentBtnText = element.contentVersion.resources[0].value
            }
            else if (element.contentId === contentTypes.ResumePage_Link_StartAssessment) {
                startAssessmentLink = element.contentVersion.resources[0].value
            }
            else if (element.contentId === contentTypes.ResumePage_Carousel_Title) {
                carouselTitle = element.contentVersion.resources;
            }
            else if (element.contentId.indexOf("quote") > -1) {
                if (resumeCarouselQuotes.length <= process.env.REACT_APP_MAX_QUOTES) {
                    if (!resumeCarouselQuotes.some(e => e.value === element.contentVersion.resources[0].value))
                        resumeCarouselQuotes.push({ value: element.contentVersion.resources[0].value })
                }
            }
        })
    }
}

//start new dmc
function startNewDmcOption(event, resumeDmcQuestionnaire, reduxDispatcher) {
    event.preventDefault()

    GTMHelper.pushEvent("e_moneychat_new_assessment");

    reduxDispatcher(setResumedDmc(null))
    reduxDispatcher(setStaffResumedDmc(null))
    reduxDispatcher(setSummaryAlert(false))

    resumeDmcQuestionnaire()
}

//get ordinal num
const getOrdinalNum = (number) => {
    let selector = 0;

    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return ['th', 'st', 'nd', 'rd', ''][selector];
};

const ResumePage = ({ resumeDmcQuestionnaire }) => {
    const [firstRender, setFirstRender] = useState(true)
    const startDmcId = useSelector(state => state.startDMC);
    let reduxDispatcher = useDispatch();

    const respondentDmcsInfo = useSelector(state => state.respondentsDmcs);

    let lastSavedDate = respondentDmcsInfo && respondentDmcsInfo.onGoingDMCLastUpdateDate ? new Date(respondentDmcsInfo.onGoingDMCLastUpdateDate) : null;
    let timezone = lastSavedDate?.toLocaleString('default', { timeZoneName: 'short' }).split(' ');
    let timeZoneName = timezone ? "(" + timezone[timezone.length - 1] + ")" : "";
    let lastSavedDateString = "Last saved on: " +
        lastSavedDate?.toLocaleString('default', { month: 'long' })
        + " " + lastSavedDate?.toLocaleString('default', { day: 'numeric' }) + getOrdinalNum(lastSavedDate?.toLocaleString('default', { day: 'numeric' }))
        + " " + lastSavedDate?.toLocaleString('default', { hour12: true, hour: '2-digit', minute: '2-digit' }).toLowerCase()
        + " " + timeZoneName;

    const resumeStaticContents = useSelector((state) => state.contents.data);

    setResumeStaticContents(resumeStaticContents)

    useEffect(() => {
        CONTENTS("ResumePage", reduxDispatcher);
        setFirstRender(false);

        GTMHelper.pushEvent("e_moneychat_vpv", {
            pageType: 'continue_assessment'
        });
    }, [])

    return <>

        <div id="welcome-page">
            <div className="components-wrapper">
                <div className="spacing resume">
                    <div className="dmc-info">
                        <div className="background-effect">
                            <div></div>
                        </div>
                        <div className="dmc-content">
                            <div className="title">{headerTitle}</div>
                            <div className="dmc-progress">
                                <CostumProgressbar type="circular" answersTaken={respondentDmcsInfo?.onGoingDMCNumberOfSteps} />
                            </div>
                            {lastSavedDate && <div className="date">{lastSavedDateString}</div>}
                        </div>
                    </div>
                    <div className="dmc-options">
                        <div>
                            <CostumButtom
                                fill={true}
                                text={continueAssessmentBtnText}
                                nextPage="questionnaire"
                                costumFunction={() => {
                                    GTMHelper.pushEvent("e_moneychat_resume_progress");

                                    RESUME_DMC(startDmcId, resumeDmcQuestionnaire, reduxDispatcher);
                                }}
                            />
                        </div>
                        <div>
                            <div className="link" onClick={(event) => startNewDmcOption(event, resumeDmcQuestionnaire, reduxDispatcher)}>{startAssessmentLink}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="components-wrapper">
                <Carousel quotes={resumeCarouselQuotes} title={carouselTitle} />
            </div>
        </div>
        {/* {!resumedDmcs && !firstRender &&
            <WelcomePage handleState={handleState} />
        } */}
    </>
}

export default ResumePage;