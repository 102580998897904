import React, { FC } from "react";
import { IExpandableItem } from "../../../../../Interfaces";
import ExpandableItem from "./ExpandableItem/ExpandableItem";

/* --- style --- */
import "./ExpandableItemList.scss";

interface IProps {
    expandableItems: IExpandableItem[];
    resultsSection?: string;
    showNotes?: boolean;
}

const ExpandableItemList: FC<IProps> = ({ expandableItems, resultsSection, showNotes }) => {
    return <div className="extendable-items-list">
        {expandableItems.map((expandableItem, index) => {
            return <ExpandableItem key={index} index={index.toString()} {...expandableItem} title={expandableItem.titles[0].value} resultsSection={resultsSection}>
                {expandableItem.items?.map((item, i) => {
                    return <ExpandableItem
                        key={i}
                        index={`${index}-${i}`}
                        {...item}
                        title={item.titles[0].value}
                        notes={item.notes}
                        showNotes={showNotes}
                        parentTitle={expandableItem.titles[0].value}
                        resultsSection={resultsSection}
                    />
                })}
            </ ExpandableItem>
        })}
    </div>;
}

export default ExpandableItemList;