import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import "./Summary.scss";

import { ISection, ISummaryResult } from "../../Interfaces";
import { GET_RESULTS_PAGE_DATA } from "../../../requests/api";
import Section from "./components/Section/Section";
import AssessmentButtonsList from "./components/AssessmentButtonsList/AssessmentButtonsList";
import AssessmentButton from "./components/AssessmentButtonsList/AssessmentButton/AssessmentButton";

/* --- mock bottom assessement buttons -- */
import { AssessmentButtonsSchema } from "../../../schemas/schemas";
import { connect, useDispatch } from "react-redux";
import GTMHelper from "@helpers/GTMHelper.js";
import SummaryAlert from "./components/SummaryAlert/SummaryAlert";
import Alert from "@components/Generic/Alert";
import ToggleSwitch from "@components/Generic/ToggleSwitch/ToggleSwitch";

import { setRespondentsDmcs, setErrorInfo } from '../../../redux/actions'

interface IProps {
  sections: ISection[];
  dmcId: string;
  respondentId: string;
  recommendationId: string;
  showAlert: boolean;
  startNewDmc?: any;
  withAlert?: any;
  style?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

interface DispatchProps {
  reduxDispatcher: any;
  setRequestLoading: (value: boolean) => void;
  setAlert: (value: any) => void;
  setError: (value: any) => void;
  setRespondentDmcs: (value: any) => void;
}

interface IState {
  data: any; // 'any' until a real service response
  showNotes: boolean;
}

class Summary extends React.Component<IProps & DispatchProps, IState> {

  constructor(props) {
    super(props);
    this.state = { data: { sections: [] }, showNotes: true };
  }

  /**
   * On mount, call service to retrieve results data
   */
  componentDidMount() {
    this.props.setRequestLoading(true);

    // Add event to gtm 'dataLayer'
    GTMHelper.pushEvent("e_moneychat_vpv", {
      pageType: this.props.withAlert ? "past_assessment_results" : "assessment_complete"
    });

    GET_RESULTS_PAGE_DATA(this.props.dmcId)
      .then((responseData) => {
        this.setState({
          data: responseData
        });

      })
      .catch(() => {
        this.props.setError({
          show: true,
          type: "fullPage",
          errorTitle: "Something went wrong",
          errorDescription: "Couldn't retrieve results info.",
          errorButton: null
        })
      })
      .finally(() => {
        this.props.setRequestLoading(false);
      });

    document.getElementById("scroll-top-summary")?.scrollIntoView();
  }

  render() {
    const isSalesforce = window.sessionStorage.getItem("CurrentChannel") === "Salesforce";

    return (
      <div id="welcome-page" style={this.props.style}>
        {//div to scroll to within dcu - CurrentChannel its equal to "dcu"
          window.sessionStorage.getItem("CurrentChannel") === "dcu" && <div id="scroll-top-summary"></div>
        }
        {isSalesforce && <div style={{ display: "flex", justifyContent: "flex-end", marginRight: '10px' }}>
          <ToggleSwitch
            title="Show notes"
            initialChecked={true}
            onToggle={(checked) => {
              this.setState({ showNotes: checked });
            }}
          />
        </div>
        }
        <div className={this.props.withAlert ? "components-wrapper with-alert" : "components-wrapper"}>
          {this.props.withAlert && <SummaryAlert {...this.props.withAlert} />}
          {this.state.data
            && this.state.data.sections
            && this.state.data.sections.map((section, index) => (
              <Section
                key={index}
                sectionId={section.sectionId}
                applyFill={section.applyFill}
                header={section.header}
                icon={section.icon}
                title={section.titles?.[0]?.value}
                description={section.descriptions?.[0]?.value}
                highlights={section.highlights}
                status={section.status}
                expandableItems={section.expandableItems}
                goals={section.goals}
                notice={section.contact}
                notes={this.state.showNotes ? section.notes : undefined}
                showNotes={this.state.showNotes}
                footer={section.footer}
              />
            ))}
          {this.props.showAlert && <Alert />}
          <AssessmentButtonsList>
            {AssessmentButtonsSchema.map((button, index) => {
              return <AssessmentButton
                key={index}
                nextPage={button.nextPage}
                title={button.titles[0].value}
                icon={button.icon ? button.icon : undefined}
                startNewDmc={this.props.startNewDmc}
              />
            })}
          </AssessmentButtonsList>
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => {
  return {
    reduxDispatcher: any => useDispatch,
    setRequestLoading: (value: boolean) => dispatch({ type: 'SET_REQUEST_LOADING', value: value }),
    setAlert: (value: any) => dispatch({ type: 'SET_HAS_ALERT', value: value }),
    setError: (value: any) => dispatch({ type: 'SET_ERROR_INFO', value: value }),
    setRespondentDmcs: (value: any) => dispatch({ type: 'SET_RESPONDENTSDMCS', value: value })
  }
}

const mapStateToProps = state => ({
  showAlert: state.alert.show,
  dmcId: state.startDMC,

  // TODO: Implement recommendationId in redux store, 
  // this id must be set after last step response
  recommendationId: state.startDMC
})

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
