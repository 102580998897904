import React, { FC } from 'react';

import CostumButton from "../../../../../CustomButton";
import NoticeButton from "./NoticeButton/NoticeButton";
import { INotice, INoticeButton } from "@components/Interfaces";
import "./Notice.scss";

interface IProps {
    border?: boolean,
    filled?: boolean,
    title?: string,
    subTitle?: string,
    description?: string,
    button?: INoticeButton,
    resultsSection?: string;
}

const Notice: FC<IProps> = ({ border, filled, title, subTitle, description, button, resultsSection }) => {
  
    return (
        <div className={
            border && filled ? "contact-container border filled" :
                border ? "contact-container border" :
                    filled ? "contact-container filled" : "contact-container"}
        >
            {title && <div className="contact-title">{title}</div>}
            {subTitle && <div className="contact-subTitle">{subTitle}</div>}
            {description && <div className="contact-description">{description}</div>}
            {button &&
                <NoticeButton
                    icon={button.icon}
                    text={button.text[0].value}
                    action={button.action}
                    resultsSection={resultsSection}
                />
            }
        </div>
    )
}

export default Notice;