function summary(state = { showSummary: false, summaryAlert: null }, action) {
  switch (action.type) {
    case "SET_SHOW_SUMMARY":
      return { showSummary: action.value, summaryAlert: state.summaryAlert }
    case "SET_SUMMARY_ALERT":
      return { showSummary: state.showSummary, summaryAlert: action.value }
    default:
      return state;
  }
}

export default summary;
