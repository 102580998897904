import { CUSTOMER_RESPONSE } from "../actions/actionTypes";

function customerQuestionnaireResponse(state = [], action) {
  switch (action.type) {
    case CUSTOMER_RESPONSE:
      return action.value;
    default:
      return state;
  }
}

export default customerQuestionnaireResponse;
