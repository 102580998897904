import React from "react";

const styles = {
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loadingBox: {
        width: "70px",
        height: "70px",
    },
};

const LocalLoading = () => {
    return (
        <div className="loadingContainer" style={styles.loadingContainer}>
            <div style={styles.loadingBox} className="loader"></div>
        </div>
    );
};

export default LocalLoading;
