import React from 'react';
import '../styles/highlight.scss';

const Highlight = ({ title, icon }) => (
    <div className="highlight">
        <div className="row">
            <div className="col-2 col-md-12 icon-container my-auto">
                <img src={icon.url} alt={icon.altText[0].value} />
            </div>
            <div className="col-10 col-md-12 highlight-title">
                <div className="d-inline">
                    {title[0].value}
                </div>
            </div>
        </div>
    </div>
)

export default Highlight;