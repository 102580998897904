import { SET_REQUEST_LOADING } from "../actions/actionTypes";

function requestLoading(state = false, action) {
  switch (action.type) {
    case SET_REQUEST_LOADING:
      return action.value;
    default:
      return state;
  }
}

export default requestLoading;
