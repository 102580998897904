import React from "react";
import "@styles/context.scss";



const Context = () => {
  var t24profileId = '';
  var givenName = '';
  var familyName = '';

  if (sessionStorage.getItem("customerData")) {
    var { t24profileId, givenName, familyName } = JSON.parse(
      sessionStorage.getItem("customerData")
    );
  }

  var unique_name = '';
  var given_name = '';
  var family_name = '';
  var AD_Group = '';
  var onprem_sid = '';

  if (sessionStorage.getItem("agentData")) {
    var { unique_name, given_name, family_name, AD_Group, onprem_sid } = JSON.parse(
      sessionStorage.getItem("agentData")
    );
  }

  return (
    <div className="Context">
      <h4>APP</h4>
      <div><b>Version: </b>{process.env.REACT_APP_Version}</div>
      <div><b>API URL: </b>{sessionStorage.getItem("ApiUrl")}</div>
      <div><b>Current Channel: </b>{sessionStorage.getItem("CurrentChannel")}</div>
      <div><b>Log Level: </b>{process.env.REACT_APP_LOG_LEVEL}</div>
      <br></br>
      <div><b>Customer t24profileId: </b>{t24profileId}</div>
      <div><b>Customer givenName: </b>{givenName}</div>
      <div><b>Customer familyName: </b>{familyName}</div>
      <br></br>
      <div><b>Agent unique_name: </b>{unique_name}</div>
      <div><b>Agent given_name: </b>{given_name}</div>
      <div><b>Agent family_name: </b>{family_name}</div>
      <div><b>Agent AD_Group: </b>{AD_Group}</div>
      <div><b>Agent onprem_sid: </b>{onprem_sid}</div>
      <br></br>
      <h4>SIMEIO</h4>
      <div><b>Bypass for Simulator: </b>{process.env.REACT_APP_BYPASS_AUTH}</div>
      <div><b>Enviroment: </b>{process.env.REACT_APP_DOMAIN}</div>
      <div><b>REDIRECT URI: </b>{process.env.REACT_APP_REDIRECT_URI}</div>
      <div><b>Send Headers: </b>{process.env.REACT_APP_API_SENDAUTHHEADER_Simeio}</div>
      <br></br>
      <h4>AZURE AD</h4>
      <div><b>REDIRECT URI: </b>{process.env.REACT_APP_AZ_REDIRECT_URI}</div>
      <div><b>Scope: </b>{process.env.REACT_APP_AZ_AUTHORIZE_SCOPE}</div>
      <div><b>Token: </b>{process.env.REACT_APP_AZ_TOKEN_SCOPE_API}</div>
      <div><b>Send Headers: </b>{process.env.REACT_APP_API_SENDAUTHHEADER_Simeio}</div>
      <div><span></span></div>
    </div>
  );
};

export default Context;
