import React, { useState, useEffect } from 'react';
import Selector from "../../../components/Generic/Selector";
import { GET_LAST_QUESTIONS } from "../../../requests/api";
import { useDispatch, useSelector } from "react-redux"

import LikertScale from '../../Likert';
import Icon from '../../../components/Generic/Icon';

import '../../../styles/modal.scss';

import { setGoalAnswers, addGoalSubQuestionAnswer } from "../../../redux/actions";

var goalCurrentStep = null;
let lastquestions = null;

var alreadySelected = [];

//helper functions
const getOptionsList = (optionsArray, questionID, goalsInserted, editingGoal) => {
    //answer.subQuestions[0].answerOptions
    let optionsList = [];
    let hasSubAnswers = false;
    let notInserted = false;
    let itemsWithSubAnswers = null;

    if (goalsInserted && goalsInserted.length > 0) {
        goalsInserted.map(goal => {
            if (goal.answers.some(e => e.stepId == questionID)) {
                let answer = goal.answers.find(e => e.stepId === questionID)
                notInserted = false;

                //if goal answers doesnt have sub answers
                if (!answer.answers.some(e => e.subAnswers !== null && e.subAnswers.length > 0)) {
                    hasSubAnswers = true;

                    if (optionsArray.some(e => e.label[0].value === answer.answers[0].value)) {
                        optionsArray.map(option => {
                            //Add all the options to OptionsList
                            if (!optionsList.some(x => x.text === option.label[0].value))
                                optionsList.push({
                                    value: option.id,
                                    text: option.label[0].value
                                })

                            //User is inserting a new goal
                            if (!editingGoal) {
                                //Validate if answers[] contains the option
                                //Save option to Already Selected Optios []
                                if (option.label[0].value === answer.answers[0].value) {
                                    if (!alreadySelected.some(o => o === option.label[0].value))
                                        alreadySelected.push(option.label[0].value)
                                }
                            }
                            //User is editing a goal
                            else {
                                if (goal.id === editingGoal) {
                                    if (alreadySelected.some(o => o === goal.subject)) {
                                        let index = alreadySelected.findIndex(o => o === goal.subject)
                                        alreadySelected.splice(index, 1)
                                    }
                                }
                                else {
                                    if (option.label[0].value === answer.answers[0].value) {
                                        if (!alreadySelected.some(o => o === option.label[0].value))
                                            alreadySelected.push(option.label[0].value)
                                    }
                                }
                            }
                        })
                    }
                    else {
                        // if goal answers doesn't have subanswers
                        // and goals answers don't have any of optionsArray's options                                               
                        optionsArray.map(option => {
                            if (!optionsList.some(e => e.text === option.label[0].value)) {
                                optionsList.push({
                                    value: option.id,
                                    text: option.label[0].value
                                })
                            }
                        })
                        itemsWithSubAnswers = optionsList
                    }
                }
                //if goal answers has sub answers
                else {
                    if (answer.answers[0].subAnswers) {
                        optionsList = [];

                        if (optionsArray.some(e => e.label[0].value === answer.answers[0].value)) {
                            let optionFound = optionsArray.find(e => e.label[0].value === answer.answers[0].value);
                            let subAnswers = optionFound.subQuestions?.[0].answerOptions;

                            if (subAnswers) {
                                if (subAnswers.some(e => e.label[0].value === answer.answers[0].subAnswers[0].answers[0].value)) {
                                    hasSubAnswers = true;
                                    let answerValue = answer.answers[0].subAnswers[0].answers[0].value;

                                    //User is inserting a new goal
                                    if (!editingGoal) {
                                        //Validate if answers[] contains the option
                                        //Save option to Already Selected Options []
                                        if (!alreadySelected.some(o => o === answerValue))
                                            alreadySelected.push(answerValue)
                                    }
                                    //User is editing a goal
                                    else {
                                        if (goal.id === editingGoal) {
                                            if (alreadySelected.some(o => o === goal.subject)) {
                                                let index = alreadySelected.findIndex(o => o === goal.subject)
                                                alreadySelected.splice(index, 1)
                                            }
                                        }
                                        else {
                                            if (!alreadySelected.some(o => o === answerValue))
                                                alreadySelected.push(answerValue)
                                        }
                                    }

                                    optionsArray.map(option => {
                                        //Add all the options to OptionsList
                                        if (!optionsList.some(x => x.text === option.label[0].value))
                                            optionsList.push({
                                                value: option.id,
                                                text: option.label[0].value
                                            })
                                    })

                                    //check if subAnswers are all already selected
                                    //if so remove respective answer from the previous dropdown
                                    let subQuestions = [];
                                    subAnswers.map(subOption => {
                                        if (!subQuestions.some(o => o === subOption.label[0].value)) subQuestions.push(subOption.label[0].value)
                                    })

                                    if (alreadySelected.length >= subQuestions.length && alreadySelected.every(opt => subQuestions.includes(opt))) {
                                        if (!alreadySelected.some(x => x === answer.answers[0].value)) alreadySelected.push(answer.answers[0].value)
                                    }

                                    if (editingGoal) {
                                        if (goal.id === editingGoal) {
                                            if (alreadySelected.some(o => o === answer.answers[0].value)) {
                                                let index = alreadySelected.findIndex(o => o === answer.answers[0].value)
                                                alreadySelected.splice(index, 1)
                                            }
                                        }
                                    }

                                    itemsWithSubAnswers = optionsList;
                                }

                            }
                        }
                        else {
                            if (itemsWithSubAnswers) {
                                optionsList = itemsWithSubAnswers;
                            }
                            else {
                                optionsArray.map(option => {
                                    if (!optionsList.some(e => e.text === option.label[0].value)) {
                                        optionsList.push({
                                            value: option.id,
                                            text: option.label[0].value
                                        })
                                    }
                                })
                            }
                        }
                    }
                    else {
                        return false;
                    }
                }
            }
            else {
                notInserted = true;
            }
        })

        if (notInserted && !hasSubAnswers) {
            optionsArray.map(option => {
                if (!optionsList.some(e => e.text === option.label[0].value)) {
                    optionsList.push({
                        value: option.id,
                        text: option.label[0].value
                    })
                }
            })
        }
    }
    else {
        optionsArray.map(option => {
            optionsList.push({
                value: option.id,
                text: option.label[0].value
            });
        })
        alreadySelected = [];
    }

    let optionsAvailable = optionsList;

    //Compare Options list received to Already selected options
    //Remove selected options from dropdown     
    if (goalsInserted && alreadySelected.length > 0) {
        optionsAvailable = optionsList.filter(option => !alreadySelected.includes(option.text))
    }
    if (!goalsInserted && editingGoal) {
        optionsAvailable = optionsList.filter(option => !alreadySelected.includes(option.text))
    }

    return optionsAvailable;
}

const saveGoalsAnswer = (event, stepId, subStepId, isSubStep, reduxGoalAnswers, reduxDispatcher) => {
    let answerSchema = {
        stepId: stepId,
        answers: [
            {
                id: "",
                value: "",
                subAnswers: null
            }
        ]
    };

    let eventId = subStepId ? subStepId.toString() : subStepId;
    let eventValue = event.target.value;

    if (event.target.classList.contains("visuallyHidden")) {
        eventId = event.target.value;
        eventValue = event.target.nextElementSibling.nextElementSibling.textContent;
    }

    if (reduxGoalAnswers != null && reduxGoalAnswers.length > 0) {
        let newAnswer = null;

        reduxGoalAnswers.map((answer, index) => {
            if (answer.stepId == stepId) {
                newAnswer = index
            }
        });

        //if answer already exists on the store
        if (newAnswer !== null) {
            let aux = reduxGoalAnswers;

            if (!isSubStep) {
                aux[newAnswer].answers[0].id = eventId != null ? eventId : null;
                aux[newAnswer].answers[0].value = eventValue != null ? eventValue : null;
            }
            else {
                aux[newAnswer].answers[0].subAnswers = [{
                    id: stepId.toString(),
                    answers: [{
                        id: subStepId.toString(),
                        value: event.target.value
                    }]
                }]
            }

            reduxDispatcher(setGoalAnswers(aux));
        }
        //if answer doesnt exist on the state
        else {
            if (isSubStep) {
                answerSchema.answers[0].subAnswers = [{
                    id: stepId.toString(),
                    answers: [{
                        id: subStepId.toString(),
                        value: event.target.value
                    }]
                }]
            }
            else {
                answerSchema.answers[0].id = eventId != null ? eventId : null;
                answerSchema.answers[0].value = eventValue != null ? eventValue : null;
            }

            reduxDispatcher(addGoalSubQuestionAnswer(answerSchema));
        }
    }
    //if state is empty
    else {
        if (isSubStep) {
            answerSchema.answers[0].id = isSubStep.id ? isSubStep.id.toString() : "";
            answerSchema.answers[0].value = isSubStep.label[0].value ? isSubStep.label[0].value : "";

            answerSchema.answers[0].subAnswers = [{
                id: stepId.toString(),
                answers: [{
                    id: subStepId.toString(),
                    value: event.target.value
                }]
            }]
        }
        else {
            answerSchema.answers[0].id = eventId != null ? eventId : null;
            answerSchema.answers[0].value = eventValue != null ? eventValue : null;
        }

        reduxDispatcher(addGoalSubQuestionAnswer(answerSchema));
    }
}

const HandleSelectorChange = (event, setFirstSelector, setFirstSelectorID, stepId, answerId, reduxGoalAnswers, reduxDispatcher) => {
    for (let i = 0; i < goalCurrentStep.answerOptions.length; i++) {
        let answer = goalCurrentStep.answerOptions[i];

        if (answer.label[0].value === event.target.value) {
            saveGoalsAnswer(event, stepId, answerId, null, reduxGoalAnswers, reduxDispatcher)
            setFirstSelector(event.target.value);
            setFirstSelectorID(event.target.id);
        }
    }
}

async function get2lastQuestions(dmcID, fireststepId, secondstepId, setLastquestions, currentStepId, setGoToLastQuestions, setLastDropDown, reduxDispatcher) {
    const endpoint1 = "/v1/dmcs/" + dmcID + "/step/" + fireststepId;
    const endpoint2 = "/v1/dmcs/" + dmcID + "/step/" + secondstepId;

    let dataFirst = null;
    let dataSecond = null;

    if (currentStepId !== fireststepId) {
        dataFirst = await GET_LAST_QUESTIONS(endpoint1, reduxDispatcher).then((response) => response?.data);
    }
    else {
        setGoToLastQuestions(true);
        setLastDropDown(true);
    }
    if (currentStepId !== secondstepId) {
        dataSecond = await GET_LAST_QUESTIONS(endpoint2, reduxDispatcher).then((response) => response.data);
    }

    lastquestions = {
        dataFirst, dataSecond
    }

    setLastquestions(lastquestions);
}

const DropDownList = ({ currentStep, selectedItems, saveAnswerToStore, setNextButtonToEnabled, changeGoalTopic, editingGoal }) => {
    //value from first dropdown
    const [firstSelector, setFirstSelector] = useState();
    const [firstSelectorID, setFirstSelectorID] = useState();
    //value from second dropdown
    const [secondSelector, setSecondSelector] = useState();
    const [lastDropDown, setLastDropDown] = useState(false);
    const [lastquestions, setLastquestions] = useState();
    const [goToLastQuestions, setGoToLastQuestions] = useState(false);

    const [answers, setAnswers] = useState(null)

    const dmcID = useSelector(state => state.startDMC);
    const stepId = useSelector(state => state.goals.subStepQuestions[state.goals.subStepQuestions.length - 1]?.stepId)
    const currentGoals = useSelector(state => state.goals.currentGoals)
    const reduxGoalAnswers = useSelector(state => state.goals.subStepAnswers)
    const goalSubStepInfo = useSelector(state => state.goals.substepInfo)
    const reduxDispatcher = useDispatch();

    goalCurrentStep = currentStep;

    //functions
    const CheckSubQuestions = (event, currentStep) => {
        let i = false;

        if (currentStep.answerOptions) {
            currentStep.answerOptions.map(answer => {
                if (answer.label[0].value == event.target.value) {
                    if (answer.subQuestions) {
                        setGoToLastQuestions(false);
                        setLastDropDown(false);
                        i = true;
                        setNextButtonToEnabled(false);
                        setLastquestions(null)
                        setAnswers(null)
                    }
                }
            })
        }

        if (i === false) {
            setGoToLastQuestions(true);
            if (!lastquestions) {
                get2lastQuestions(dmcID,
                    25,
                    26,
                    setLastquestions,
                    currentStep.stepId,
                    setGoToLastQuestions,
                    setLastDropDown, reduxDispatcher);
            }
        }
    }

    const checkSeletorResponseId = (event) => {
        let options = event.target.querySelectorAll('option')
        let answerId = null

        for (let i = 0; i < options.length; i++) {
            if (options[i].value === event.target.value) {
                answerId = options[i].id;
            }
        }
        return answerId;
    }

    const validateSelectedItems = () => {
        let answers = {
            title: "",
            firstSelector: "",
            secondSelector: "",
            time: "",
            confidence: "",
        }

        if (selectedItems) {
            selectedItems.map((ans, index) => {
                switch (ans.stepId) {
                    case 17:
                        answers.title = ans.answers[0].value
                        break;
                    case 25:
                        answers.time = ans.answers[0].value
                        break;
                    case 26:
                        answers.confidence = ans.answers[0].id ? ans.answers[0].id : ans.answers[0].optionId ? ans.answers[0].optionId : ""
                        break;
                    default:
                        answers.firstSelector = ans.answers[0].value
                        setFirstSelector(ans.answers[0].value)
                        setFirstSelectorID(ans.answers[0].id)

                        if (ans.answers[0].subAnswers && ans.answers[0].subAnswers.length > 0) {
                            answers.secondSelector = ans.answers[0].subAnswers[0].answers[0].value
                        }
                        break;
                }

                //has only the goal topic and 2 last questions
                if (selectedItems.length === 3 && index === selectedItems.length - 2) {
                    answers.firstSelector = ans.answers[0].value
                    setFirstSelector(ans.answers[0].value)
                    setFirstSelectorID(ans.answers[0].id)
                }
            });

            setGoToLastQuestions(true);
            setLastDropDown(true);

            if (!lastquestions) {
                get2lastQuestions(dmcID,
                    25,
                    26,
                    setLastquestions,
                    currentStep.stepId,
                    setGoToLastQuestions,
                    setLastDropDown,
                    reduxDispatcher);
            }
            setAnswers(answers);
            setNextButtonToEnabled(true);
        }
    }

    const handleGoalLikert = (event) => {
        saveGoalsAnswer(event, lastquestions.dataSecond.stepId, stepId, null, reduxGoalAnswers, reduxDispatcher);
        setNextButtonToEnabled(true);
    }

    useEffect(() => {
        validateSelectedItems();
    }, [])

    useEffect(() => {
        if (alreadySelected.length > 0) {
            if (currentGoals?.length > 0) {
                alreadySelected.map((option, index) => {
                    if (!currentGoals?.some(goal => goal.subject === option))
                        alreadySelected.splice(index, 1);
                })
            }
            else {
                alreadySelected = [];
            }
        }
    }, [])

    return (
        <div className="goal-selector"
            onChange={(event) => {
                let answerId = checkSeletorResponseId(event)

                HandleSelectorChange(event, setFirstSelector, setFirstSelectorID, stepId, answerId, reduxGoalAnswers, reduxDispatcher)
                CheckSubQuestions(event, goalCurrentStep)
            }}>
            <div className="my-goal-selected">
                <div className="goal-icon">{goalSubStepInfo && <Icon imageUrl={goalSubStepInfo.icon} />}</div>
                <div className="goal-title">{goalSubStepInfo.title}</div>
                <div className="change-goal-btn" tabIndex={0} role="button"
                    onClick={() => {
                        setFirstSelector(null);
                        setGoToLastQuestions(false);
                        setLastquestions(null)
                        changeGoalTopic()
                    }}
                    onKeyDown={(event) => { if (event.key === "Enter") changeGoalTopic() }}
                >Change</div>
            </div>
            <div className="small-title">{goalCurrentStep.subTitles && goalCurrentStep.subTitles[0]?.value ? goalCurrentStep.subTitles[0].value : goalCurrentStep.titles[0].value}</div>
            <Selector
                accessibilityText={goalCurrentStep.subTitles ? goalCurrentStep.subTitles[0]?.value : goalCurrentStep.titles[0].value}
                optionsList={getOptionsList(goalCurrentStep.answerOptions, goalCurrentStep.stepId, goalCurrentStep.stepId !== 25 ? currentGoals : null, editingGoal)}
                selectedItem={answers ? answers.firstSelector : ""}
            />

            {firstSelector &&
                goalCurrentStep.answerOptions.map(answer => {
                    if (answer.label[0].value === firstSelector && answer.subQuestions) {
                        return (
                            <div className="sub-selector"
                                onChange={event => {
                                    setSecondSelector(event.target.value)

                                    let answerId = checkSeletorResponseId(event);

                                    if (!lastquestions) { get2lastQuestions(dmcID, 25, 26, setLastquestions, currentStep.stepId, setGoToLastQuestions, setLastDropDown, reduxDispatcher) }
                                    saveGoalsAnswer(event, stepId, answerId, answer, reduxGoalAnswers, reduxDispatcher)
                                }}>
                                <div className="small-title">{answer.subQuestions[0]?.titles[0]?.value}</div>
                                <Selector
                                    accessibilityText={answer.subQuestions[0]?.titles[0]?.value ? answer.subQuestions[0]?.titles[0]?.value : ""}
                                    optionsList={getOptionsList(answer.subQuestions[0].answerOptions, goalCurrentStep.stepId, currentGoals, editingGoal)}
                                    selectedItem={answers ? answers.secondSelector : ""}
                                />
                            </div>
                        )
                    }
                })
            }
            {goToLastQuestions && lastquestions != null &&
                <div>
                    {lastquestions.dataFirst &&
                        <div onChange={(event) => {
                            setLastDropDown(true);
                            let answerId = checkSeletorResponseId(event);
                            saveGoalsAnswer(event, lastquestions.dataFirst.stepId, answerId, null, reduxGoalAnswers, reduxDispatcher)
                        }}>
                            <div className="small-title">{lastquestions.dataFirst.titles[0].value}</div>
                            <Selector
                                accessibilityText={lastquestions.dataFirst.titles[0].value ? lastquestions.dataFirst.titles[0].value : ""}
                                optionsList={getOptionsList(lastquestions.dataFirst.answerOptions, goalCurrentStep.stepId, null, editingGoal)}
                                selectedItem={answers ? answers.time : ""}
                            />
                        </div>
                    }
                    {lastquestions.dataSecond && lastDropDown &&
                        <div onChange={(event) => {
                            let answerId = checkSeletorResponseId(event);
                            saveGoalsAnswer(event, lastquestions.dataSecond.stepId, answerId, null, reduxGoalAnswers, reduxDispatcher);
                            //registGoal(reduxDispatcher)
                            setNextButtonToEnabled(true)
                            //reduxDispatcher(addNewGoal({ goalsAnswer }));
                        }}>
                            <div className="small-title">{lastquestions.dataSecond.titles[0].value}</div>
                            <LikertScale {...lastquestions.dataSecond} titles={""} handleState={() => { }} currentValue={answers ? answers.confidence : ""} onSelect={() => { }} handleStateModal={handleGoalLikert} />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default DropDownList;