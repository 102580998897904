import * as React from 'react';
import CostumButton from '../../../components/CustomButton'

//styles
import "./ErrorPage.scss"
import { useDispatch } from 'react-redux';
import { setErrorInfo, setShowHistory } from '../../../redux/actions';

interface IProps {
    icon?: string
    title?: string,
    description?: string,
    button?: string,
    link?: string
}

function goBackPreviousPage(page: (string | undefined), reduxDispatcher: any) {
    if (page) {
        if (page.toLowerCase() === "history") {
            //reset Error redux data
            reduxDispatcher(setErrorInfo({
                show: false,
                type: "",
                errorTitle: "",
                errorDescription: "",
                errorButton: ""
            }))

            //show history page on click
            reduxDispatcher(setShowHistory(true))
        }
    }
}

const ErrorPage: React.FC<IProps> = (props) => {
    const reduxDispatcher = useDispatch();

    return <div className="components-wrapper error-container">
        <div className="spacing">
            <div className="error-page">
                <div className="error-container">
                    {props.icon && <img src={props.icon} alt="Alert Icon" />}
                    {props.title && <div className="error-title">{props.title}</div>}
                    {props.description &&
                        <div className="error-description">
                            {props.description}
                            {props.link &&
                                <div>
                                    <a target="_blank" href={props.link}>{props.link}</a>
                                </div>
                            }
                        </div>}
                    {props.button &&
                        <CostumButton
                            text={"Ok"}
                            disabled={false}
                            type="retry"
                            nextPage="retry"
                            handleStepState={() => { console.log("Retry call method ...") }}
                            costumFunction={() => { goBackPreviousPage(props.button, reduxDispatcher) }}
                        />
                    }
                </div>
            </div>
        </div>
    </div>
}

export default ErrorPage;