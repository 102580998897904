import React, { useState, useEffect } from "react";

import Header from "../components/Header";
import Progressbar from "../components/Progressbar";
import LikertScale from "../components/Likert";
import RadioButton from "../components/Custom/RadioButton";
import RadioButtonList from "../components/RadioButtonList";
import TextInput from "../components/TextInput";
import CostumButton from "../components/CustomButton";
import Goals from "../components/containers/Goals/Goals";
import DropDownList from "../components/containers/DropDown/DropDownList";
import Icon from "../components/Generic/Icon";
import Label from "../components/Generic/Label";

import "../styles/questionaire.scss";

import { useDispatch, useSelector } from "react-redux";
import { setAnswerState, setNewAnswer, setContextualNote, setRespondentsDmcs, setErrorInfo } from "../redux/actions";

import { START_DMC, RESPONDENT_DMCS } from "../requests/api";
import QuestionnaireNote from "@components/Questionnaire/QuestionnaireNote/QuestionnaireNote";
//import Logger, { LogLevel } from "../helpers/Logger";

import MessageModal from '@components/Generic/MessageModal/MessageModal';
import { ReactComponent as ErrorIcon } from "@assets/svg/error.svg";

const QuestionairePage = ({ handleReturnWelcome }) => {
  const [completed, setCompleted] = useState(false); // State of next button
  const [withSubAnswers, setWithSubAnswers] = useState(false);
  const [savedAnswer, setSaveAnswer] = useState(false); // response saved bottom notification

  // this isn't good code but it was the only way I
  // managed to fix the problem for not allowing to 
  // select options between different components.
  const [updateRange, setUpdateRange] = useState(false);
  const [updateRadio, setUpdateRadio] = useState(false);

  // Redux stored data
  const reduxDispatcher = useDispatch();
  const respondentId = useSelector((state) => state.respondents.data);
  const respondentsDmcs = useSelector(state => state.respondentsDmcs)
  const reduxStoreAnswers = useSelector((state) => state.questionnaireWording.answers);
  const steps = useSelector((state) => state.questionnaireFlow.steps).length;
  const currentStep = useSelector((state) => state.questionnaireFlow.currentStep); // Data to fill the current step
  let currentStepId = currentStep?.stepId;
  const isRequestLoading = useSelector((state) => state.requestLoading);
  const isLastStep = useSelector((state) => state.questionnaireWording.isLastStep);
  const resumedDmc = useSelector(state => state.resumedDmc);
  const error = useSelector(state => state.error.show)
  const goalSubQuestions = useSelector(state => state.goals.subStepQuestions)

  // Show if responses were saved. Sets disabled to button NEXT
  const resetState = () => {
    setCompleted(false);
    setSaveAnswer(true);

    let options = document.querySelectorAll(".radioButtonList input[type='radio']");
    if (options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        options[i].checked = false;
      }
    }
  };

  // Show if responses were saved. Sets disabled to button NEXT
  const backResetState = () => {
    setCompleted(false);

    if (error) setSaveAnswer(true)

    let options = document.querySelectorAll(".radioButtonList input[type='radio']");
    if (options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        options[i].checked = false;
      }
    }
  };

  //info next step disable next button
  const infoResetStep = () => {
    setCompleted(false);
  }
  // Read answers stored in redux. Checks if there's answers and fill the responses already made
  const dynamicRender = () => {
    let withAnswer = null;

    //when the user goes back to a page, get the answers given if they exist
    reduxStoreAnswers.map((answer) => {
      if (answer.stepId === currentStepId) {
        //set goals after resume        
        if (answer.answers?.length > 0 && currentStep?.controlType === "GoalSelector") {
          let goalSubSteps = goalSubQuestions;

          withAnswer = {
            goals: answer.answers,
            goalsSubSteps: goalSubSteps && goalSubSteps.length > 0 ? goalSubSteps : null
          }
        }
        else {
          withAnswer = {
            selectedId: answer.answers[0].id ? answer.answers[0].id : answer.answers[0].optionId ? answer.answers[0].optionId : null,
            selectedValue: answer.answers[0].value,
            selectedSubAnswers: answer.answers[0].subAnswers ? answer.answers[0].subAnswers[0] : null
          };
        }

        return true;
      }
    });

    if (withAnswer && !completed && !withSubAnswers) {
      if (withAnswer.selectedValue) {
        setCompleted(true);
      }
    }

    // Sets what type of elements/components should be displayed based on what's stored in redux currentStep
    switch (currentStep.controlType) {
      case "RadioButtonList":
        return (
          <RadioButton
            {...currentStep}
            key={currentStep.stepId}
            handleState={saveAnswerStore}
            selectedItem={withAnswer && completed ? withAnswer : {}}
            setCompleted={setCompleted}
          />
        );
      case "Custom":
        // Get the list of used controls
        let controls = [];
        currentStep.answerOptions.forEach((option) => {
          if (!controls.includes(option.controlItemType)) {
            controls.push(option.controlItemType);
          }
        });

        // Iterate controls and render them
        let controlElements = [];
        let step = { ...currentStep };
        controls.forEach((controlTypeName, index) => {
          // Only show title and description at first control
          step =
            index === 0 ? { ...currentStep } : { ...currentStep, titles: [], descriptions: [] };

          // Render according to the controlTypeName
          switch (controlTypeName) {
            case "RangeItem":
              controlElements.push(
                <LikertScale
                  key={index.toString() + currentStep.stepId + updateRange}
                  {...step}
                  handleState={saveAnswerStore}
                  currentValue={withAnswer ? withAnswer.selectedId : null}
                  onSelect={(selectedOption) => { setUpdateRadio(!updateRadio); }}
                />
              );
              break;
            case "RadioButtonListItem":
              controlElements.push(
                <RadioButton
                  key={index.toString() + currentStep.stepId + updateRadio}
                  {...step}
                  handleState={saveAnswerStore}
                  selectedItem={withAnswer && completed ? withAnswer : {}}
                  onSelect={(selectedOption) => { setUpdateRange(!updateRange); }}
                  setCompleted={setCompleted}
                />
              );
              break;
            default:
              //Logger.Log("Unknown control type: " + controlTypeName, LogLevel.ERROR);
              break;
          }
        });
        return <div className="likert-radio">{controlElements}</div>;

      case "IconRadioButtonList":
        return (
          <RadioButtonList
            key={currentStep.stepId}
            type="row"
            //options={schemaLifeStage}
            options={currentStep}
            handleState={saveAnswerStore}
            resetState={setWithSubAnswers}
            setCompleted={setCompleted}
            selectedItem={withAnswer && completed ? withAnswer : {}}
          />
        );

      case "Range":
        return (
          <LikertScale
            {...currentStep}
            handleState={saveAnswerStore}
            currentValue={withAnswer ? withAnswer.selectedId : null}
            key={currentStep.stepId}
          />
        );

      case "TextBox":
        return (
          <TextInput
            {...currentStep}
            handleState={saveAnswerStore}
            writtenText={withAnswer ? withAnswer.selectedValue : ""}
          />
        );
      case "GoalSelector":
        return (
          <Goals
            currentStep={currentStep}
            saveAnswerToStore={setCompleted /*saveAnswerStore*/}
            selectedGoals={withAnswer ? withAnswer : {}}
            resetState={resetState}
          //setNextButtonToEnabled={setCompleted}
          />
        );
      case "DropDownList":
        return (
          <DropDownList
            currentStep={currentStep}
            //saveAnswerToStore={saveAnswerStore}
            setNextButtonToEnabled={setCompleted}
          //selectedItem={withAnswer ? withAnswer : {}}
          />
        );
      default:
        break;
    }
  };

  // Gets and Sets the type of object and saves to redux store
  const saveAnswerStore = (answerType, answerId, answerValue) => {
    let answerSchema = {
      stepId: currentStepId,
      type: "",
      answers: [
        {
          id: "",
          value: "",
          subAnswers: null
        },
      ],
      // startDate: 12323423421432,
      // endDate: 12323423421432,
    };

    if (reduxStoreAnswers != null && reduxStoreAnswers.length > 0) {
      let newAnswer = null;

      reduxStoreAnswers.map((answer, index) => {
        if (answer.stepId === currentStepId) {
          newAnswer = index;
          return;
        }
      });

      //if answer already exists on the store
      if (newAnswer != null) {
        let aux = reduxStoreAnswers;
        let othersId = aux[newAnswer].answers[0].id

        aux[newAnswer].answers[0].id = answerId != null ? answerId : null;

        if (answerId && answerId.toString().indexOf(".") > -1) {
          aux[newAnswer].answers[0].id = answerId.split(".")[0];
          aux[newAnswer].answers[0].subAnswers = [{
            id: answerId.split(".")[1],
            value: answerValue
          }]
        }
        else if (answerId && answerId.toString().indexOf("other") > -1) {
          aux[newAnswer].answers[0].id = othersId
          aux[newAnswer].answers[0].value = answerValue != null ? answerValue : null;
        }
        else {
          aux[newAnswer].answers[0].value = answerValue != null ? answerValue : null;
          aux[newAnswer].answers[0].subAnswers = null;
        }

        //setQuestionaireAnswers(aux);
        reduxDispatcher(setAnswerState(aux));
      }
      //if answer doesnt exist on the state
      else {
        answerSchema.answers[0].id = answerId != null ? answerId : null;
        answerSchema.type = answerType;

        if (answerId && answerId.toString().indexOf(".") > -1) {
          answerSchema.answers[0].id = answerId.split(".")[0];
          answerSchema.answers[0].subAnswers = [{
            id: answerId.split(".")[1],
            value: answerValue
          }]
        }
        else {
          answerSchema.answers[0].value = answerValue != null ? answerValue : null;
          answerSchema.answers[0].subAnswers = null
        }

        //setQuestionaireAnswers(prevState => [...prevState, answerSchema])
        reduxDispatcher(setNewAnswer(answerSchema));
      }
    }
    //if state is empty
    else {
      answerSchema.answers[0].id = answerId != null ? answerId : null;
      answerSchema.type = answerType;

      if (answerId && answerId.toString().indexOf(".") > -1) {
        answerSchema.answers[0].id = answerId.split(".")[0];
        answerSchema.answers[0].subAnswers = [{
          id: answerId.split(".")[1],
          value: answerValue
        }]
      }
      else {
        answerSchema.answers[0].value = answerValue != null ? answerValue : null;
        answerSchema.answers[0].subAnswers = null
      }

      reduxDispatcher(setNewAnswer(answerSchema));
    }

    if (!withSubAnswers) {
      if (answerValue === "") {
        setCompleted(false)
      }
      else {
        setCompleted(true);
      }
    }
  };

  // Manage back on the first question of the dmc
  function manageFirstStepBack() {
    reduxDispatcher(setRespondentsDmcs(null))
    RESPONDENT_DMCS(respondentId, reduxDispatcher)
    handleReturnWelcome();
  }

  //get questionaire content from endpoint
  useEffect(() => {
    if (!error && respondentId && !resumedDmc.dmc && !resumedDmc.isStaffDmc) {
      START_DMC(respondentId, reduxDispatcher);
    }
  }, [respondentId]);

  // force page to scroll up when changing questionnaire step
  // to avoid scrolled down pages
  useEffect(() => {
    if (document.getElementById("scroll-top-questionnaire")) {
      document.getElementById("scroll-top-questionnaire").scrollIntoView(true, { behavior: "smooth" })
    }
    else {
      document.getElementById("dmcWidget").scrollIntoView(true, { behavior: "smooth" })
    }

    document.activeElement.blur()
  }, [currentStep])

  // It fades response saved bottom notification
  if (savedAnswer) {
    setTimeout(() => setSaveAnswer(false), 2000);
  }

  const isSalesforce = window.sessionStorage.getItem("CurrentChannel") === "Salesforce";

  const [ShowMessageModal, SetShowMessageModal] = React.useState(true);

  // error message \\
  const errorInfo = useSelector(state => state.error);

  useEffect(() => {
    if (errorInfo && errorInfo.type === "smallError") {
      //show error message
      setSaveAnswer(true)
    }
  }, [errorInfo])

  if (currentStep != null) {
    return (
      <>
        <div
          id="questionnaire-page"
          className={isRequestLoading ? `components-wrapper loadingEffect` : `components-wrapper`}
        >
          {isRequestLoading && <div id="loaderShadow"></div>}
          {//div to scroll to within dcu - CurrentChannel its equal to "dcu"
            window.sessionStorage.getItem("CurrentChannel") === "dcu" && <div id="scroll-top-questionnaire"></div>
          }
          {currentStep.stepType?.toLowerCase() == "question" && (
            <div className="spacing questionnaire">
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <Progressbar
                  type="Linear"
                  answersTaken={steps}
                  totalAnswers={process.env.REACT_APP_TOTAL_ANSWERS}
                />
                <div className="questions-container">{
                  dynamicRender()}
                </div>

              </form>
              <div className="bottom-container">
                <div className={savedAnswer ? "submition-message" : "submition-message hide"} aria-hidden={!savedAnswer ? true : undefined}>
                  {errorInfo &&
                    errorInfo.show &&
                    errorInfo.type == "smallError"
                    ?
                    <MessageModal
                      show={true}
                      hideCloseButton={true}
                      title={currentStep.section.name}
                    >
                      <div className="error-message-container">
                        <div></div>
                        <div className="error-message">
                          <ErrorIcon />
                          <strong style={{ marginBottom: 10 }}>
                            {errorInfo.errorTitle}
                          </strong>
                          <p>{errorInfo.errorDescription}</p>
                        </div>
                        <button
                          className="dmc-button"
                          onClick={() => {
                            //SetShowMessageModal(false);
                            reduxDispatcher(setErrorInfo({
                              show: false
                            }));
                          }}>
                          Ok
                          </button>
                      </div>
                    </MessageModal> : "Responses saved"
                  }
                </div>
                <div className="buttons-row">
                  <CostumButton
                    text="Back"
                    fill={false}
                    handleStepState={backResetState}
                    nextPage={currentStep.stepId == 1 ? "welcomePage" : null}
                    costumFunction={currentStep.stepId == 1 ? manageFirstStepBack : null}
                  />
                  <CostumButton
                    text={isLastStep ? "Finish" : "Next"}
                    fill={true}
                    disabled={!completed}
                    type="submit"
                    handleStepState={resetState}
                    isLastStep={isLastStep}
                    contextualNote={currentStep.note}
                  />
                  {/* button to display only on mobile */}
                  <CostumButton
                    text="Back"
                    fill={false}
                    handleStepState={backResetState}
                    nextPage={currentStep.stepId == 1 ? "welcomePage" : null}
                    costumFunction={currentStep.stepId == 1 ? manageFirstStepBack : null}
                  />
                </div>
              </div>
            </div>
          )}
          {currentStep.stepType?.toLowerCase() === "info" && (
            <div id="info-page">
              <Header title={currentStep.titles} images={currentStep.images} />
              <div className="info-container">
                <div className="description">
                  {currentStep.descriptions[0].value}
                  {currentStep.highlights && (
                    <div className="options">
                      <div className="options-title">{currentStep.highlights.title[0].value}</div>
                      <div className="options-icons">
                        {currentStep.highlights &&
                          currentStep.highlights.highlightItems.map((item, index) => (
                            <>
                              <div key={index} className="icon">
                                <div
                                  className={"icon-image " + item.borderType.toLowerCase()}
                                  style={{
                                    backgroundColor: item.backgroundColor,
                                    borderColor: item.borderColor,
                                  }}
                                >
                                  <Icon imageStyle={{ height: '25px' }}
                                    imageUrl={item.icon[0].imageUrl}
                                  />
                                </div>
                                <div className="icon-description">
                                  <Label text={item.description[0].value} />
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="bottom-container info">
                <div className={savedAnswer ? "submition-message" : "submition-message hide"} aria-hidden={!savedAnswer ? true : undefined}>
                  {errorInfo &&
                    errorInfo.show &&
                    errorInfo.type == "smallError" ?
                    <MessageModal
                      show={true}
                      hideCloseButton={true}
                      title={currentStep.section.name}
                    >
                      <div className="error-message-container">
                        <div></div>
                        <div className="error-message">
                          <ErrorIcon />
                          <strong style={{ marginBottom: 10 }}>
                            {errorInfo.errorTitle}
                          </strong>
                          <p>{errorInfo.errorDescription}</p>
                        </div>
                        <button
                          className="dmc-button"
                          onClick={() => {
                            //SetShowMessageModal(false);
                            reduxDispatcher(setErrorInfo({
                              show: false
                            }));
                          }}>
                          Ok
                          </button>
                      </div>
                    </MessageModal> : "Responses saved"
                  }
                </div>
                <div className="buttons-row">
                  <CostumButton
                    text="Back"
                    fill={false}
                    handleStepState={backResetState}
                    nextPage={currentStep.stepId == 1 ? "Incomplete" : null}
                    costumFunction={currentStep.stepId == 1 ? manageFirstStepBack : null}
                  />
                  <CostumButton
                    text={isLastStep ? "Finish" : "Next"}
                    fill={true}
                    disabled={false}
                    type={"submit"}
                    resetState={infoResetStep}
                    isLastStep={isLastStep}
                  />
                  {/* button to display only on mobile */}
                  <CostumButton
                    text="Back"
                    fill={false}
                    handleStepState={backResetState}
                    nextPage={currentStep.stepId == 1 ? "Incomplete" : null}
                    costumFunction={currentStep.stepId == 1 ? manageFirstStepBack : null}
                  />
                </div>
              </div>
            </div>
          )}
          {currentStep.stepType?.toLowerCase() === "feedback" && (
            <div id="info-page" className="spacing questionnaire">
              <Progressbar
                type="Linear"
                answersTaken={steps}
                totalAnswers={process.env.REACT_APP_TOTAL_ANSWERS}
              />
              <div className="feedback-container">
                <div className="title">{currentStep.titles[0].value}</div>
                <div className="description">
                  {currentStep.descriptions[0].value}
                </div>
                <div className="push"></div>
              </div>

              <div className="bottom-container feedback">
                <div className={savedAnswer ? "submition-message" : "submition-message hide"} aria-hidden={!savedAnswer ? true : undefined}>
                  {errorInfo &&
                    errorInfo.show &&
                    errorInfo.type == "smallError" ?
                    <MessageModal
                      show={true}
                      hideCloseButton={true}
                      title={currentStep.section.name}
                    >
                      <div className="error-message-container">
                        <div></div>
                        <div className="error-message">
                          <ErrorIcon />
                          <strong style={{ marginBottom: 10 }}>
                            {errorInfo.errorTitle}
                          </strong>
                          <p>{errorInfo.errorDescription}</p>
                        </div>
                        <button
                          className="dmc-button"
                          onClick={() => {
                            //SetShowMessageModal(false);
                            reduxDispatcher(setErrorInfo({
                              show: false
                            }));
                          }}>
                          Ok
                          </button>
                      </div>
                    </MessageModal> : "Responses saved"
                  }
                </div>
                <div className="buttons-row">
                  <CostumButton text="Back" fill={false} handleStepState={backResetState} />
                  <CostumButton
                    text={isLastStep ? "Finish" : "Next"}
                    fill={true}
                    disabled={false}
                    type={"submit"}
                    isLastStep={isLastStep}
                    resetState={infoResetStep}
                  />
                  {/* button to display only on mobile */}
                  <CostumButton text="Back" fill={false} handleStepState={backResetState} />
                </div>
              </div>

            </div>
          )}
        </div>
        {
          isSalesforce && // Only staff members will see notes
          currentStep.showNote &&
          <QuestionnaireNote
            key={currentStep.stepId}
            note={{ value: currentStep.note }}
            onSave={(text) => {
              reduxDispatcher(setContextualNote(text))
            }} />
        }
      </>
    );
  }
};

export default QuestionairePage;
