import { INote } from "@components/Interfaces";
import React, { FC } from "react";
import { Button } from "react-bootstrap";
import ContextualNote from "@components/ContextualNote/ContextualNote";
import "./QuestionnaireNote.scss";
import { ReactComponent as IconAddNote } from "@assets/svg/add-note.svg";

interface IProps {
    note?: INote;
    style?: React.HTMLAttributes<HTMLDivElement>;
    onSave?: (text: string) => void;
}

const QuestionnaireNote: FC<IProps> = ({ note, style, onSave }) => {
    const [showNote, setShowNote] = React.useState(note?.value ? true : false);
    const [noteText, setNoteText] = React.useState(note?.value);

    const handleSave = (text) => {
        setNoteText(text);

        if (!text) {
            setShowNote(false);
        }
        if (onSave) {
            onSave(text);
        }
    }

    return (
        <div className="questionnaire-note" style={style}>
            { !showNote &&
                <div className="button-container">
                    <Button className="add-note-button" variant="outline-primary" onClick={() => { setShowNote(true) }}>
                        <IconAddNote />
                        + Add note
                    </Button>
                </div>
            }
            { showNote &&
                <div className="note-container">
                    <p className="notes-title">Notes</p>
                    <ContextualNote text={noteText} onSave={(text) => { handleSave(text); }} />
                </div>
            }
        </div>
    );
}

export default QuestionnaireNote;