import React, { useEffect, useState } from 'react';
import { CONTENTS, DELETE_DMC, RESPONDENT_DMCS_HISTORY } from 'requests/api';
import { useDispatch, useSelector } from 'react-redux';
import Assessment from './Assessment/Assessment';
import ConfirmModal from '@components/ConfirmModal';
import CostumButton from '@components/CustomButton';

import "./History.scss";
import AlertIcon from '@assets/svg/alert.svg';
import { setHistoryAssessments, setShowHistory, setShowSummary, setShowWelcome, setErrorInfo, setRequestLoading } from '../../../redux/actions';
import GTMHelper from '@helpers/GTMHelper.js';

import * as contentTypes from "../../../types/ContentTypes";
interface IProps {
    beginDmc: (boolean) => void
    resumeDmc: (startQuestionnaire: any, dmc: any, reduxDispatcher: any) => void
}

let headerTitle = "";
let mostRecentAssessments = "";
let pastAssessmentsTitle = "";
let resultsLinkText = "";
let deleteModalTitle = "";
let deleteModalSubTitle = "";
let deleteModalQuestionText = "";
let deleteModalConfirmText = "";
let deleteModalCancelText = "";
let deleteModalIconIdle = "";
let deleteModalIconHover = "";

function setResumeStaticContents(contents) {
    if (contents && contents.length > 0) {
        contents.map(element => {
            if (element.contentId === contentTypes.HistoryPage_Header_Title) {
                headerTitle = element.contentVersion.resources[0].value
            }
            else if (element.contentId === contentTypes.HistoryPage_RecentAssessment_Title) {
                mostRecentAssessments = element.contentVersion.resources[0].value
            }
            else if (element.contentId === contentTypes.HistoryPage_PastAssessments_Title) {
                pastAssessmentsTitle = element.contentVersion.resources[0].value
            }
            else if (element.contentId === contentTypes.HistoryPage_Link) {
                resultsLinkText = element.contentVersion.resources
            }
            else if (element.contentId === contentTypes.HistoryPage_DeleteModal_Title) {
                deleteModalTitle = element.contentVersion.resources[0].value
            }
            else if (element.contentId === contentTypes.HistoryPage_DeleteModal_SubTitle) {
                deleteModalSubTitle = element.contentVersion.resources[0].value
            }
            else if (element.contentId === contentTypes.HistoryPage_DeleteModal_QuestionDescription) {
                deleteModalQuestionText = element.contentVersion.resources[0].value;
            }
            else if (element.contentId === contentTypes.HistoryPage_DeleteModal_ConfirmDescription) {
                deleteModalConfirmText = element.contentVersion.resources[0].value;
            }
            else if (element.contentId === contentTypes.HistoryPage_DeleteModal_CancelDescription) {
                deleteModalCancelText = element.contentVersion.resources[0].value;
            }
            else if (element.contentId === contentTypes.HistoryPage_DeleteModal_IconIdle) {
                deleteModalIconIdle = element.contentVersion.resources[0].value;
            }
            else if (element.contentId === contentTypes.HistoryPage_DeleteModal_IconHover) {
                deleteModalIconHover = element.contentVersion.resources[0].value;
            }
        })
    }
}

const History: React.FC<IProps> = (props) => {
    let reduxDispatcher = useDispatch();
    const assessments = useSelector((state: any) => state.history.assessments);
    const pastDmcs = useSelector((state: any) => state.history.assessments.assessments);
    const ongoing = useSelector((state: any) => state.history.assessments.ongoing)
    const historyContents = useSelector((state: any) => state.contents.data);
    const resumedDmc = useSelector((state: any) => state.resumedDmc.dmc);
    const [confirmModal, setConfirmModal] = useState(false);
    const [dmcIdClicked, setDmcIdClicked] = useState(null);

    const respondentId = useSelector((state: any) => state.respondents.data);

    //const dmcsHistory: any = [].concat(assessments, going);
    let dmcs: any = null
    const [dmcsHistory, setDmcsHistory] = useState(dmcs);

    //const historyContents : any = useSelector((state) => state.contents.data);
    //functions
    //open confirmation modal
    function openModal(dmcId) {
        if (dmcId) setDmcIdClicked(dmcId);

        if (confirmModal) setConfirmModal(false)
        else setConfirmModal(true)
    }

    //delete assessment
    function deleteAssessment() {
        DELETE_DMC(dmcIdClicked, assessments.assessments, assessments.ongoing, reduxDispatcher);

        if (confirmModal) setConfirmModal(false)
        else setConfirmModal(true)
    }

    setResumeStaticContents(historyContents);

    useEffect(() => {
        CONTENTS("HistoryPage", reduxDispatcher)

        GTMHelper.pushEvent("e_moneychat_vpv", {
            pageType: "past_assessments"
        });

        window.scrollTo(0, 0);

    }, []);

    const previousResumedDmc = React.useRef();
    useEffect(() => {
        if (previousResumedDmc.current && previousResumedDmc.current !== undefined && previousResumedDmc.current != resumedDmc) {
            props.resumeDmc(props.beginDmc, resumedDmc, reduxDispatcher)
        }
        previousResumedDmc.current = resumedDmc
    }, [resumedDmc])

    //get dmcs to display in history page
    useEffect(() => {
        reduxDispatcher(setRequestLoading(true))
        RESPONDENT_DMCS_HISTORY(respondentId)
            .then(result => {
                reduxDispatcher(setRequestLoading(false))
                reduxDispatcher(setHistoryAssessments(result));
                let concatArray = result.assessments?.concat(result.ongoing)
                setDmcsHistory(concatArray)

            })
            .catch(() => {
                reduxDispatcher(setErrorInfo(
                    {
                        show: true,
                        type: "fullPage",
                        errorTitle: "Something went wrong",
                        errorDescription: "Couldn't retrieve results info.",
                        errorButton: null
                    }
                ))
            })
    }, [])

    useEffect(() => {
        if (assessments.assessments && assessments.assessments.length > 0) {
            let concatArray = assessments.assessments.concat(assessments.ongoing)
            setDmcsHistory(concatArray)
        }
        else if (assessments.assessments?.length === 0 && assessments.ongoing?.length === 0) {
            setDmcsHistory([])
        }
    }, [assessments])

    if (dmcsHistory && dmcsHistory.length <= 0) {
        reduxDispatcher(setShowSummary(false))
        reduxDispatcher(setShowHistory(false))
        reduxDispatcher(setShowWelcome(true));
    }

    return <>
        {dmcsHistory && dmcsHistory.length > 0 &&
            <>
                <div id="welcome-page">
                    <div className="components-wrapper">
                        <div className="spacing">
                            {headerTitle !== "" && <div className="history-header-title">{headerTitle}</div>}
                            <div className="assessment-list">
                                {mostRecentAssessments && <div className="title">{mostRecentAssessments}</div>}
                                {ongoing?.map((going, index) => {
                                    return <Assessment
                                        key={index}
                                        completed={false}
                                        dmcId={going.dmcId}
                                        startDate={going.startDate}
                                        endDate={going.endDate}
                                        steps={going.steps}
                                        link={going.link}
                                        deleteIconIdle={deleteModalIconIdle}
                                        deleteIconHover={deleteModalIconHover}
                                        resumeIncomplete={props.beginDmc}
                                        resumeDmc={props.resumeDmc}
                                        openModal={() => { openModal(going.dmcId) }}
                                    />
                                })}
                                {ongoing && ongoing.length > 0 && <div className="title">{pastAssessmentsTitle}</div>}
                                {pastDmcs.map((assessment, index) => {
                                    return <>
                                        <Assessment
                                            key={index}
                                            dmcId={assessment.dmcId}
                                            completed={true}
                                            startDate={assessment.startDate}
                                            endDate={assessment.endDate}
                                            steps={assessment.steps}
                                            link={assessment.link}
                                            deleteIconIdle={deleteModalIconIdle}
                                            deleteIconHover={deleteModalIconHover}
                                            openModal={() => { openModal(assessment.dmcId) }}
                                        />
                                        {index === 0 && dmcsHistory.length > 1 && (!ongoing || ongoing.length === 0) && <div className="title">{pastAssessmentsTitle}</div>}
                                    </>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmModal
                    title={deleteModalTitle}
                    show={confirmModal}
                    closeModal={() => setConfirmModal(false)}
                >
                    <img className="alert-icon" src={AlertIcon} alt={"Alert Icon"} />
                    <div className="confirm-title">{deleteModalSubTitle}</div>
                    <div className="confirm-description">{deleteModalQuestionText}</div>
                    <div className="confirm-btn">
                        <div className="first">
                            <CostumButton
                                text={deleteModalConfirmText}
                                handleStepState={() => { }}
                                costumFunction={() => deleteAssessment()}
                                nextPage={"History"}
                            />
                        </div>
                    </div>
                    <div className="confirm-btn">
                        <CostumButton
                            text={deleteModalCancelText}
                            handleStepState={() => { }}
                            costumFunction={() => openModal(null)}
                            nextPage={"History"}
                        />
                    </div>
                </ConfirmModal>
            </>
        }
    </>
}

export default History;