import { SET_RESPONDENTS } from "../actions/actionTypes";

function respondents(state = [], action) {
  switch (action.type) {
    case SET_RESPONDENTS:
      return action.value;
    default:
      return state;
  }
}

export default respondents;
