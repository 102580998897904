import React, { FC } from "react";
import { IGoalItem, IGoal } from "../../../../../Interfaces";
import Goal from "../../../../../Goal";

//styles
import "./SummaryGoals.scss";

interface IProps {
    goals: IGoalItem[];
}

const SummaryGoals: FC<IProps> = (props) => {
    return <div className="summary-goals-list">
        {props.goals?.map((goal, index) => {
            return <Goal
                key={index}
                index={index}
                {...goal}
                heat={goal.statusColor}
                alignment={"column"}
                readOnly={true}
            />
        })}
    </div>;
}

export default SummaryGoals;