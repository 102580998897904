const crypto = require("crypto-js");

interface Generate {
  codeVerifier: () => string;
  randomString: (length: number) => string;
  codeChallenge: (code_verifier: string) => string;
  encodeToBase64: (stringToEncode: string) => string;
}

export const generate: Generate = {
  codeVerifier: function () {
    var code_verifier = this.randomString(128);
    return code_verifier;
  },
  randomString: function (length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  },
  codeChallenge: function (code_verifier) {
    return this.encodeToBase64(crypto.SHA256(code_verifier));
  },
  encodeToBase64: function (stringToEncode) {
    const base64String = crypto.enc.Base64.stringify(stringToEncode);
    return base64String.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
  },
};
