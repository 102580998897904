import React from "react";
import "@styles/dmcWrapper.scss";

const DMCWrapper = ({ children }) => {
  return (
    <div className="DMCWrapper">
      {children}
    </div>
  );
};

export default DMCWrapper;
