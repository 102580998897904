import React, { useState, useEffect } from "react";
import Goal from "../../Goal";
import GoalModal from "../../GoalModal";

import RadioButtonList from "../../RadioButtonList";
import RadioButton from "../../Custom/RadioButton";
import LikertScale from "../../Likert";
import TextInput from "../../TextInput";
import DropDownList from "../DropDown/DropDownList";
import CostumButton from "../../CustomButton";
import ConfirmModal from "@components/ConfirmModal";
import AlertIcon from "@assets/svg/alert.svg";
import { Button } from "react-bootstrap";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { addNewGoal, addGoalSubQuestionAnswer, setGoalAnswers, setGoalsArray, setGoalSubQuestions, setNewAnswer, setAnswerState, setSubstrepInfo } from "../../../redux/actions"

//Resquest functions
import { SAVE_GOAL_SUBQUESTION } from "../../../requests/api";

const GoalsDragDropContainer = ({ storeGoals, selectedGoals, resetState }) => {
  const [goals, setGoals] = useState();
  const [completed, setCompleted] = useState(false);
  const [addGoal, setAddGoal] = useState(false);

  //control edit/remove goal Modal
  const [editingGoal, setEditingGoal] = useState(null);
  const [editedGoalAnswers, setEditedGoalAnswers] = useState(false);
  const [goalIDSelected, setGoalIDSelected] = useState();

  //control Modals
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  let firstGoalEmpty = null;

  //redux data
  const dmcId = useSelector(state => state.startDMC);
  const currentStep = useSelector(state => state.questionnaireFlow.currentStep);
  const reduxAnswers = useSelector(state => state.questionnaireWording.answers);
  const reduxDispatcher = useDispatch();

  //goals answer  
  const insertedGoals = useSelector(state => state.goals.currentGoals);
  insertedGoals.length > 0 ? storeGoals(true) : storeGoals(false)

  const goalsSubQuestions = useSelector(state => state.goals.subStepQuestions);
  let goalCurrentSubStep = goalsSubQuestions[goalsSubQuestions.length - 1];
  const goalsSubStepAnswers = useSelector(state => state.goals.subStepAnswers);

  //helper functions
  //handle modal closing and reseting all modal states
  const handleModal = (value) => {
    if (value) {
      // setEditedGoalAnswers(null);
      // setEditingGoal(null);
      setModalOpen(value);
    }
    else {
      setCompleted(value);
      setAddGoal(value);
      reduxDispatcher(setGoalAnswers([]));
      reduxDispatcher(setGoalSubQuestions([]));
      setModalOpen(value);
    }
  }

  //select a goal on click function
  const selecGoalOpen = (value) => {
    if (editingGoal || editedGoalAnswers) {
      setEditingGoal(false);
      setEditedGoalAnswers(false);
      setAddGoal(false);
      setCompleted(false);
    }
    handleModal(value)
  }

  //set flow completed
  const setFlowCompleted = (value) => {
    setCompleted(value)
    setAddGoal(value)
  }

  //drag and drop goals
  const swapGoals = (from, to) => {
    const goalsClone = [...goals];
    goalsClone[from] = goals[to];
    goalsClone[to] = goals[from];
    setGoals(goalsClone);
    reduxDispatcher(setGoalsArray(goalsClone))
  }

  //save goals answers
  const saveAnswerStore = (answerType, answerId, answerValue) => {
    let answerSchema = {
      stepId: goalCurrentSubStep.stepId,
      type: "",
      answers: [
        {
          id: "",
          value: "",
        },
      ],
    };

    if (goalsSubStepAnswers != null && goalsSubStepAnswers.length > 0) {
      let newAnswer = null;

      goalsSubStepAnswers.map((answer, index) => {
        if (answer.stepId?.toString() === goalCurrentSubStep.stepId?.toString()) {
          newAnswer = index;
          return;
        }
      });

      //if answer already exists on the store
      if (newAnswer != null) {
        let aux = goalsSubStepAnswers;
        aux[newAnswer].answers[0].id = answerId != null ? answerId : null;
        aux[newAnswer].answers[0].value = answerValue != null ? answerValue : null;
        //setQuestionaireAnswers(aux);
        reduxDispatcher(setGoalAnswers(aux));
      }
      //if answer doesnt exist on the state
      else {
        answerSchema.answers[0].id = answerId != null ? answerId : null;
        answerSchema.answers[0].value = answerValue != null ? answerValue : null;
        answerSchema.type = answerType;
        //setQuestionaireAnswers(prevState => [...prevState, answerSchema])
        reduxDispatcher(addGoalSubQuestionAnswer(answerSchema));
      }
    }
    //if state is empty
    else {
      answerSchema.answers[0].id = answerId != null ? answerId : null;
      answerSchema.answers[0].value = answerValue != null ? answerValue : null;
      answerSchema.type = answerType;
      reduxDispatcher(addGoalSubQuestionAnswer(answerSchema));
    }

    if (answerType === "radio" && answerSchema.answers[0]?.id !== "") {
      getNextGoalStep(answerSchema);
    }
  }

  //render expected components inside modal
  const dynamicRender = (subStep) => {
    let withAnswers = null;

    if (editingGoal && editedGoalAnswers) {
      withAnswers = editedGoalAnswers;
    }

    switch (subStep.controlType) {
      case "RadioButtonList":
        return (
          <RadioButton
            {...subStep}
          // handleState={saveAnswerStore}
          // selectedItem={withAnswer && completed ? withAnswer : {}}
          />
        );
        break;
      case "IconRadioButtonList":
        return (
          <RadioButtonList
            type="row"
            //options={schemaLifeStage}
            options={subStep}
            handleState={saveAnswerStore}
          //resetState={setCompleted}
          //selectedItem={withAnswers ? withAnswers : null}
          />
        );
        break;
      case "Range":
        return (
          <LikertScale
            {...subStep}
          // handleState={saveAnswerStore}
          // currentValue={withAnswer ? withAnswer.selectedId : null}            
          />
        );
        break;
      case "TextBox":
        return (
          <TextInput
            {...subStep}
          // handleState={saveAnswerStore}
          // writtenText={withAnswer ? withAnswer.selectedValue : ""}
          />
        );
        break;
      case "DropDownList":
        return (
          <DropDownList
            currentStep={subStep}
            alreadySelected={insertedGoals}
            selectedItems={withAnswers ? withAnswers : null}
            saveAnswerToStore={saveAnswerStore}
            setNextButtonToEnabled={setFlowCompleted}
            changeGoalTopic={changeGoalTopic}
            editingGoal={editingGoal}
          />
        )
        break
    }
  }

  //go to next goal step
  const getNextGoalStep = (iconRadioButtonAnswer) => {
    if (iconRadioButtonAnswer) {
      let subStepAnswerToStore = {
        isSubStep: true,
        stepId: iconRadioButtonAnswer.stepId ? iconRadioButtonAnswer.stepId : "",
        answers: iconRadioButtonAnswer.answers ? iconRadioButtonAnswer.answers : [{}],
      }

      SAVE_GOAL_SUBQUESTION(dmcId, subStepAnswerToStore, resetState, reduxDispatcher)
    }
  }

  const calculateHeat = (value) => {
    if (value <= 1) {
      return "red"
    }
    else if (value > 1 && value < 4) {
      return "orange"
    }
    else if (value >= 4) {
      return "green"
    }
  }

  //save goal in the store
  const saveNewGoal = (id, goalsSubStepAnswers, subStepsTaken, reduxDispatcher) => {
    let newGoal = {
      id: id,
      subject: "",
      time: "",
      heat: "",
      state: "",
      answers: goalsSubStepAnswers,
      steps: subStepsTaken
    }

    goalsSubStepAnswers.map(ans => {
      if (ans.stepId == 25) {
        newGoal.time = ans.answers[0].value
      }
      else if (ans.stepId == 26) {
        newGoal.state = ans.answers[0].value;
        let heatCalc = ans.answers[0].id !== undefined ? calculateHeat(parseInt(ans.answers[0].id)) :
          ans.answers[0].optionId !== undefined ? calculateHeat(parseInt(ans.answers[0].optionId)) : null;
        newGoal.heat = heatCalc;
      }
      else {
        if (ans.answers[0].subAnswers && ans.answers[0].subAnswers.length > 0) {
          newGoal.subject = ans.answers[0].subAnswers[0].answers[0].value;
        }
        else {
          newGoal.subject = ans.answers[0].value
        }
      }
    })

    reduxDispatcher(addNewGoal(newGoal));
    reduxDispatcher(setGoalSubQuestions([]));
    handleModal(false);
  }

  //delete goal localy
  const deleteGoal = (goalID) => {
    insertedGoals.map((goal, index) => {
      if (goal.id == goalID) {
        let auxGoals = insertedGoals;
        auxGoals.splice(index, 1);

        if (auxGoals.length > 0) {
          auxGoals.map((goal, index) => {
            goal.id = index + 1;
          });
        }

        reduxDispatcher(setGoalsArray(auxGoals))
        setEditModalOpen(false);
        return
      }
    })
  }

  //edit goal  
  const editGoal = (goalID) => {
    let selectedGoal = null;

    insertedGoals.map((goal, index) => {
      if (goal.id == goalID) {
        selectedGoal = goal;
        return
      }
    })

    let id = selectedGoal?.answers[0]?.answers[0]?.id;
    let optionId = selectedGoal?.answers[0]?.answers[0]?.optionId;
    let stepIcon = id ? selectedGoal?.steps[0]?.answerOptions?.find(x => x.id.toString() === id)
      : optionId ? selectedGoal?.steps[0]?.answerOptions?.find(x => x.id.toString() === optionId) : null;

    if (stepIcon) {
      reduxDispatcher(setSubstrepInfo(
        {
          icon: stepIcon.icon.imageUrl,
          title: selectedGoal?.answers[0]?.answers[0]?.value
        }
      ))
    }

    reduxDispatcher(setGoalSubQuestions(selectedGoal.steps))
    setEditedGoalAnswers(selectedGoal.answers);
    setEditingGoal(goalID);
    handleModal(true);
    setEditModalOpen(false);
  }

  //save edited goal
  const saveEditedGoal = (goalID) => {
    let editedGoal = null;
    let auxGoals = insertedGoals;

    insertedGoals.map((goal) => {
      if (goal.id == goalID) {
        editedGoal = goal;
      }
    })

    if (goalsSubStepAnswers) {
      editedGoal.answers.map((ans, index) => {
        //loop through the answer from the goal clicked to edit
        if (goalsSubStepAnswers.some(e => e.stepId === ans.stepId)) {
          let indexFound = index;
          let answerFound = goalsSubStepAnswers.find(x => x.stepId === ans.stepId)

          editedGoal.answers[indexFound] = answerFound;

          if (answerFound.stepId == 25) {
            editedGoal.time = answerFound.answers[0].value
          }
          else if (answerFound.stepId == 26) {
            editedGoal.state = answerFound.answers[0].value;
            let heatCalc = ans.answers[0].id !== undefined ? calculateHeat(parseInt(ans.answers[0].id)) :
              ans.answers[0].optionId !== undefined ? calculateHeat(parseInt(ans.answers[0].optionId)) : null;
            editedGoal.heat = heatCalc;
          }
          else {
            if (answerFound.answers[0].subAnswers) {
              editedGoal.subject = answerFound.answers[0].subAnswers[0].answers[0].value
            }
            else {
              editedGoal.subject = answerFound.answers[0].value
            }
          }
        }
        else {
          if (ans.stepId > 17 && ans.stepId < 25 && goalsSubStepAnswers.some(item => item.stepId > 17 && item.stepId < 25)) {
            let previousAnswersArray = editedGoal.answers;
            let diferentStepAnswer = goalsSubStepAnswers.filter((item) => { return !previousAnswersArray.some(answer => answer.stepId === item.stepId) })

            if (diferentStepAnswer && diferentStepAnswer.length > 0) {
              editedGoal.answers[index] = diferentStepAnswer[0];
            }
            else {
              editedGoal.answers.splice(index, 1)
            }
          }
        }
      })

      if (goalsSubQuestions) {
        editedGoal.steps.map((subStep, index) => {
          if (!goalsSubQuestions.some(item => item.stepId === subStep.stepId)) {
            let previousSteps = editedGoal.steps;
            let newStep = goalsSubQuestions.filter(step => { return !previousSteps.some(previous => previous.stepId === step.stepId) });

            if (newStep && newStep.length > 0) editedGoal.steps[index] = newStep[0];
          }
        })
      }

      goalsSubStepAnswers.map(editedAnswer => {
        if (editedGoal.answers.length > 0 && !editedGoal.answers.some(e => e.stepId === editedAnswer.stepId)) {
          editedGoal.answers.push(editedAnswer)
        }
        else if (editedGoal.answers.some(e => e.stepId === editedAnswer.stepId)) {
          let answerIndex = editedGoal.answers.findIndex(e => e.stepId === editedAnswer.stepId)
          editedGoal.answers[answerIndex] = editedAnswer
        }

        if (editedAnswer.stepId === 25) {
          editedGoal.time = editedAnswer.answers[0].value
        }
        else if (editedAnswer.stepId === 26) {
          editedGoal.state = editedAnswer.answers[0].value;
          let heatCalc = editedAnswer.answers[0].id !== undefined ? calculateHeat(parseInt(editedAnswer.answers[0].id)) :
            editedAnswer.answers[0].optionId !== undefined ? calculateHeat(parseInt(editedAnswer.answers[0].optionId)) : null;
          editedGoal.heat = heatCalc;
        }
        else {
          if (editedAnswer.answers[0].subAnswers) {
            editedGoal.subject = editedAnswer.answers[0].subAnswers[0].answers[0].value
          }
          else {
            editedGoal.subject = editedAnswer.answers[0].value
          }
        }
      })
    }

    auxGoals.map((goal, index) => {
      if (goal.id === goalID) {
        auxGoals[index] = editedGoal;
      }
    })

    reduxDispatcher(setGoalsArray(auxGoals))
    reduxDispatcher(setGoalSubQuestions([]));
    setGoals(auxGoals);
    handleModal(false);
  }

  //change goal topic and go back 
  const changeGoalTopic = () => {
    setCompleted(false);

    if (goalsSubStepAnswers && goalsSubStepAnswers.length > 0) {
      //remove last step taken
      let stepsTaken = goalsSubQuestions[0];

      //remove answer given to the current step
      goalsSubStepAnswers.map(answer => {
        if (answer.stepId === stepsTaken.stepId) {
          reduxDispatcher(setGoalSubQuestions([stepsTaken]))
          setAddGoal(false)
          // setEditingGoal(true)
          // setEditedGoalAnswers(stepsTaken.answers)
        }
      })

      reduxDispatcher(setGoalAnswers([]))
    }
    else if (goalsSubQuestions && goalsSubQuestions.length > 0) {
      //setEditingGoal(false)
      setEditedGoalAnswers(null)
      setAddGoal(false);
      reduxDispatcher(setGoalSubQuestions([goalsSubQuestions[0]]))
      //reduxDispatcher(setGoalSubQuestions([]))
    }
  }

  const setOpenModal = (goalID) => {
    setGoalIDSelected(goalID);
    editModalOpen ? setEditModalOpen(false) : setEditModalOpen(true);
  }

  function closeModal(value) {
    setEditModalOpen(value);
    setConfirmDelete(value)
  }

  //calculate the amount of empty goals after goal is insert
  let auxGoals = [];
  if (insertedGoals.length < currentStep.answerOptions.length) {
    let count = currentStep.answerOptions.length - insertedGoals.length;

    for (let index = 0; index < count; index++) {
      auxGoals.push(
        <Goal
          swapGoals={swapGoals}
          index={count == 1 ? index + 2 : index + 1}
          key={index}
          openModal={selecGoalOpen}
          selectString={index == 0 ? "Select a goal" : ""}
        />
      )
    }
  }

  useEffect(() => {
    setGoals(insertedGoals)

    let newAnswer = {
      stepId: currentStep.stepId,
      answers: [/*{
        id: currentStep.stepId,
        subAnswers: []
      }*/]
    }

    insertedGoals.map(goal => {
      newAnswer.answers.push(
        {
          id: goal.id.toString(),
          subAnswers: goal.answers
        }
      )
    })

    if (insertedGoals && insertedGoals.length > 0) {
      if (reduxAnswers.some(e => e.stepId == 26)) {
        let index = reduxAnswers.findIndex(e => e.stepId == currentStep.stepId)
        let aux = reduxAnswers;
        aux[index] = newAnswer
        reduxDispatcher(setAnswerState(aux))
        //reduxDispatcher(setCurrentStep(newAnswer))
      }
      else {
        if (reduxAnswers.some(answer => answer.stepId === currentStep.stepId)) {
          let index = reduxAnswers.findIndex(e => e.stepId === currentStep.stepId)
          let aux = reduxAnswers;
          aux[index] = newAnswer
          reduxDispatcher(setAnswerState(aux))
        }
        else {
          reduxDispatcher(setNewAnswer(newAnswer))
        }
        //reduxDispatcher(setNewAnswer(newAnswer))
        //reduxDispatcher(setCurrentStep(newAnswer))
      }
    }
  }, [insertedGoals])

  //build goals after resume
  useEffect(() => {
    if (selectedGoals && selectedGoals.goals?.length > 0 && insertedGoals?.length === 0) {
      selectedGoals.goals.map((goal, index) => {
        let goalSubSteps = []

        //get goal Substeps after Dmc Resume
        if (goal.subAnswers) {
          goal.subAnswers.map(answer => {
            if (goal.subAnswers.length <= 3) {
              if (answer !== goal.subAnswers[goal.subAnswers.length - 1]) {
                if (selectedGoals.goalsSubSteps.some(step => step.stepId === answer.stepId)) {
                  let goalStep = selectedGoals.goalsSubSteps.find(step => step.stepId === answer.stepId)

                  goalSubSteps.push(goalStep);
                }
              }
            }
            else {
              if (answer !== goal.subAnswers[goal.subAnswers.length - 2] && answer !== goal.subAnswers[goal.subAnswers.length - 1]) {
                if (selectedGoals.goalsSubSteps.some(step => step.stepId === answer.stepId)) {
                  let goalStep = selectedGoals.goalsSubSteps.find(step => step.stepId === answer.stepId)

                  goalSubSteps.push(goalStep);
                }
              }
            }
          })
        }

        reduxDispatcher(setGoalSubQuestions([]))
        saveNewGoal(index + 1, goal.subAnswers, goalSubSteps, reduxDispatcher);
      })
    }
  }, [])

  return (
    <>
      <div className="goals-container">
        {insertedGoals.length === 0 ? (
          currentStep.answerOptions?.map((goal, index) => {
            if (!goal.subject && !firstGoalEmpty) { firstGoalEmpty = goal.id }

            return <Goal
              swapGoals={swapGoals}
              selectString={goal.id === firstGoalEmpty && firstGoalEmpty ? "Select a goal" : ""}
              id={goal ? goal.id : index}
              index={index}
              openModal={selecGoalOpen}
              // editGoal={editGoal}
              // deleteGoal={deleteGoal}
              setOpenModal={setOpenModal}
              {...goal} />
          })) :
          (
            <>
              {insertedGoals.map((goal, index) => {
                return <Goal
                  swapGoals={swapGoals}
                  id={goal ? goal.id : index}
                  index={index}
                  key={index}
                  openModal={selecGoalOpen}
                  // editGoal={editGoal}
                  // deleteGoal={deleteGoal}
                  setOpenModal={setOpenModal}
                  {...goal} />
              })}
              {auxGoals && auxGoals}
            </>
          )
        }
      </div>
      {modalOpen &&
        <GoalModal state={modalOpen} modalTitle={"My goal is to:"} closeModal={handleModal}
          body={
            <div className="body-wrapper">
              {goalCurrentSubStep ? dynamicRender(goalCurrentSubStep) : ""}
            </div>
          }
          buttons={
            <>
              <Button
                tabIndex={0}
                onClick={() => {
                  if (completed && addGoal) {
                    if (!editingGoal && !editedGoalAnswers) {
                      saveNewGoal(insertedGoals.length + 1, goalsSubStepAnswers, goalsSubQuestions, reduxDispatcher)
                    }
                    else {
                      saveEditedGoal(editingGoal);
                    }
                  }
                }}
                disabled={!completed}>
                {!editingGoal ? "Add goal" : "Edit goal"}
              </Button>
            </>
          }
        />
      }
      {editModalOpen &&
        <GoalModal state={editModalOpen} modalTitle={""} closeModal={closeModal}
          body={
            <div className="body-wrapper">
              <div className="edit-goal-wrapper">
                {!confirmDelete ? (
                  <>
                    <img className="alert-icon" src={AlertIcon} alt={"alert-icon"} />
                    <div className="confirm-description">What is the action for this goal?</div>
                    <div className="confirm-btn">
                      <div className="first">
                        <CostumButton
                          text={"Edit goal"}
                          handleStepState={() => { }}
                          costumFunction={() => editGoal(goalIDSelected)}
                          nextPage={"History"}
                        />
                      </div>
                    </div>
                    <div className="confirm-btn">
                      <CostumButton
                        text={"Delete goal"}
                        handleStepState={() => { }}
                        costumFunction={() => setConfirmDelete(true)}
                        nextPage={"History"}
                      />
                    </div>
                  </>
                ) :
                  (
                    <>
                      <img className="alert-icon" src={AlertIcon} alt={"alert-icon"} />
                      <div className="confirm-description">Are you sure you want to delete this goal?</div>
                      <div className="confirm-btn">
                        <div className="first">
                          <CostumButton
                            text={"Yes, delete it"}
                            handleStepState={() => { }}
                            costumFunction={() => { deleteGoal(goalIDSelected); setConfirmDelete(false) }}
                            nextPage={"History"}
                          />
                        </div>
                      </div>
                      <div className="confirm-btn">
                        <CostumButton
                          text={"No, keep it"}
                          handleStepState={() => { }}
                          costumFunction={() => { setEditModalOpen(false); setConfirmDelete(false); }}
                          nextPage={"History"}
                        />
                      </div>
                    </>
                  )}
              </div>
            </div>
          }
        />
      }
    </>
  );
};

export default GoalsDragDropContainer;
