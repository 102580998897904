import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import ContextualNote from "../ContextualNote/ContextualNote";
import "./ContextualNoteList.scss";
import { INote } from "@components/Interfaces";
import { ReactComponent as IconArrowDown } from "@assets/svg/chevron-down.svg";
import { ReactComponent as IconArrowUp } from "@assets/svg/chevron-up.svg";
import { ReactComponent as IconAddNote } from "@assets/svg/add-note.svg";
import { Button } from "react-bootstrap";

interface IProps {
    notes: INote[];
    containerClassName?: string;
    style?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    onSave?: (noteId: string, text: string) => void;
}

const ContextualNoteList: FC<IProps> = ({ notes, containerClassName, style, onSave }) => {
    const [Expanded, setExpanded] = React.useState(false);
    const containerClass = ["notes-list-container", containerClassName].join(" ");
    const [SelfNotes, setSelfNotes] = React.useState(notes.length === 1 && notes.find(n => n.value === "") ? [] : notes);
    const [NoteIdPrefix, setNoteIdPrefix] = React.useState<string | null>();

    React.useEffect(() => {
        if (notes?.length > 0) {
            let emptyNoteId = notes[0]?.noteId;

            setNoteIdPrefix(emptyNoteId?.split("Note")[0] + "Note");
        }
    }, [])

    const handleSave = (noteId, text) => {
        if (SelfNotes.length === 1 && !text) {
            setSelfNotes([]);
            setExpanded(false);
        }
        onSave && onSave(noteId, text);
    }

    return (
        <>
            <div className={containerClass} style={style}>
                <div className="expand-panel" onClick={() => { setExpanded(!Expanded); }}>
                    <p className="notes-title">Notes</p>
                    {(!SelfNotes || SelfNotes.length === 0) &&
                        <div className="button-container">
                            <Button className="add-note-button" variant="outline-primary"
                                onClick={() => {
                                    SelfNotes.push(
                                        {
                                            value: "",
                                            noteId: NoteIdPrefix ? NoteIdPrefix + SelfNotes.length : ""
                                        }
                                    );
                                    setExpanded(true);
                                }}>
                                <IconAddNote />
                                + Add note
                            </Button>
                        </div>
                    }
                    {SelfNotes && SelfNotes.length > 0 &&
                        <div>
                            {Expanded ? <IconArrowUp /> : <IconArrowDown />}
                        </div>
                    }
                </div>
                {Expanded && SelfNotes.map((note, index) => (
                    <ContextualNote
                        key={index}
                        text={SelfNotes[index].value}
                        title={(note.titles && note.titles.length > 0) ? note.titles[0].value : ""}
                        subtitle={(note.subTitles && note.subTitles.length > 0) ? note.subTitles[0].value : ""}
                        style={{ marginBottom: 10 }}
                        onSave={(text) => { handleSave(note.noteId, text); SelfNotes[index].value = text; }}
                        isList={SelfNotes.length > 1}
                    />
                ))}
            </div>
        </>
    );
}

export default ContextualNoteList;