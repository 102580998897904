import React from "react";
import "../../styles/radio.scss";

/**
 *
 * @param {string} id - the unique ID for the input
 * @param {string} type - the type of the input. Example: "radio"
 * @param {number} name - the name of the input
 *
 *
 * @example
 * <Input id="1" type="radio" name="inputGroup" />
 */

const Input = (props) => {
  const { id, type, name, value, checked, tabindex } = props;
  const classname = type === "radio" ? "radioContainer" : "";

  return (
    <div className={classname}>
      <input type={type} id={id} value={value} name={name} tabindex={tabindex} checked={checked || null} />
      <div className="checkmark"></div>
    </div>
  );
};

export default Input;
