function history(state = { showHistory: false, assessments: [] }, action) {
    switch (action.type) {
        case "SET_SHOW_HISTORY":
            return { showHistory: action.value, assessments: state.assessments }
        case "SET_HISTORY_ASSESSMENTS":
            return { showHistory: state.showHistory, assessments: action.value }
        default:
            return state
    }
}

export default history