import React from "react";
import { Button } from "react-bootstrap";
import "./ContextualNote.scss";
import { ReactComponent as IconAddNote } from "@assets/svg/add-note.svg";

interface IProps {
    onSave?: (text: string) => void;
    title?: string;
    subtitle?: string;
    text?: string;
    style?: React.CSSProperties;
    isList?: boolean;
}

interface IState {
    note: string;
    readonly: boolean;
}

class ContextualNote extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            note: this.props.text ? this.props.text : "",
            readonly: (this.props.text || this.props.text === "" && this.props.isList) || (this.props.text) ? true : false
        }
    }

    handleEdit = () => {
        this.setState({
            readonly: false
        });
    }

    handleSave = () => {
        this.setState({
            readonly: true
        });

        if (this.props.onSave) {
            this.props.onSave(this.state.note);
        }
    }
    render() {
        return (
            <div className="contextual-note-container" style={this.props.style}>
                {this.props.title && <p className="note-title">{this.props.title}</p>}
                {this.props.subtitle && <p className="note-subtitle">{this.props.subtitle}</p>}
                <div className="note-panel">
                    <div className="notes-content">
                        {this.state.readonly ?
                            <>
                                <p className="note-text-readonly">{this.state.note}</p>
                                {this.props.isList && this.state.note === "" ?
                                    <Button onClick={this.handleEdit}
                                        variant="outline-primary"
                                        className="note-button note-button-icon"
                                    >
                                        <>
                                            <IconAddNote /> + Add note
                                        </>
                                    </Button>
                                    :
                                    <Button onClick={this.handleEdit}
                                        variant="outline-primary"
                                        className="note-button"
                                    >
                                        Edit
                                    </Button>
                                }
                            </>
                            :
                            <>
                                <textarea
                                    className="note-text"
                                    value={this.state.note}
                                    onChange={(e) => this.setState({ note: e.target.value })}
                                />
                                <Button variant="outline-primary"
                                    onClick={this.handleSave}
                                    className="note-button"
                                >
                                    Save
                                </Button>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ContextualNote;