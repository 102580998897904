import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import Label from "./Label";
import Input from "./Input";

/* IMPORT SVG ICONS HERE */
import BorrowIcon from "../../assets/svg/borrow.svg";
import BusinessIcon from "../../assets/svg/business.svg";
import HandsIcon from "../../assets/svg/hands.svg";
import WalletIcon from "../../assets/svg/wallet.svg";
import SaveIcon from "../../assets/svg/save-money.svg";
import PlanIcon from "../../assets/svg/plan.svg";
//import NewAssessmentIcon from "../../assets/svg/new-assessment.svg";
import NewAssessmentIcon from "../../assets/svg/new_assessment.svg";
import PastAssessmentIcon from "../../assets/svg/history.svg";
import ExportAssessmentIcon from "../../assets/svg/export-assessment.svg";
import Phone from "../../assets/svg/phone.svg";
import Calendar from "../../assets/svg/calendar.svg";

import { IIcon } from "@components/Interfaces"

/**
 * Renders an icon with _img_ tag.
 * @example
 *
 * // Passing the icon business as a prop
 * <Icon icon="business" />
 */

interface IProps {
  imageUrl: string,
  altText: string,
  className?: string;
  imageStyle?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  ariaHidden?: boolean;
}

const Icon: FC<IProps> = (props) => {
  const icons = {
    default: "icon", // Displays empty icon
    borrow: BorrowIcon,
    hands: HandsIcon,
    business: BusinessIcon,
    wallet: WalletIcon,
    phone: Phone,
    calendar: Calendar,
    new: NewAssessmentIcon,
    past: PastAssessmentIcon,
    save: SaveIcon,
    plan: PlanIcon,
    export: ExportAssessmentIcon
  };

  return <>{props.imageUrl && <img aria-hidden={props.ariaHidden ? "true" : "false"} src={props.imageUrl} style={props.imageStyle} alt={props.altText ? props.altText : ""} className={props.className} />}</>
};

export default Icon;
