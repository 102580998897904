import React from 'react';
import { Modal } from 'react-bootstrap';
import '../styles/modal.scss';
import CloseModalIcon from "../assets/svg/closeModal.svg"

/**
 * 
 * @param {*} children
 * @param {*} state
 * @param {*} modalTitle
 * @param {*} body
 * 
 * @example
 * 
 * <Modal show={state}>
 *     <Modal.Header>title</Modal.Header>
 *     <Modal.Body>body</Modal.Body>
 *     <Modal.Footer>buttons</Modal.Footer>
 * </Modal>
 */

const GoalModal = ({ state, modalTitle, closeModal, body, buttons }) => {
    let className = "goal-modal";

    if (sessionStorage.getItem("CurrentChannel") === "dcu_mobile") {
        className += " webViewMode";
    }

    return (
        <Modal show={state}
            className={className}
            onHide={() => closeModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            container={document.getElementById("dmcWidget")}
            centered>
            <Modal.Header>
                <p id="contained-modal-title-vcenter" className="sr-only">{modalTitle}</p>
                <p aria-hidden="true" className="goal-header-title">{modalTitle}</p>
                <button alt="Close" aria-label="Close" tabIndex={0} onClick={() => closeModal(false)} onKeyDown={(e) => { if (e.key === "Enter") closeModal(false) }}><img src={CloseModalIcon} /></button>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                {buttons}
            </Modal.Footer>
        </Modal>)
}

export default GoalModal;
