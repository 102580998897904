import React from "react";

const style = { cursor: "pointer" };
const textStyle = { margin: "6px 0" };
/**
 *
 * @param {string} htmlFor - Same id as the Input for label
 * @param {string} text - Text to display in the label
 * @param {JSX} children - JSX to pass as children
 *
 * @example
 * // <Input> is the children
 * <Label htmlFor="1" text="I'm a label">
 *     <Input type="radio" id="1" name="inputGroup" />
 * </Label>
 */
const Label = (props) => {
  const { htmlFor, text, children } = props;

  return (
    <label style={style} htmlFor={htmlFor}>
      <div className="card-title" style={textStyle}><div>{text}</div></div>
      <div>{children}</div>
    </label>
  );
};

export default Label;
