import { SET_LOADER_MESSAGE, SET_IS_QUESTIONNAIRE_RUNNING } from "../actions/actionTypes";

function loaderMessage(state = { loaderWithMessage: false, isQuestionnaireRunning: false }, action) {
    switch (action.type) {
        case SET_LOADER_MESSAGE:
            return { loaderWithMessage: action.value, isQuestionnaireRunning: state.isQuestionnaireRunning };
        case SET_IS_QUESTIONNAIRE_RUNNING:
            return { loaderWithMessage: state.loaderWithMessage, isQuestionnaireRunning: action.value };
        default:
            return state;
    }
}

export default loaderMessage;