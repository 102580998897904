import * as React from 'react';
import "./AssessmentButton.scss";
import { IIcon } from "@components/Interfaces"
import { useDispatch, useSelector } from 'react-redux';
import { setShowHistory, setShowSummary, startNewDmc, setCurrentStep, setQuestionnaireFlow, setAnswerState, setIsLastStep, setGoalsArray, setAnswerPairs, setResumedDmc, setStaffResumedDmc } from '../../../../../../redux/actions';
import ExportAssessmentModal from '@components/ExportAssessmentModal/ExportAssessmentModal';
import ExportAssessment from '../../ExportAssessment/ExportAssessment';
import { ReactComponent as ExportIcon } from "@assets/svg/export-assessment.svg";
import { ReactComponent as NewIcon } from "@assets/svg/new_assessment.svg";
import { ReactComponent as HistoryIcon } from "@assets/svg/history.svg";
import { ReactComponent as OtherIcon } from "@assets/svg/other.svg";
import GTMHelper from '@helpers/GTMHelper.js';
interface IProps {
    nextPage: string,
    icon?: IIcon,
    title?: string,
    startNewDmc?: () => void
}

//redirect widget to assessments history page
function goToHistoryPage(assessments, reduxDispatcher) {
    if (assessments) {
        if (assessments.length > 0) {
            reduxDispatcher(setShowHistory(true));
            reduxDispatcher(setShowSummary(false))
        }
        else {
            reduxDispatcher(setShowHistory(false));
            reduxDispatcher(setShowSummary(false))
        }
    }
}

//redirect widget to start a new questionnaire
function startNewQuestionnaire(startNewDmc, reduxDispatcher) {
    //reset redux questionnaire state
    reduxDispatcher(setShowHistory(false));
    reduxDispatcher(setShowSummary(false));
    reduxDispatcher(setCurrentStep([]));
    reduxDispatcher(setQuestionnaireFlow([]));
    reduxDispatcher(setAnswerState([]));
    reduxDispatcher(setIsLastStep(false))
    reduxDispatcher(setGoalsArray([]));
    reduxDispatcher(setAnswerPairs([]));

    reduxDispatcher(setResumedDmc(null));
    reduxDispatcher(setStaffResumedDmc(null));

    startNewDmc()
}

//decide btn action by nextPage received
function setButtonAction(nextPage, startNewDmc, assessments, reduxDispatcher) {
    if (nextPage === "questionnaire") {
        GTMHelper.pushEvent("e_moneychat_new_assessment");
        startNewQuestionnaire(startNewDmc, reduxDispatcher);
    }
    else if (nextPage === "history") {
        goToHistoryPage(assessments, reduxDispatcher)
    }
}

const AssessmentButton: React.FC<IProps> = (props) => {
    const reduxDispatcher = useDispatch();
    let assessments = useSelector((state: any) => state.respondentsDmcs?.past);
    const [ShowModal, setShowModal] = React.useState(false);
    //remove this
    //force button to work without past dmcs
    assessments = [{}];

    let Icon: any = <OtherIcon />;
    switch (props.nextPage) {
        case "questionnaire": Icon = <NewIcon />; break;
        case "export": Icon = <ExportIcon />; break;
        case "history": Icon = <HistoryIcon />; break;
    }

    return (
        <>
            <button className="assessment-btn"
                aria-label={props.title}
                onClick={(e) => {
                    e.preventDefault();
                    setButtonAction(props.nextPage, props.startNewDmc, assessments, reduxDispatcher);
                    props.nextPage === "export" && setShowModal(true);
                }}>
                {props.icon && Icon}

                <div>{props.title}</div>
            </button>
            <ExportAssessmentModal
                title="Export assessment" show={ShowModal}
                closeModal={() => { setShowModal(false); }}
            >
                <ExportAssessment />
            </ExportAssessmentModal>
        </>);
}

export default AssessmentButton;