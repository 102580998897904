export const replacePlaceholderName = (text: string) => {
    // This should be a temporary fix... 
    /// the text must be sent from service
    let customerData = sessionStorage.getItem("customerData");
    let customerName = "";
    let txtToReplace = "#placeholderName#";
    const isSalesforce = window.sessionStorage.getItem("CurrentChannel") === "Salesforce";

    if (customerData) {
        customerData = JSON.parse(customerData);
        // @ts-ignore
        if (customerData.givenName) {
            // @ts-ignore
            customerName = isSalesforce ? "" : `${customerData.givenName}`;
        }
    }

    if (customerName === "") {
        // Check if comma exists
        if (text.indexOf(", " + txtToReplace) !== -1) {
            txtToReplace = ", " + txtToReplace;
        }
        // Check if space exists
        if (text.indexOf(" " + txtToReplace) !== -1) {
            txtToReplace = " " + txtToReplace;
        }
    }

    return text.replace(txtToReplace, customerName);
}
export default replacePlaceholderName;