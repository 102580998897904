// Redux Actions
import {
  setRespondents,
  setContents,
  setRequestLoading,
  setRespondentsDmcs,
  setQuestionnaireFlow,
  setNewStep,
  setCurrentStep,
  startNewDmc,
  setNewAnswerPair,
  setAnswerPairs,
  setAnswerState,
  setProgressMade,
  addGoalSubQuestion,
  setIsLastStep,
  setShowSummary,
  setResumedDmc,
  setSummaryAlert,
  setGoalsArray,
  setErrorInfo,
  setLoaderMessage,
  setHistoryAssessments,
  setShowWelcome,
  setGoalSubQuestions,
  setIsQuestionnaireRunning,
  setSessionGuid
} from "../redux/actions";
import axios from "axios";
import log from "../helpers/logger";
import GTMHelper from "@helpers/GTMHelper.js";
import store from '../redux/store.js';
import { v4 as uuidv4 } from "uuid";

// Destructured environment variables
const {
  REACT_APP_API_RESPONDENTS_URI,
  REACT_APP_API_CONTENTS_GROUP_URI
} = process.env;

//Set authorization header based on Config
var config = {};
const postHeaders = new Headers();

//avoid loader refresh on next step process
let isServiceCallRunning = false;

//detect session timeout
var isSessionTimedOut = false;
let guid = null;

function setHeaders() {
  log.debug(`Authorization Headers Current Channel: ${sessionStorage.getItem("CurrentChannel")} , Azure Send: ${process.env.REACT_APP_API_SENDAUTHHEADER_Azure}, Simeio Send: ${process.env.REACT_APP_API_SENDAUTHHEADER_Simeio}`);

  const contextHeader = {
    UserAgent: navigator.userAgent,
    Channel: sessionStorage.getItem("CurrentChannel"),
    agent: JSON.parse(sessionStorage.getItem("agentData")),
    customer: JSON.parse(sessionStorage.getItem("customerData"))
  };

  config = {
    headers: {
      'X-DMC-SessionId': guid ? guid : null,
      'dmc_context': JSON.stringify(contextHeader)
    }
  };
  postHeaders.set('dmc_context', JSON.stringify(contextHeader));

  if ((process.env.REACT_APP_API_SENDAUTHHEADER_Simeio === "true" && (sessionStorage.getItem("CurrentChannel") === "dcu" || sessionStorage.getItem("CurrentChannel") === "dcu_sim" || sessionStorage.getItem("CurrentChannel") === "dcu_mobile")) ||
    (process.env.REACT_APP_API_SENDAUTHHEADER_Azure === "true" && sessionStorage.getItem("CurrentChannel") === "Salesforce")) {
    log.debug("Setting Authorization Headers");
    postHeaders.set("Authorization", `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`);
    config.headers.Authorization = `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`;
  } else {
    log.debug("Authorization Headers - Disabled");
  }
}

/* ----------------------------------------- */
/* ------------ GET ------------------------ */
/* ----------------------------------------- */
function GET(endpoint, reduxDispatcher) {
  if (!isServiceCallRunning) reduxDispatcher(setRequestLoading(true));

  log.debug(`GET: ${endpoint}`);
  setHeaders();

  try {
    return axios
      .get(sessionStorage.getItem("ApiUrl") + endpoint, config)
      .then((response) => {
        log.debug(`Success calling: ${endpoint}`);
        return response;
      })
      .catch((error) => {
        log.error(`Service error calling: ${endpoint} - ${error}`);

        //Requires DCU testing
        if (error.response.status === 401) {
          console.log("Money Chat - session timeout")

          reduxDispatcher(setErrorInfo({
            show: true,
            type: "fullPage",
            errorTitle: "Something went wrong.",
            errorDescription: "Your session has expired. Please log back into Online Banking to resume your Money Chat session",
            errorButton: null,
          }))

          isSessionTimedOut = true;
        }

        reduxDispatcher(setErrorInfo({
          show: true,
          type: "fullPage",
          errorTitle: "Something went wrong.",
          errorDescription: "It's not you, it's us. Please try again.",
          errorButton: null,
        }))
      })
      .finally(() => {
        reduxDispatcher(setRequestLoading(false));
        reduxDispatcher(setLoaderMessage(false))
      });
  } catch (e) {
    log.error(`Unexpected error calling: ${endpoint}`, e);
  }
  return null;
}

/* ----------------------------------------- */
/* ------------ POST ----------------------- */
/* ----------------------------------------- */
function POST(endpoint, payload, reduxDispatcher) {
  if (reduxDispatcher) {
    reduxDispatcher(setRequestLoading(true));
  }

  log.debug(`POST: ${endpoint}`);
  setHeaders();

  try {
    postHeaders.set("Content-Type", "application/json");
    postHeaders.set("X-DMC-SessionId", guid);
    const requestOptions = {
      method: "POST",
      headers: postHeaders,
      redirect: "follow",
      withCredentials: true,
    };

    if (payload) {
      requestOptions.body = JSON.stringify(payload);
    };

    return fetch(sessionStorage.getItem("ApiUrl") + endpoint, requestOptions)
      .then((response) => {
        if (response.status === 204) return;

        //Requires DCU testing       
        if (response.status === 401) {
          console.log("Money Chat - session timeout")

          reduxDispatcher(setErrorInfo({
            show: true,
            type: "fullPage",
            errorTitle: "Something went wrong.",
            errorDescription: "Your session has expired. Please log back into Online Banking to resume your Money Chat session",
            errorButton: null,
          }))

          isSessionTimedOut = true;
          return;
        }

        if (response) {
          return response.json();
        }
      })
      .then((result) => {
        return result;
      })
      .catch((error) => log.error(`Service error posting: ${endpoint} - ${error}`))
      .finally(() => {
        if (reduxDispatcher) {
          if (!isServiceCallRunning) {
            reduxDispatcher(setRequestLoading(false));
            reduxDispatcher(setLoaderMessage(false))
          }
        }
      });
  } catch (e) {
    log.error(`Unexpected error posting: ${endpoint}`, e);

    reduxDispatcher(setErrorInfo({
      show: true,
      type: "fullPage",
      errorTitle: "Something went wrong.",
      errorDescription: "It's not you, it's us. Please try again.",
      errorButton: null,
    }))
  }
  return null;
};

function POST_DELETE(endpoint, payload, reduxDispatcher) {
  if (reduxDispatcher) {
    reduxDispatcher(setRequestLoading(true));
  }

  log.debug(`POST: ${endpoint}`);
  setHeaders();
  try {
    postHeaders.set("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: postHeaders,
      redirect: "follow",
      withCredentials: true,
    };

    if (payload) {
      requestOptions.body = JSON.stringify(payload);
    };

    return fetch(sessionStorage.getItem("ApiUrl") + endpoint, requestOptions)
      .then((response) => {
        if (response.status === 204) return;
        if (response) {
          return response.json();
        }
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {

        log.error(`Service error posting: ${endpoint} - ${error}`);

        reduxDispatcher(setErrorInfo({
          show: true,
          type: "fullPage",
          errorTitle: "Something went wrong.",
          errorDescription: "Your Money Chat could not be deleted. Please log a Service Now incident for assistance",
          errorLink: "https://coastcapitalonline.service-now.com/sp/",
          errorButton: "History"
        }));

      })
      .finally(() => {
        if (reduxDispatcher) {
          if (!isServiceCallRunning) {
            reduxDispatcher(setRequestLoading(false));
            reduxDispatcher(setLoaderMessage(false))
          }
        }
      });
  } catch (e) {
    log.error(`Unexpected error posting: ${endpoint}`, e);

    reduxDispatcher(setErrorInfo({
      show: true,
      type: "fullPage",
      errorTitle: "Something went wrong.",
      errorDescription: "Your Money Chat could not be deleted. Please log a Service Now incident for assistance",
      errorLink: "https://coastcapitalonline.service-now.com/sp/",
      errorButton: "History"
    }));
  }
  return null;
};

/* ----------------------------------------- */
/* ------------ GET RESPONDENTS ------------ */
/* ----------------------------------------- */
export const RESPONDENTS = (t24ProfileId, reduxDispatcher) => {
  log.debug(`Calling RESPONDENTS`);
  const endpoint = `${REACT_APP_API_RESPONDENTS_URI}?t24ProfileId=${t24ProfileId}`;

  //create and save session guid
  if (!guid) {
    guid = uuidv4();
    reduxDispatcher(setSessionGuid(guid))
  }

  GET(endpoint, reduxDispatcher).then(response => {
    if (response.data) {
      RESPONDENT_DMCS(response.data, reduxDispatcher);
      reduxDispatcher(setRespondents(response));
    }
  })
    .catch(error => {
      log.error(`Unexpected error posting: ${endpoint}`, error);

      reduxDispatcher(setErrorInfo({
        show: true,
        type: "fullPage",
        errorTitle: "Something went wrong.",
        errorDescription: "It's not you, it's us. Please try again.",
        errorButton: null
      }));
    });
};

/* --------------------------------------------- */
/* ------------ GET RESPONDENT DMCS ------------ */
/* --------------------------------------------- */
export const RESPONDENT_DMCS = (respondentId, reduxDispatcher) => {
  log.debug("Calling RESPONDENTS DMCS");
  const endpoint = `/v1/respondents/${respondentId}/dashboard`;

  //create and save session guid
  if (!guid) {
    guid = uuidv4();
    reduxDispatcher(setSessionGuid(guid))
  }

  GET(endpoint, reduxDispatcher).then(result => {
    if (result.data !== "") {
      reduxDispatcher(setRespondentsDmcs(result.data));
    }
    else {
      reduxDispatcher(setShowWelcome(true))
      reduxDispatcher(setRespondentsDmcs(null));
    }
  })
    .catch(error => {
      reduxDispatcher(setErrorInfo({
        show: true,
        type: "fullPage",
        errorTitle: "Something went wrong.",
        errorDescription: "It's not you, it's us. Please try again.",
        errorButton: null
      }))
    });
};

export const RESPONDENT_DMCS_HISTORY = (respondentId) => {
  log.debug("Calling HISTORY RESPONDENT DMCS");
  const endpoint = sessionStorage.getItem("ApiUrl") + `/v1/respondents/${respondentId}/history`;
  setHeaders();

  return new Promise((resolve, reject) => {
    axios
      .get(endpoint, config)
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        log.error("Failed to retrieve updated dmcs: " + error)
        reject()
      })
  })
};

///// RESUME DMC \\\\\
export const RESUME_DMC = (dmcId, resumeDmcQuestionnaire, reduxDispatcher) => {
  log.debug("Calling RESUME DMC");
  const endpoint = `/v1/dmcs/${dmcId}/resume`;

  return new Promise((resolve, reject) => {
    //get resume dmc data
    GET(endpoint, reduxDispatcher).then(result => {
      //save respondentID which has this resumed dmc
      reduxDispatcher(setRespondents({ data: result.data.respondentId }));

      //save Resume DMC ID
      reduxDispatcher(startNewDmc(result.data.dmcId))

      //save resumed dmc information
      reduxDispatcher(setResumedDmc(result.data))

      let dmc = result.data;
      if (dmc) {
        let questionnaireSteps = [];
        let questionnaireSubSteps = [];
        let questionnaireAnswerPairs = [];

        //build redux with the incompleted      

        //questionnaireWording
        if (dmc.answers) {
          reduxDispatcher(setAnswerState(dmc.answers))

          dmc.answers.map(answer => {
            if (dmc.steps && dmc.steps.some(step => step.stepId === answer.stepId)) {
              let stepFound = dmc.steps.find(step => step.stepId === answer.stepId)

              questionnaireSteps.push(stepFound);
            }
            if (answer.answerId)
              questionnaireAnswerPairs.push(
                {
                  stepId: answer.stepId,
                  answerId: answer.answerId
                }
              )
          })

          if (!questionnaireSteps.some(step => step.stepId === dmc.steps[dmc.steps.length - 1])) {
            questionnaireSteps.push(dmc.steps[dmc.steps.length - 1])
          }

          questionnaireSubSteps = dmc.steps.filter(step => !questionnaireSteps.includes(step))
        }

        if (questionnaireSubSteps.length > 0) {
          reduxDispatcher(setGoalSubQuestions(questionnaireSubSteps))
        }

        //set answers pairs
        if (questionnaireAnswerPairs) reduxDispatcher(setAnswerPairs(questionnaireAnswerPairs))

        //use new steps array after iteration
        if (questionnaireSteps.length > 0) {
          //set dmc steps          
          reduxDispatcher(setQuestionnaireFlow(questionnaireSteps))

          //set current step
          reduxDispatcher(setCurrentStep(dmc.steps[dmc.steps.length - 1]))

          //set progress made
          reduxDispatcher(setProgressMade(questionnaireSteps.length))

          if (questionnaireSteps[questionnaireSteps.length - 1].isLastStep) reduxDispatcher(setIsLastStep(true))
        }

        //redirect widget to Questionnaire page
        resumeDmcQuestionnaire()
        reduxDispatcher(setSummaryAlert(false))
      }

      resolve();
    })
      .catch(e => {
        reject();
        //show error message in case of error or dmc not found
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "fullPage",
          errorTitle: "Something went wrong.",
          errorDescription: "The information provided is invalid. Please log a Service Now incident for assistance.",
          errorButton: null
        }))
      })
  })
}

export const DELETE_DMC = (dmcId, assessments, going, reduxDispatcher) => {
  log.debug("Calling DELETE_DMC");

  const endpoint = `/v1/dmcs/${dmcId}/delete`;

  POST_DELETE(endpoint, null, reduxDispatcher)
    .then((response) => {
      let auxAssessments = assessments;
      let auxGoing = going;

      if (assessments.some(a => a.dmcId === dmcId)) {
        assessments.map((assessment, index) => {
          if (assessment.dmcId && assessment.dmcId === dmcId) {
            auxAssessments.splice(index, 1);
            return true
          }
        })
      }
      if (going.some(g => g.dmcId === dmcId)) {
        going.map((going, index) => {
          if (going.dmcId === dmcId) {
            auxGoing.splice(index, 1);
            return true
          }
        })
      }

      reduxDispatcher(setHistoryAssessments({
        assessments: auxAssessments,
        ongoing: auxGoing
      }))
    })
    .catch(error => {
      //show error message
      log.debug("Unexpected error when deleting DMC ")
    })
}

/* -------------------------------------- */
/* ------------ GET CONTENTS ------------ */
/* -------------------------------------- */
export const CONTENTS = (groupName, reduxDispatcher) => {
  log.debug("Calling CONTENTS");
  const endpoint = REACT_APP_API_CONTENTS_GROUP_URI + groupName;
  GET(endpoint, reduxDispatcher).then(response => {
    reduxDispatcher(setContents(response));
  }
  );
};

/* ----------------------------------- */
/* ------------ START DMC ------------ */
/* ----------------------------------- */
export const START_DMC = (respondentId, reduxDispatcher) => {
  log.debug("Calling START_DMC");
  const endpoint = "/v1/dmcs?respondentId=" + respondentId;
  const payload = {};

  POST(endpoint, payload, reduxDispatcher).then(result => {
    reduxDispatcher(startNewDmc(result.dmcId));
    reduxDispatcher(setProgressMade(result.maxNumberOfQuestions));
    reduxDispatcher(setGoalsArray([]))

    //first page of  the questionnaire
    GET_QUESTIONNAIRE_NEXT_STEP(result.dmcId, 1, () => { }, reduxDispatcher);
  }
  );
}

/* --------------------------------------- */
/* ------------ PREVIOUS STEP ------------ */
/* --------------------------------------- */
export const GET_QUESTIONNAIRE_PREVIOUS_STEP = (
  dmcID,
  stepId,
  answerId,
  resetState,
  reduxDispatcher
) => {
  log.debug("Calling GET_QUESTIONNAIRE_PREVIOUS_STEP");
  const endpoint = "/v1/dmcs/" + dmcID + "/back?currentStepId=" + stepId + "&answerId=" + answerId;

  //avoid back service displaying loader message
  reduxDispatcher(setIsQuestionnaireRunning(false))
  isServiceCallRunning = false

  POST(endpoint, null, reduxDispatcher).then(response => {
    log.debug("Prev Called Successfully");

    reduxDispatcher(setErrorInfo({
      show: false,
      type: "",
      errorTitle: "",
      errorDescription: "",
      errorButton: null
    }))
  })
    .catch(error => {
      //stop loader
      reduxDispatcher(setRequestLoading(false))
      //show error when back fails
      if (!isSessionTimedOut)
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "smallError",
          errorTitle: "Unexpected error.",
          errorDescription: "Sorry, your responses were not saved. Please try again or contact an advisor for support at 1-888-517-7000.",
          errorButton: null
        }))
    });
}


/**
 * Add GTM event for a question
 * @param {object} data the output data from GET next step
 */
function addQuestionEvent(data) {

  // Default values
  let questionNumber = "Not defined";
  let sectionName = "Not defined";
  let sectionNumber = "Not defined";
  let pageType = "question";

  // Get question number from the length of the array of steps
  questionNumber = store.getState().questionnaireFlow.steps.length + 1;
  if (data.stepType === "Info") {
    pageType = "sectionInfo";
  }
  // Add the event
  GTMHelper.pushEvent("e_moneychat_vpv", {
    pageType: pageType,
    sectionNumber: data.section ? data.section.id : sectionNumber, // section number
    sectionName: data.section ? data.section.name : sectionName, // section name
    questionNumber: questionNumber, // Question number - get from array of answers
    question: data.titles ? data.titles[0].value : ""
  });
}

/* ------------------------------------------------- */
/* ------------ QUESTIONNAIRE NEXT STEP ------------ */
/* ------------------------------------------------- */
export const GET_QUESTIONNAIRE_NEXT_STEP = (dmcID, stepId, resetState, reduxDispatcher) => {
  log.debug("Calling GET_QUESTIONNAIRE_NEXT_STEP");
  const endpoint = "/v1/dmcs/" + dmcID + "/step/" + stepId;

  GET(endpoint, reduxDispatcher).then(response => {
    addQuestionEvent(response.data);

    reduxDispatcher(setNewStep(response.data));

    //reset error message if get_next_step runs successfuly
    reduxDispatcher(setErrorInfo({
      show: false,
      type: "",
      errorTitle: "",
      errorDescription: "",
      errorButton: null
    }))

    isServiceCallRunning = false
    resetState();
  })
    .catch(error => {
      //stop loader
      reduxDispatcher(setRequestLoading(false))

      //show error message when get step fails
      if (!isSessionTimedOut)
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "smallError",
          errorTitle: "Unexpected error.",
          errorDescription: "Sorry, your responses were not saved. Please try again or contact an advisor for support at 1-888-517-7000.",
          errorButton: null
        }))
    })
};

/* ------------------------------------------------- */
/* ------------ Finish Questionnaire --------------- */
/* ------------------------------------------------- */
export const FINISH_QUESTIONNAIRE = (dmcID, resetState, reduxDispatcher) => {
  log.debug("Calling FINISH_QUESTIONNAIRE");
  const endpoint = `/v1/dmcs/${dmcID}/finish`;
  POST(endpoint, null, reduxDispatcher).then(result => {
    //reset error 
    reduxDispatcher(setErrorInfo({
      show: false,
      type: "",
      errorTitle: "",
      errorDescription: "",
      errorButton: null
    }))

    reduxDispatcher(setShowSummary(true));
    reduxDispatcher(setSummaryAlert(false))
  })
    .catch(error => {
      //show error message when finish dmc fails
      if (!isSessionTimedOut)
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "smallError",
          errorTitle: "Something went wrong.",
          errorDescription: "Sorry, your responses were not saved. Please try again or contact an advisor for support at 1-888-517-7000.",
          errorButton: null
        }))
    })
};


/**
 * Saves a note
 * @param {string} noteId The note ID 
 * @param {string} noteText Text of the note
 * @param {function} reduxDispatcher The redux dispatcher 
 * @param {string} dmcId The current DMC ID
 */
export const SAVE_CONTEXTUAL_NOTE = (noteId, noteText, dmcId) => {
  log.debug("Calling SAVE_CONTEXTUAL_NOTE");
  let input = {
    noteId,
    value: noteText
  };

  const endpoint = `/v1/recommendations/note/${dmcId}`;
  POST(endpoint, input, null).then(result => {
    log.debug(`Success calling ${endpoint}`);
  });
};

/**
 * Calls service to return results page data (Summary)
 */
export const GET_RESULTS_PAGE_DATA = (dmcId) => {
  log.debug("Calling GET_RESULTS_PAGE_DATA");
  const endpoint = sessionStorage.getItem("ApiUrl") + `/v1/recommendations/${dmcId}`;
  setHeaders();

  return new Promise((resolve, reject) => {
    axios
      .get(endpoint, config)
      .then((response) => {
        log.debug(`Success calling: /v1/recommendations/${dmcId}`);
        resolve(response.data);
      })
      .catch((error) => {
        log.error("Failed to retrieve recommendations: " + error);
        reject();
      });
  });
};

/* --------------------------------------------------- */
/* ------------ SAVE QUESTION TO DATABASE ------------ */
/* --------------------------------------------------- */
export const SAVE_QUESTION_TO_DB = (dmcID, answer, isQuestionnaireRunning, resetState, reduxDispatcher) => {
  log.debug("Calling SAVE_QUESTION_TO_DB");
  const endpoint = "/v1/dmcs/" + dmcID + "/next";
  const getAnswerToApi = () => {
    if (dmcID) {
      let answerToAPI = {
        stepId: answer ? answer.stepId : "",
        answers: answer.answers ? [
          {
            optionId: answer.answers[0].id ? answer.answers[0].id.toString() : answer.answers[0].optionId ? answer.answers[0].optionId : null,
            value: answer.answers[0].value,

            subAnswers: answer.answers[0].subAnswers ? answer.answers[0].subAnswers : null
          }
        ] : []
      };

      //check for multiple answers (goals)
      if (answer.answers) {
        let auxGoalAnswers = []
        answer.answers.map(ans => {
          auxGoalAnswers.push(
            {
              optionId: ans.id ? ans.id.toString() : ans.optionId ? ans.optionId : null,
              value: ans.value,
              subAnswers: ans.subAnswers ? ans.subAnswers : null
            }
          )
        })

        answerToAPI.answers = auxGoalAnswers
      }

      if (answer.answers && answer.answers[0]?.id && answer.answers[0]?.id.toString().indexOf(".") >= 0) {
        var s = answer.answers[0].id.split(".");

        let answerAPI = [
          {
            optionId: s[0],
            value: "",
          },
          {
            optionId: s[1],
            value: answer.answers[0].value,
          },
        ];

        answerToAPI.answers = answerAPI;
      }

      let goalSubAnswers = false

      answerToAPI.answers.map(answer => {
        if (answer?.subAnswers) {
          let auxSubAns = []
          answer.subAnswers.map(subAns => {
            if (subAns.answers) {
              let auxSubEle = null
              if (subAns.answers?.[0].subAnswers && subAns.answers?.[0].subAnswers.length > 0) {
                let answerOptionID = subAns.answers[0].subAnswers[0].answers[0].id ? subAns.answers[0].subAnswers[0].answers[0].id
                  : subAns.answers[0].subAnswers[0].answers[0].optionId ? subAns.answers[0].subAnswers[0].answers[0].optionId : null;

                let answerStepID = subAns.answers[0].subAnswers[0].id ? subAns.answers[0].subAnswers[0].id :
                  subAns.answers[0].subAnswers[0].stepId ? subAns.answers[0].subAnswers[0].stepId : null;

                auxSubEle = [{
                  stepId: parseInt(answerStepID),
                  answers: [{
                    optionId: answerOptionID,
                    value: subAns.answers[0].subAnswers[0].answers[0].value
                  }]
                }]
              }

              let auxEle = [
                {
                  optionId: subAns.answers[0].id ? subAns.answers[0].id : subAns.answers[0].optionId ? subAns.answers[0].optionId : null,
                  value: subAns.answers[0].value,
                  subAnswers: auxSubEle
                }
              ]
              subAns.answers = auxEle;

              auxSubAns.push(subAns)
              goalSubAnswers = true
            }
            else {
              let auxAns = { optionId: subAns.id, value: subAns.value }
              auxSubAns.push(auxAns)
            }
          })

          if (auxSubAns.length > 0) {
            if (goalSubAnswers) {
              answer.subAnswers = auxSubAns
            }
            else {
              answer.subAnswers = [
                {
                  stepId: answer.stepId,
                  answers: auxSubAns
                }
              ]
            }
          }
        }
      })

      // Set the answer note
      answerToAPI.note = answer.note;

      return answerToAPI;
    }
  };

  if (isQuestionnaireRunning) isServiceCallRunning = true;

  POST(endpoint, getAnswerToApi(), reduxDispatcher).then(result => {
    log.debug("Save answer successfully");
    let answerPair = {
      answerId: result.answerId,
      stepId: answer.stepId,
    };

    reduxDispatcher(setIsLastStep(result.isLastStep ? result.isLastStep : false));

    reduxDispatcher(setNewAnswerPair(answerPair));

    if (result.nextStepId && result.nextStepId !== undefined) {
      GET_QUESTIONNAIRE_NEXT_STEP(dmcID, result.nextStepId, resetState, reduxDispatcher);
    }
    else {
      //stop loader
      reduxDispatcher(setRequestLoading(false))
      reduxDispatcher(setErrorInfo({
        show: true,
        type: "smallError",
        errorTitle: "Something went wrong.",
        errorDescription: "Sorry, your responses were not saved. Please try again or contact an advisor for support at 1-888-517-7000.",
        errorButton: null
      }));
    }
  })
    .catch(error => {
      //stop loader
      reduxDispatcher(setRequestLoading(false))
      //show error message
      if (!isSessionTimedOut)
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "smallError",
          errorTitle: "Something went wrong.",
          errorDescription: "Sorry, your responses were not saved. Please try again or contact an advisor for support at 1-888-517-7000.",
          errorButton: null
        }))
    })
};

/**
 * This version returns a Promise
 */
export const SAVE_QUESTION_TO_DB__v2 = (dmcID, answer, isQuestionnaireRunning, reduxDispatcher) => {
  log.debug("Calling SAVE_QUESTION_TO_DB__v2");

  if (isQuestionnaireRunning) isServiceCallRunning = true;

  if (reduxDispatcher) {
    reduxDispatcher(setRequestLoading(true));
  }

  const endpoint = sessionStorage.getItem("ApiUrl") + "/v1/dmcs/" + dmcID + "/next";
  const getAnswerToApi = () => {
    if (dmcID) {
      let answerToAPI = {
        stepId: answer ? answer.stepId : "",
        answers: answer.answers ? [
          {
            optionId: answer.answers[0].id,
            value: answer.answers[0].value,

            subAnswers: answer.answers[0].subAnswers ? answer.answers[0].subAnswers : null
          }
        ] : []
      };

      //check for multiple answers (goals)
      if (answer.answers) {
        let auxGoalAnswers = []
        answer.answers.map(answer => {
          auxGoalAnswers.push(
            {
              optionId: answer.id,
              value: answer.value,

              subAnswers: answer.subAnswers ? answer.subAnswers : null
            }
          )
        })

        answerToAPI.answers = auxGoalAnswers
      }

      if (answer.answers && answer.answers[0]?.id && answer.answers[0]?.id.indexOf(".") >= 0) {
        var s = answer.answers[0].id.split(".");

        let answerAPI = [
          {
            optionId: s[0],
            value: "",
          },
          {
            optionId: s[1],
            value: answer.answers[0].value,
          },
        ];

        answerToAPI.answers = answerAPI;
      }

      let goalSubAnswers = false

      answerToAPI.answers.map(answer => {
        if (answer?.subAnswers) {
          let auxSubAns = []
          answer.subAnswers.map(subAns => {
            if (subAns.answers) {
              let auxSubEle = null
              if (subAns.answers?.[0].subAnswers) {
                auxSubEle = [{
                  stepId: parseInt(subAns.answers[0].subAnswers[0].id),
                  answers: [{
                    optionId: subAns.answers[0].subAnswers[0].answers[0].id,
                    value: subAns.answers[0].subAnswers[0].answers[0].value
                  }]
                }]
              }

              let auxEle = [
                {
                  optionId: subAns.answers[0].id,
                  value: subAns.answers[0].value,
                  subAnswers: auxSubEle
                }
              ]

              subAns.answers = auxEle;

              auxSubAns.push(subAns)
              goalSubAnswers = true
            }
            else {
              let auxAns = { optionId: subAns.id, value: subAns.value }
              auxSubAns.push(auxAns)
            }
          })

          if (auxSubAns.length > 0) {
            if (goalSubAnswers) {
              answer.subAnswers = auxSubAns
            }
            else {
              answer.subAnswers = [
                {
                  stepId: answer.stepId,
                  answers: auxSubAns
                }
              ]
            }
          }
        }
      })

      // Set the answer note
      answerToAPI.note = answer.note;

      return answerToAPI;
    }
  };

  setHeaders();

  return new Promise((resolve, reject) => {
    axios
      .post(endpoint, getAnswerToApi(), config)
      .then((response) => {
        log.debug(`Success calling: /v1/recommendations/export/${dmcID}`);
        if (reduxDispatcher) {
          reduxDispatcher(setRequestLoading(false));
          reduxDispatcher(setLoaderMessage(false))
        }
        resolve(response);
      })
      .catch((error) => {
        log.error("Failed to send export recommendations: " + error);
        if (reduxDispatcher) {
          reduxDispatcher(setRequestLoading(false));
          //show error message
          reduxDispatcher(setErrorInfo({
            show: true,
            type: "smallError",
            errorTitle: "Something went wrong.",
            errorDescription: "Sorry, your responses were not saved. Please try again or contact an advisor for support at 1-888-517-7000.",
            errorButton: null
          }))
        }
        reject();
      });
  });
};

/* --------------------------------------------------- */
/* ------------ GET GOAL NEXT STEP ------------------- */
/* --------------------------------------------------- */
export const GET_GOAL_NEXT_STEP = (dmcID, stepId, resetState, reduxDispatcher) => {
  log.debug("Calling GET_GOAL_NEXT_STEP");
  const endpoint = "/v1/dmcs/" + dmcID + "/step/" + stepId;

  //avoid back service displaying loader message
  reduxDispatcher(setIsQuestionnaireRunning(false))
  isServiceCallRunning = false

  GET(endpoint, reduxDispatcher).then(response => {
    addQuestionEvent(response.data);
    reduxDispatcher(addGoalSubQuestion(response.data));

    // reset error message state in case of success
    reduxDispatcher(setErrorInfo({
      show: false,
      type: "",
      errorTitle: "",
      errorDescription: "",
      errorButton: null
    }))

    resetState();
  })
    .catch(error => {
      //show error message in case of failure geting next step
      reduxDispatcher(setErrorInfo({
        show: true,
        type: "fullPage",
        errorTitle: "Unexpected error.",
        errorDescription: "Something went wrong when trying to get next step.",
        errorButton: null
      }))
    })
};

/* --------------------------------------------------- */
/* ------------ SAVE GOAL  SUBQUESTION --------------- */
/* --------------------------------------------------- */
export const SAVE_GOAL_SUBQUESTION = (dmcID, answer, resetState, reduxDispatcher) => {
  reduxDispatcher(setRequestLoading(true));
  const getAnswerToApi = () => {
    if (dmcID) {
      let answerToAPI = {
        isSubStep: answer.isSubStep ? answer.isSubStep : null,
        stepId: answer ? answer.stepId : "",
        answers: answer.answers ? [{
          optionId: answer.answers[0].id,
          value: answer.answers[0].value,

          subAnswers: answer.answers[0].subAnswers ? [{
            stepId: answer.stepId,
            answers: [
              {
                optionId: answer.answers[0].subAnswers[0].id,
                value: answer.answers[0].subAnswers[0].value
              }
            ]
          }] : null
        }] : [],
      };

      if (answer.answers && answer.answers[0]?.id && answer.answers[0]?.id.indexOf(".") >= 0) {
        var s = answer.answers[0].id.split(".");

        let answerAPI = [
          {
            optionId: s[0],
            value: "",
          },
          {
            optionId: s[1],
            value: answer.answers[0].value,
          },
        ];

        answerToAPI.answers = answerAPI;
      }

      // if (answerToAPI.answers?.subAnswers) {
      //   let auxSubAns = []
      //   answerToAPI.answers.subAnswers.map(subAns => {
      //     let auxAns = { optionId: subAns.id, value: subAns.value }
      //     auxSubAns.push(auxAns)
      //   })

      //   if (auxSubAns.length > 0) {
      //     answerToAPI.answers.subAnswers = auxSubAns
      //   }
      // }

      return answerToAPI;
    }
  };

  log.debug("Calling SAVE_GOAL_SUBQUESTION");
  const endpoint = "/v1/dmcs/" + dmcID + "/next";

  POST(endpoint, getAnswerToApi(), reduxDispatcher).then(result => {
    let answerPair = {
      answerId: result.answerId,
      stepId: answer.stepId,
    };

    reduxDispatcher(setIsLastStep(result.isLastStep ? result.isLastStep : false));

    reduxDispatcher(setNewAnswerPair(answerPair));

    if (result.nextStepId)
      GET_GOAL_NEXT_STEP(dmcID, result.nextStepId, resetState, reduxDispatcher);
  }
  )
    .catch(error => {
      //show error message
      if (!isSessionTimedOut)
        reduxDispatcher(setErrorInfo({
          show: true,
          type: "fullPage",
          errorTitle: "Unexpected error.",
          errorDescription: "Something went wrong when trying to save your answers."
        }))
    })
};

/* --------------------------------------------------- */
/* ------------ GET DROP LAST QUESTIONS -------------- */
/* --------------------------------------------------- */
export const GET_LAST_QUESTIONS = (endpoint, reduxDispatcher) => {
  log.debug("Calling GET_LAST_QUESTIONS");
  return GET(endpoint, reduxDispatcher);
}

/**
 * Export Assessment
 */
export const EXPORT_ASSESSMENT = (dmcId, email) => {
  log.debug("Calling ExportAssessment");
  let input = {
    email
  };
  const endpoint = sessionStorage.getItem("ApiUrl") + `/v1/recommendations/export/${dmcId}`;

  setHeaders();

  return new Promise((resolve, reject) => {
    axios
      .post(endpoint, input, config)
      .then((response) => {
        log.debug(`Success calling: /v1/recommendations/export/${dmcId}`);
        resolve(response);
      })
      .catch((error) => {
        log.error("Failed to send export recommendations: " + error);
        reject();
      });
  });
};
