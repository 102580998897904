import React, { FC } from "react";
import "./Description.scss";

interface IProps {
    text: string;
}

const Description: FC<IProps> = ({ text }) => {
    return <p className="description">{text}</p>;
}
export default Description;