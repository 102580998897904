function startDMC(state = "", action) {
  switch (action.type) {
    case "START_NEW_DMC":
      return action.value;
    default:
      return state;
  }
}

export default startDMC;
