import * as React from 'react';
import "./SummaryAlert.scss";

import Previous from '@assets/svg/previous.png'
import { useDispatch } from 'react-redux';
import { setShowHistory, setShowSummary } from '../../../../../redux/actions';

interface IProps {
    text?: string;
    date?: string;
    link?: string
}

function goToHistory(event, reduxDispatcher) {
    event.preventDefault();

    reduxDispatcher(setShowHistory(true));
    reduxDispatcher(setShowSummary(false));
}

const SummaryAlert = ({ text, date, link }) => {
    const reduxDispatcher = useDispatch()
    let alertText = `${text} ${date}`;

    return <div className="summary-alert">
        <img src={Previous} alt="previous-assessments" />
        <div>
            <div className="alert-content">
                {alertText}
                <a onClick={(event) => { goToHistory(event, reduxDispatcher) }} className="alert-link">{link}</a>
            </div>
        </div>
    </div>
}

export default SummaryAlert