import * as React from 'react';
import "./Assessment.scss"
import { IGenericItem, ILink } from '@components/Interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { startNewDmc, setShowSummary, setShowHistory, setSummaryAlert, setRequestLoading } from '../../../../redux/actions';
import { RESUME_DMC } from '../../../../requests/api';
interface IDate {
    value: string,
    text?: IGenericItem[],
}

interface IStep {
    title: IGenericItem[],
    answers?: IGenericItem[]
}

interface IProps {
    dmcId: string,
    completed: boolean
    startDate?: IDate,
    endDate?: IDate,
    steps?: IStep[]
    link?: IGenericItem[],
    deleteIconIdle?: string,
    deleteIconHover?: string,
    resumeIncomplete?: (boolean) => void,
    resumeDmc?: (beginDmc: any, dmc: any, reduxDispatcher: any) => void,
    openModal?: () => void
}

function seeAssessmentsResults(event, dmcId, date, summaryAlert, reduxDispatcher) {
    event.preventDefault();
    //see assessment results page
    reduxDispatcher(startNewDmc(dmcId));
    reduxDispatcher(setShowHistory(false));

    let auxStartDate = date?.value ? new Date(date?.value) : null;
    let convertedStartDate = auxStartDate ? auxStartDate.toLocaleString('default', { month: 'long' }) +
        " " + auxStartDate.toLocaleString('default', { day: 'numeric' }) +
        ", " + auxStartDate.getFullYear() + "." : null;

    reduxDispatcher(setSummaryAlert(
        {
            text: summaryAlert.text ? summaryAlert.text : "You are viewing your assessment from",
            date: convertedStartDate,
            link: summaryAlert.link ? summaryAlert.link : "Back to all assessments"
        }
    ))

    reduxDispatcher(setShowSummary(true));
}

function resumeOnClick(event, dmcId, letsBegin, reduxDispatcher) {
    event.preventDefault();

    RESUME_DMC(dmcId, () => { letsBegin(true); reduxDispatcher(setSummaryAlert(false)) }, reduxDispatcher).then(() => {
        reduxDispatcher(setShowHistory(false))
    })
}

//get ordinal num
const getOrdinalNum = (number) => {
    let selector = 0;

    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return ['th', 'st', 'nd', 'rd', ''][selector];
};

const Assessment: React.FC<IProps> = (props) => {
    const reduxDispatcher = useDispatch();
    const summaryAlert = useSelector((state: any) => state.summary.summaryAlert);
    const resumedGoingDmc = useSelector((state: any) => state.resumedDmc.dmc)
    const isSalesforce = window.sessionStorage.getItem("CurrentChannel") === "Salesforce";

    let auxStartDate = props.startDate?.value ? new Date(props.startDate.value) : null;
    let convertedStartDate = auxStartDate ? auxStartDate.toLocaleString('default', { month: 'long' }) + ", " + auxStartDate.getFullYear() : props.dmcId;

    let auxEndDate = props.endDate?.value ? new Date(props.endDate.value) : null;
    let convertedEndDate = auxEndDate ? auxEndDate.toLocaleString('default', { month: 'short' }) + " "
        + auxEndDate.toLocaleString('default', { day: 'numeric' }) + getOrdinalNum(auxEndDate.toLocaleString('default', { day: 'numeric' })) + " "
        + auxEndDate?.toLocaleString('default', { hour12: true, hour: '2-digit', minute: '2-digit' }) : null

    return <div className="assessment">
        <div className="assessment-title">
            {convertedStartDate && <div className="start-date">{convertedStartDate}</div>}
            {convertedEndDate && props.endDate?.text && <div className="end-date">{props.endDate.text[0].value}&nbsp;{convertedEndDate}</div>}
            {/* only show delete icon when the user is staff member */}
            {isSalesforce &&
                <div className="delete-dmc" onClick={() => { if (props.openModal) props.openModal() }}>
                    {props.deleteIconIdle && <img className="icon-idle" src={props.deleteIconIdle} alt="delete-dmc" />}
                    {props.deleteIconHover && <img className="icon-hover" src={props.deleteIconHover} alt="delete-dmc" />}
                </div>
            }
        </div>
        <div className="assessment-steps">
            {props.steps?.map(step => {
                return <>
                    <div className="assessment-step-title">{step.title?.[0].value}</div>
                    <div className="assessment-answers-list">
                        {step.answers?.map((ans, index) => {
                            return <div key={index}>{ans.value}</div>
                        })}
                    </div>
                </>
            })}
        </div>
        <div className="assessment-results">
            {props.link &&
                <a href=""
                    onClick={(event) => props.completed ? seeAssessmentsResults(event, props.dmcId, props.endDate, summaryAlert, reduxDispatcher)
                        : resumeOnClick(event, props.dmcId, props.resumeIncomplete, reduxDispatcher)}>{props.link[0].value}</a>}
        </div>
    </div>
}

export default Assessment;