import {
  SET_AUTHORIZATION,
  SET_IS_AUTHENTICATED,
  SET_IS_AUTHORIZED,
} from "../actions/actionTypes";

// SET_AUTHORIZATION - saves the data (object) that comes from a request
// SET_IS_AUTHENTICATED - sets a boolean, true or false
// SET_IS_AUTHORIZED - sets a boolean, true or false

const defaultState = {
  requestData: {},
  isAuthenticated: false,
  isAuthorized: false,
};

function authorization(state = defaultState, action) {
  switch (action.type) {
    case SET_AUTHORIZATION:
      return { ...state, requestData: action.value };
    case SET_IS_AUTHORIZED:
      return { ...state, isAuthorized: action.value };
    case SET_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.value };
    default:
      return state;
  }
}

export default authorization;
