function questionnaireWording(state = { answers: [], answersPairs: [], isLastStep: false }, action) {
  switch (action.type) {
    case "QUESTIONNAIRE_WORDING":
      return action.value;
    case "SET_ANSWER_STATE":
      return { answers: action.value, answersPairs: state.answersPairs, isLastStep: state.isLastStep };
    case "SET_ANSWER_PAIRS":
      return { answers: state.answers, answersPairs: action.value, isLastStep: state.isLastStep };
    case "SET_NEW_ANSWER":
      return { answers: [...state.answers, action.value], answersPairs: state.answersPairs, isLastStep: state.isLastStep }
    case "SET_NEW_ANSWER_PAIR":
      return { answers: state.answers, answersPairs: [...state.answersPairs, action.value], isLastStep: state.isLastStep }
    case "SET_IS_LAST_STEP":
      return { answers: state.answers, answersPairs: state.answersPairs, isLastStep: action.value }
    default:
      return state;
  }
}

export default questionnaireWording;
