import React, { useState, FC } from 'react';
import LikertScale from '../../../../../../Likert';
import "./ExpandableItem.scss";
import expandArrow from "@assets/svg/expandArrow.svg";
import { ILink, IIcon, IRange, IRangeOption, INote } from "@components/Interfaces"
import ContextualNoteList from '@components/ContextualNoteList/ContextualNoteList';
import GTMHelper from '@helpers/GTMHelper.js';
import { useDispatch, useSelector } from "react-redux";
import { SAVE_CONTEXTUAL_NOTE } from 'requests/api';

interface IProps {
    index: string,
    children?: any,
    icon?: IIcon,
    title: string,
    range: IRange,
    link?: ILink,
    notes?: INote[];
    showNotes?: boolean,
    parentTitle?: string;
    resultsSection?: string;
}

//helper function
function manageInsideFocus(expanded, setExpanded, target) {
    let recommendationsGroup = target.closest(".expandable-row").previousElementSibling;
    let links = target.closest(".expandable-row")?.previousElementSibling?.querySelectorAll(".expandable-item a");

    if (expanded) {
        if (links && links.length > 0) Array.prototype.forEach.call(links, (link) => {
            link.tabIndex = -1
        })

        recommendationsGroup.removeAttribute("tabindex");
        setExpanded(false);
    }
    else {
        if (links && links.length > 0) Array.prototype.forEach.call(links, (link) => {
            link.tabIndex = 0;
        })
        setTimeout(() => { recommendationsGroup.tabIndex = 0; recommendationsGroup.focus() })
        setExpanded(true);
    }
    return false;
}

const ExpandableItem: FC<IProps> = (props) => {
    const { index, children, icon, title, range, link, parentTitle, resultsSection, showNotes } = props;
    const [expanded, setExpanded] = useState(false)

    let topicState = range?.rangeOptions?.find(option => option.value === range.currentValue)?.label?.[0]?.value;

    const isSalesforce = window.sessionStorage.getItem("CurrentChannel") === "Salesforce";
    // @ts-ignore
    const dmcId = useSelector(state => state.startDMC);

    return (
        <div tabIndex={0} className={expanded ? "expandable-item expanded" : "expandable-item"} aria-label={`${title} State ${topicState}`}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="d-flex expandableItem-header">
                    {icon && <div className="expandableItem-icon"><img src={icon.url} alt={""} /></div>}
                    <div className="header-row">
                        <div className="expandableItem-title"><div>{title}</div></div>
                        {range &&
                            <div tabIndex={0}
                                aria-label={`${topicState} radio button checked unavailable`}
                                className={range.color ? "likert " + range.color.toLocaleLowerCase() : ""}>
                                <LikertScale
                                    handleStateModal={null}
                                    id={index}
                                    titles={null}
                                    answerOptions={range.rangeOptions}
                                    currentValue={range.currentValue ? range.currentValue : null}
                                    handleState={null}
                                    onSelect={null}
                                    descriptions={null}
                                    staticScale={true} />

                            </div>}
                    </div>
                </div>
                {
                    isSalesforce && // Only staff members will see notes
                    props.notes &&
                    showNotes &&
                    <ContextualNoteList notes={props.notes}
                        onSave={(noteId, text) => {
                            SAVE_CONTEXTUAL_NOTE(noteId, text, dmcId);
                        }} />}
            </div>
            {children &&
                <>
                    <div className={expanded ? "expandable-container expanded" : "expandable-container"} aria-label={`${title} Recommendations list`}>
                        {children}
                    </div>
                    <div className="expandable-row"
                        onClick={(event: any) => {
                            event.preventDefault();
                            manageInsideFocus(expanded, setExpanded, event.target);
                            return false;
                        }}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault()
                                manageInsideFocus(expanded, setExpanded, event.target)
                            }
                        }}
                        tabIndex={0}
                        aria-expanded={expanded ? "true" : "false"}
                        role="button">
                        <button className="expand-arrow" tabIndex={-1}>
                            <div>
                                <img
                                    role="button"
                                    className={expanded ? "open" : ""}
                                    src={expandArrow}
                                    alt={title + " panel"}
                                    style={
                                        { cursor: "pointer" }
                                    }
                                />
                            </div>
                        </button>
                    </div>
                </>
            }
            {link && <div className="expandableItem-link"
                onClick={() => {
                    GTMHelper.pushEvent("e_moneychat_view_financial_advice", {
                        fin_advice_section: parentTitle,
                        fin_advice_task: title
                    });
                }}
            >
                <a target={link.target ? link.target : undefined} href={link.url} tabIndex={-1}>
                    {link.title?.[0].value}
                    {link.target && link.target === "_blank" &&
                        <span className="sr-only" aria-hidden={true}>in a new tab</span>
                    }
                </a>
            </div>
            }
        </div>
    )
}

export default ExpandableItem;
