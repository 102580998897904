import React, { FC } from "react";
import { IHighlight } from "@components/Interfaces";
import "./HighlightList.scss";
import HighlightListItem from "./HighlightListItem/HighlightListItem"

interface IProps {
    highlights: IHighlight[]
}

const HighLightList: FC<IProps> = (props) => {
    return (
        <div className="highlight-list">
            {props.highlights.map((item, index) => (
                <HighlightListItem
                    key={index}
                    sectionId={item.sectionId}
                    description={item.description?.[0].value}
                    backgroundColor={item.backgroundColor}
                    borderColor={item.borderColor}
                    borderType={item.borderType}
                    icon={item.icon}
                />
            ))}
        </div>
    );
}
export default HighLightList;