import React from "react";

import { CircularProgressbar } from "react-circular-progressbar";
import ProgressBar from "@ramonak/react-progress-bar";
import "react-circular-progressbar/dist/styles.css";
import setProgressMade from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";

import "../styles/progressbar.scss";

/**
 *
 * @param {string} type - A string that sets the type of progress bar. Example: "linear"/"circular"
 * @param {number} answersTaken - A number that represents the total of reponses
 *
 * @example
 *
 * <CostumProgressbar type="circular" answersTaken={1} />
 */

const CostumProgressbar = ({ type, answersTaken }) => {
  let currentStepId = useSelector(state => state.questionnaireFlow.currentStep.stepId)
  let totalAnswers = useSelector((state) => state.questionnaireProgress.total);
  let progress =
    totalAnswers === 0
      ? Math.round((answersTaken / totalAnswers) * 100)
      : Math.round((answersTaken / process.env.REACT_APP_TOTAL_ANSWERS) * 100);

  if (type.toLowerCase() === "circular") {
    return (
      <div className="circular-progressbar">
        <CircularProgressbar value={progress} maxValue={100} text={progress + "%"} />
      </div>
    );
  }
  return (
    <div
      className={progress > 89 ? "linear-progressbar" : "linear-progressbar out"}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={progress}
    >
      <ProgressBar completed={progress} labelAlignment="right" />
    </div>
  );
};

export default CostumProgressbar;
