import React, { FC } from "react";
import "./Title.scss";

interface IProps {
    text?: string;
}

const Title: FC<IProps> = ({ text }) => {
    return (
        <p className="title">{text}</p>
    );
}
export default Title;