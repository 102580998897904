function welcome(state = { showWelcome: false }, action) {
    switch (action.type) {
        case "SET_SHOW_WELCOME":
            return { showWelcome: action.value }
        default:
            return state;
    }
}

export default welcome;
