import * as React from 'react';
import { Modal } from 'react-bootstrap';
import "./ExportAssessmentModal.scss";
import { ReactComponent as IconClose } from "@assets/svg/close_white.svg";

interface IProps {
    show?: boolean,
    title: string,
    closeModal?: (boolean) => void,
}

/**
 * 
 * @param {*} children
 * @param {*} show
 * @param {*} title
 * 
 * @example
 * 
 * <Modal show={state}>
 *     <Modal.Header>title</Modal.Header>
 *     <Modal.Body>children</Modal.Body>
 * </Modal>
 */

const ExportAssessmentModal: React.FC<IProps> = (props) => {
    let className = "confirm-modal export-modal";

    if (sessionStorage.getItem("CurrentChannel") === "dcu_mobile") {
        className += " webViewMode";
    }

    return <Modal
        show={props.show}
        className={className}
        onHide={() => { if (props.closeModal) { props.closeModal(false) } }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        container={document.getElementById("dmcWidget")}
        centered>
        <Modal.Header>
            <span className="modal-title">{props.title}</span>
            <span className="modal-close-btn" onClick={() => { props.closeModal && props.closeModal(true) }}><IconClose /></span>
        </Modal.Header>
        <Modal.Body>
            {props.children && <>{props.children}</>}
        </Modal.Body>
    </Modal>
}

export default ExportAssessmentModal