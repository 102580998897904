type DefaultActionTypes =
  | "CUSTOMER_RESPONSE"
  | "CUSTOMER_RESULTS"
  | "GENERIC_CONTENTS"
  | "QUESTIONNAIRE_FLOW"
  | "QUESTIONNAIRE_WORDING"
  | "SET_AUTHORIZATION"
  | "SET_RESPONDENTS"
  | "SET_RESPONDENTSDMCS"
  | "SET_CONTENTS"
  | "SET_REQUEST_LOADING"
  | "SET_IS_AUTHENTICATED"
  | "SET_IS_AUTHORIZED"
  | "SET_QUESTIONAIRE_STEP"
  | "SET_QUESTIONAIRE_FLOW"
  | "SET_NEW_STEP"
  | "SET_CURRENT_STEP"
  | "SET_ANSWER_STATE"
  | "SET_NEW_ANSWER"
  | "START_NEW_DMC"
  | "SET_HAS_ALERT"
  | "SET_PROGRESS_MADE"
  | "SET_NEW_ANSWER_PAIR"
  | "SET_ANSWER_PAIRS"
  | "ADD_NEW_GOAL"
  | "SET_GOALS_ARRAY"
  | "ADD_GOAL_SUBQUESTION"
  | "SET_GOAL_SUBQUESTIONS"
  | "SET_GOAL_ANSWERS"
  | "ADD_GOAL_SUBQUESTION_ANSWER"
  | "SET_IS_LAST_STEP"
  | "SET_SHOW_SUMMARY"
  | "SET_RESUMEDDMC"
  | "SET_STAFFRESUMEDDMC"
  | "SET_SHOW_HISTORY"
  | "SET_HISTORY_ASSESSMENTS"
  | "SET_SHOW_WELCOME"
  | "SET_SUMMARY_ALERT"
  | "SET_CONTEXTUAL_NOTE"
  | "SET_SUBSTEP_INFO"
  | "SET_ERROR_INFO"
  | "SET_CONTEXT"
  | "SET_IS_QUESTIONNAIRE_RUNNING"
  | "SET_SESSION_GUID";

export const SET_AUTHORIZATION: DefaultActionTypes = "SET_AUTHORIZATION";
export const CUSTOMER_RESPONSE: DefaultActionTypes = "CUSTOMER_RESPONSE";
export const CUSTOMER_RESULTS: DefaultActionTypes = "CUSTOMER_RESULTS";
export const GENERIC_CONTENTS: DefaultActionTypes = "GENERIC_CONTENTS";
export const SET_RESPONDENTS: DefaultActionTypes = "SET_RESPONDENTS";
export const SET_RESPONDENTSDMCS: DefaultActionTypes = "SET_RESPONDENTSDMCS";
export const SET_CONTENTS: DefaultActionTypes = "SET_CONTENTS";
export const SET_REQUEST_LOADING: DefaultActionTypes = "SET_REQUEST_LOADING";
export const SET_IS_AUTHENTICATED: DefaultActionTypes = "SET_IS_AUTHENTICATED";
export const SET_IS_AUTHORIZED: DefaultActionTypes = "SET_IS_AUTHORIZED";

//// QUESTIONAIRE RELATED \\\\
export const QUESTIONNAIRE_FLOW: DefaultActionTypes = "QUESTIONNAIRE_FLOW";
export const QUESTIONNAIRE_WORDING: DefaultActionTypes = "QUESTIONNAIRE_WORDING";
export const SET_PROGRESS_MADE: DefaultActionTypes = "SET_PROGRESS_MADE"

export const SET_QUESTIONAIRE_FLOW: DefaultActionTypes = "SET_QUESTIONAIRE_FLOW";
export const SET_NEW_STEP: DefaultActionTypes = "SET_NEW_STEP";
export const SET_CURRENT_STEP: DefaultActionTypes = "SET_CURRENT_STEP";
export const SET_ANSWER_STATE: DefaultActionTypes = "SET_ANSWER_STATE";
export const SET_NEW_ANSWER: DefaultActionTypes = "SET_NEW_ANSWER";
export const SET_NEW_ANSWER_PAIR: DefaultActionTypes = "SET_NEW_ANSWER_PAIR";
export const SET_ANSWER_PAIRS: DefaultActionTypes = "SET_ANSWER_PAIRS";
export const SET_IS_LAST_STEP: DefaultActionTypes = "SET_IS_LAST_STEP";
export const SET_CONTEXTUAL_NOTE: DefaultActionTypes = "SET_CONTEXTUAL_NOTE";

export const START_NEW_DMC: DefaultActionTypes = "START_NEW_DMC";
export const SET_HAS_ALERT: DefaultActionTypes = "SET_HAS_ALERT";

/////// Goals Related \\\\\\\\\\
export const ADD_NEW_GOAL: DefaultActionTypes = "ADD_NEW_GOAL";
export const SET_GOALS_ARRAY: DefaultActionTypes = "SET_GOALS_ARRAY";
export const ADD_GOAL_SUBQUESTION: DefaultActionTypes = "ADD_GOAL_SUBQUESTION";
export const SET_GOAL_SUBQUESTIONS: DefaultActionTypes = "SET_GOAL_SUBQUESTIONS";
export const ADD_GOAL_SUBQUESTION_ANSWER: DefaultActionTypes = "ADD_GOAL_SUBQUESTION_ANSWER";
export const SET_GOAL_ANSWERS: DefaultActionTypes = "SET_GOAL_ANSWERS";
export const SET_SUBSTEP_INFO: DefaultActionTypes = "SET_SUBSTEP_INFO"

/////// Summary Related \\\\\\\\\\
export const SET_SHOW_SUMMARY: DefaultActionTypes = "SET_SHOW_SUMMARY";
export const SET_SUMMARY_ALERT: DefaultActionTypes = "SET_SUMMARY_ALERT";

/////// Resume Dmc \\\\\\\\
export const SET_RESUMEDDMC: DefaultActionTypes = "SET_RESUMEDDMC";
export const SET_STAFFRESUMEDDMC: DefaultActionTypes = "SET_STAFFRESUMEDDMC";

/////// Assessments History \\\\\\\\
export const SET_SHOW_HISTORY: DefaultActionTypes = "SET_SHOW_HISTORY";
export const SET_HISTORY_ASSESSMENTS: DefaultActionTypes = "SET_HISTORY_ASSESSMENTS";
export const SET_SHOW_WELCOME: DefaultActionTypes = "SET_SHOW_WELCOME";

////// Error Messages \\\\\\\
export const SET_ERROR_INFO: DefaultActionTypes = "SET_ERROR_INFO";


////// Context \\\\\\\
export const SET_CONTEXT: DefaultActionTypes = "SET_CONTEXT";

////// Loader Message  \\\\\\\
export const SET_LOADER_MESSAGE = "SET_LOADER_MESSAGE";
export const SET_IS_QUESTIONNAIRE_RUNNING = "SET_IS_QUESTIONNAIRE_RUNNING";

////// Log Session Guid
export const SET_SESSION_GUID: DefaultActionTypes = "SET_SESSION_GUID";